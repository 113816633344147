.filter {
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  /* justify-content: ; */
  margin-bottom: 30px;
  font-family: var(--app-font-family);
  color: #1f1f1f;
}

.interview_status_filter {
  margin-right: 20px;
}

.monthly_filter select,
.interview_status_filter select,
.processing_stage_filter select {
  padding: 10px;
  margin-top: 5px;
  border: 1px;
  border-color: var(--prm-color);
  border-radius: 5px;
}

/* ERP - Login */

.account-logo {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
}

.account-logo {
  margin-bottom: 50px;
}

.account-logo img {
  width: 150px;
}

.container {
  height: 90vh;
}

.account-box {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 0 auto;
  overflow: hidden;
  width: 45%;
}

.account-box label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}

.account-box .form-control {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height: 46px;
}

.account-box .form-group {
  margin-bottom: 25px;
}
.account-box .account-btn {
  background: var(--prm-color);
  background: -moz-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: -webkit-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: -ms-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  border: 0;
  border-radius: 4px;
  display: block;
  font-size: 22px;
  padding: 10px 26px;
  width: 100%;
}

.account-box .account-btn:hover,
.account-box .account-btn:focus {
  border: 0;
  opacity: 0.8;
}

.close-account-btn {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 12px !important;
}

.create_ticket_div {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  right: 5%;
  height: 100px;
  width: 100px;
}
.create_ticket_text {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  text-align: center;
  width: 200px;
  bottom: -25%;
  font-family: var(--app-font-family);
  font-weight: 600;
}
.create_ticket_div:hover .create_ticket_text {
  opacity: 1;
}

.login-input::placeholder {
  color: #ccc !important;
  font-weight: 400 !important;
  opacity: 0.5 !important;
}

.emp-dashboard-leave-card {
  height: 240px;
  border-radius: 5px;
  /* min-height: 35vh */
}

.emp-dashboard-leave-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 15px;
}

.app-table-div {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 15px !important;
  padding: 15px !important;
  background-color: #ffffff !important;
}

.table {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border: 1px solid #ccc !important;
}

.custom-table-div {
  background-color: #ffffff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
  overflow-x: auto;
  width: 100%;
}
.custom-field-div {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 100%;
  height: 100%;
}

.monthly_attendance_spinner {
  position: absolute;
  left: 30rem;
}

.rep-siever-status-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #ffffff;
  border-radius: 10px;
  gap: 20px 10px;
  padding: 20px 30px 20px 10px;
  margin-bottom: 30px;
}

.rep-siever-status-card {
  background-color: #ffffff;
  min-width: 20%;
  margin-left: 20px;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.rep-siever-status-card:hover {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.rep-siever-status-card > span {
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
}

.rep-widget-icon {
  border-radius: 100%;
  display: inline-block;
  float: left;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  text-align: center;
  width: 30px;
}

.rep-card-info > h3 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.proof_upload {
  color: #595959;
  height: 40px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: 1.5px solid #528dc280;
  margin-bottom: 25px;
  cursor: pointer;
}

.proof_upload::-webkit-file-upload-button {
  background-color: #528dc2;
  color: #ffffff;
  border: none;
  padding: 4px 15px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.proof_upload:focus {
  outline: 2px solid #528dc280;
}
.proof_upload::-webkit-input-placeholder {
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .hr-filter-select {
    flex-direction: column;
  }

  .rep-siever-status-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: #ffffff;
    border-radius: 10px;
    gap: 20px 10px;
    padding: 20px 30px 20px 10px;
    margin-bottom: 30px;
  }
}

.tl.row {
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 10vh;
  user-select: none;
}

.tl.card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 400px;
  height: 180px;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.3s ease-in;
  cursor: pointer;
}

.tl.card > h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.tl.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: var(--prm-color);
  background: -moz-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: -webkit-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: -ms-linear-gradient(left, var(--prm-color) 0%, var(--sec-color) 100%);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  height: 220px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.tl.card:hover::before {
  transition-delay: 0.2s;
  transform: scale(40);
}

.tl.card:hover {
  transition-delay: 0.2s;
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.26) 0px 22px 70px 4px;
  z-index: 1;
}

.tl.card:hover,
.tl.card:hover h4,
.tl.card:hover p,
.tl.card:hover .tl.link {
  color: #ffffff;
}

.tl.card > p {
  margin-bottom: 5px;
  font-weight: 500;
}

.tl.link {
  color: var(--sec-color);
  text-decoration: none;
}

.edit-icon.teams {
  height: 0;
  margin-left: 50px;
  background-color: #ffffff;
  border-color: var(--prm-color);
}

.logout {
  color: #ff4136 !important;
}

.date_error {
  color: #ff4136;
  font-size: 12px;
  font-style: italic;
  font-family: var(--app-font-family);
  font-weight: 600;
}

.date_error_icon {
  color: #ff4136;
  font-size: 12px;
  margin-right: 5px;
}

/* Payroll Earnings & Deductions */

.payroll_earnings_div {
  margin-bottom: 20px;
  display: flex;
}

.payroll_earnings_container {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-weight: 500;
}
.payroll_earnings_icon {
  color: green;
  font-weight: 500;
  margin-right: 10px;
  font-size: 25px;
}
.payroll_earnings {
  color: green;
}

.payroll_deductions_container {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.payroll_deductions_icon {
  color: red;
  font-weight: 500;
  margin-right: 10px;
  font-size: 25px;
}
.payroll_deductions {
  color: red;
}

/*--------------------- 
Employee Salary Table 
-----------------------*/
.emp_salary_custom-table-div {
  background-color: #ffffff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.emp_salary_custom-table-div table {
  border-collapse: collapse;
  width: 100%;
}

.emp_salary_custom-table-thead th,
.emp_salary_custom-table-tbody th {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

.emp_salary_custom-table-tbody td {
  border: 1px solid #999;
  text-align: center;
  padding: 8px;
}

.emp_salary_tr_th.exempt {
  border: none !important;
}

.emp_salary_custom-table-thead_sub_tr {
  background-color: #f2f2f2;
}

.emp_salary_custom-table-thead_sub_tr > th:nth-child(1),
.emp_salary_custom-table-thead_sub_tr > th:nth-child(2),
.emp_salary_custom-table-thead_sub_tr > th:nth-child(3),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(1),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(2),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(3) {
  text-align: left;
}

.emp_salary_custom-table-tbody.loading,
.emp_salary_custom-table-tbody.no-data {
  font-weight: 500;
}

table.custom-table thead th {
  border: 2px solid #6c757d !important;
}

/*--------------------- 
    Deduction Card 
-----------------------*/

.deduction_card {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: auto !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.deduction_card > span {
  margin-bottom: 10px;
}

.department_leave_history_span {
  font-size: 14px;
}

/*---------------------- 
    Header Dropdown 
----------------------*/

.header-display-name {
  max-width: 205px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.header_user_profile_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid #999;
}

.profile_image_div {
  height: 100%;
  width: 40%;
  border-radius: 10px;
}

.profile_info_div {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_user_info {
  display: flex;
  flex-direction: column;
}

.profile_name,
.profile_designation,
.profile_ogid {
  margin-bottom: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.profile_name {
  font-weight: 500;
}

.profile_designation,
.profile_ogid {
  font-size: 11px;
  color: #999;
}

.profile_image_div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow:
    rgba(67, 71, 85, 0.733) 0px 0px 0.25em,
    rgba(90, 124, 188, 0.459) 0px 0.25em 1em;
}

.profile_button {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  background-color: var(--prm-color);
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profile_button:hover {
  background-color: #00aedf;
  transition: all 0.3s ease-in-out;
}

.profile_email_div {
  padding: 0 20px;
  margin-bottom: 15px;
}
.profile_email_div > p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profile_email_div > p:hover {
  color: #007bff;
  transition: all 0.3s ease-in-out;
}

.profile_items {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #999;
}
.profile_item_inner {
  padding: 5px 20px;
  cursor: pointer;
}
.profile_item_inner > p {
  margin-bottom: 0;
}
.profile_item_inner:hover {
  background-color: #ececec;
  transition: all 0.3s ease-in-out;
}

.profile_logout_items {
  padding: 10px 0 0 0;
}
.profile_logout_item_inner {
  padding: 5px 20px;
  cursor: pointer;
}
.profile_logout_item_inner > p {
  margin-bottom: 0;
  color: #ff4136;
}
.profile_logout_item_inner:hover {
  background-color: #ececec;
  transition: all 0.3s ease-in-out;
}

/*---------------------- 
    Avatar Span
----------------------*/

.avatar-span {
  background-color: var(--prm-color);
  border-radius: 50%;
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 400;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}

.payroll-table-avatar {
  display: flex;
  align-items: center;
}

.payroll-table-avatar-name {
  display: flex;
  flex-direction: column;
}
.payroll-table-avatar-bankDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payroll-table-avatar-name > span,
.payroll-table-avatar-bankDetails > span {
  color: #808080;
  font-size: 12px;
}

.payroll_month_indicator,
.stage_indicator {
  color: #808080;
  font-weight: 500;
  font-family: var(--app-font-family);
}

/*------------------------ 
    Monthly Attendance
--------------------------*/

.monthly-attendance-table.true {
  min-height: 10vh;
}

.monthly-attendance-table > thead > tr > th:first-child,
.monthly-attendance-table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #d8d8d8;
  border-right: 2px solid #dee2e6;
}

.monthly-attendance-table.true > tbody > tr > td:first-child {
  height: 10vh;
  padding-bottom: 50px;
  background-color: #f6f6f6;
}

/*------------------------ 
    Payslip
--------------------------*/

.payslip-table > table > thead > tr:nth-child(2) > th:first-child,
.payslip-table > table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #d8d8d8;
  border-right: 2px solid #dee2e6;
}

/*---------------------- 
    Resignation
----------------------*/

.resignation_form_inner {
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  outline: 0;
}

.resignation_reason {
  min-height: 100px !important;
}

.resignation_form_footer {
  border-top: none !important;
}

@media only screen and (max-width: 991px) {
  .resignation_icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------- 
    Payroll Date Config
----------------------------*/

.payroll_alert_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.payroll_alert_left {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.edit-icon.payday {
  height: 0;
  margin-left: 10px;
  background-color: #ffffff;
  border-color: var(--prm-color);
}

.form-control.resignation_effective_today {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}

.custom-modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payroll_action_btn_div {
  display: flex;
  justify-content: space-between;
}

/*------------------------- 
    Checkbox Input
----------------------------*/

.dropdown_checkbox > button {
  width: 100%;
  height: 45px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  margin-bottom: 20px;
  background-color: #ffffff;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkblue' height='25' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: top 9px right 0;
}

.checkbox_options {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #667080;
  border-radius: 6px;
  padding: 10px;
  z-index: 1;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1fr);
}

.checkbox_input {
  width: 100%;
  font-size: 14px;
  padding-right: 10px;
}
.checkbox_input > input {
  margin-right: 10px;
}

.reason_for_leaving-not-ok {
  border: 1px solid red !important;
}

.resignation_note {
  font-size: 14px;
}

.resignation_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resignation_search_div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.payroll_search_div {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.payroll-custom-search {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10px 10px 0;
}

.resignation-custom-search {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10px 10px 0;
}

.custom-payroll-search-input {
  width: 100%;
  height: 45px;
  border: 1px solid #66708080;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  background-color: #ffffff;
}

.survey_answers_view {
  padding: 20px 0 0 0;
  border-top: 1px solid #ccc;
}

.feedback_subInfo {
  color: #999;
  font-size: 13px;
  font-weight: 400 !important;
}

/*------------------- 
  Manual Attendance
-------------------- */

.manual-attendance-clock {
  font-family: var(--app-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
}

.clock-container {
  background-color: #242424;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.clock-col {
  text-align: center;
  margin: 0 40px;
  min-width: 100px;
  position: relative;
}

.clock-col:not(:last-child):before,
.clock-col:not(:last-child):after {
  content: "";
  background-color: rgba(255, 255, 255, 0.3);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -42px;
}

.clock-col:not(:last-child):before {
  top: 25%;
}
.clock-col:not(:last-child):after {
  top: 40%;
}
.clock-timer {
  color: #ffffff;
  font-size: 4.2rem;
  text-transform: uppercase;
  font-weight: 500;
}
.clock-label {
  color: rgb(221, 221, 221);
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-top: 10px;
}

@media (max-width: 825px) {
  .clock-container {
    flex-direction: column;
    padding: 40px 0;
  }

  .clock-col + .clock-col {
    margin-top: 20px;
  }
  .clock-col:before,
  .clock-col:after {
    display: none !important;
  }
}

/*----------------
    Extras  
-----------------*/

.login_logo_icons {
  margin-left: -8px;
}
.login_logo_icons.far {
  margin-left: 8px;
}

.login_logo_icons > img {
  border-radius: 50%;
  /* border-radius: 0 0 50% 50%; */
}

.header_logo_icons {
  position: absolute;
  top: 20%;
  right: 13%;
}

.valentine_styling_page {
  font-size: 50px !important;
  font-weight: 700 !important;
  color: var(--sec-color);
  font-family: "Dr Sugiyama", cursive;
  background: -webkit-linear-gradient(#ffffff, var(--sec-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 20px 0 0 20px;
}

.hearts {
  font-size: 15px;
  margin-left: 5px !important;
}

.rose_page {
  font-size: 30px;
}
.hearts_page {
  font-size: 30px;
  margin-left: -15px !important;
}

.page-wrapper-new {
  appearance: none;
  background-image: url("./assets/img/valentines-day-bg-1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 82vh;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.valentine_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
}

.find_val_btn {
  font-family: "Dr Sugiyama", cursive;
  color: #fff;
  width: auto;
  height: auto;
  padding: 10px 20px;
  font-size: 28px;
  border: none;
  cursor: pointer;
  background-color: var(--sec-color);
  transition: all 0.3 ease-in-out;
  border-radius: 5px;
}

.find_val_btn:hover {
  font-size: 28px;
  border: none;
  background-color: var(--prm-color);
  transition: all 0.3 ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.val_pair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.val_pair > p {
  font-family: "Dr Sugiyama", cursive;
  font-size: 40px;
  color: var(--sec-color);
}

.val_pair > h1 {
  font-weight: 600;
}

.confetti {
  position: absolute;
  z-index: 1000;
}

.IWD-page-wrapper {
  position: relative;
  appearance: none;
  background-image: url("./assets/img/IWD-1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: contain; */
  /* background-position: top right 50%; */
  border-radius: 20px;
  height: 95vh;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.IWD_page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.IWD_page-header .breadcrumb a {
  color: #333;
}

.IWD_styling_page {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.IWD_styling_page > h2,
.IWD_styling_page > span {
  font-size: 30px !important;
  font-weight: 600 !important;
  font-family: "Fugaz One", cursive;
  color: #ffffff !important;
}

.IWD_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.IWD_Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.IWD_spinner_icon {
  width: 20%;
  height: 20%;
  border-radius: 10px;
  object-fit: cover;
}

.IWD_Spinner > p {
  position: absolute;
  font-family: "Dr Sugiyama", cursive;
  color: #fff;
  top: 70%;
  left: calc(100% - 54%);
  font-size: 30px !important;
}

#IWD_card_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--app-font-family);
  transition: all 1s ease-in-out;
  border-radius: 20px;
  padding: 0 20px;
}

.scroll_IWD_div {
  background: #fff;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
}
.scroll_IWD_div:hover {
  transform: scale(1.1);
}

.scroll_IWD_button {
  font-size: 18px;
}
.scroll_IWD_button.back {
  rotate: 180deg;
}

.IWD_quote_box {
  min-width: 900px;
  max-width: 900px;
  display: grid;
  margin: auto auto;
  border-radius: 20px;
  padding: 40px 50px;
  display: table;
  background-color: #fff;
  font-weight: 400;
  box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
}

.IWD_quote_text {
  text-align: center;
  height: auto;
  font-weight: 500;
  font-size: 1.75em;
}

.IWD_quote_text > i {
  font-size: 1em;
  margin-right: 0.4em;
}

.IWD_quote_author {
  height: auto;
  clear: both;
  padding-top: 20px;
  font-size: 1em;
  text-align: right;
}

.IWD_button_div {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 150px;
  margin-top: 30px;
}

#new-IWD-quote {
  float: right;
  padding-bottom: 10px;
  border-radius: 19px;
  font-weight: 600px;
  background-color: #333;
}

#new-IWD-quote:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.IWD_Profile_div {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 70%;
  gap: 15px;
}

.IWD_Profile_image_div {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url("./assets/img/IWD-BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
}
.IWD_Profile_image_div > img {
  border-radius: 50%;
}

.IWD_Profile_info_div {
  padding: 5px;
  width: 50%;
}
.IWD_Profile_info_div > p {
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.IWD_countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.new_IWD_button {
  height: 38px;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #333;
  outline: none;
  font-size: 0.85em;
  padding: 8px 18px 6px 18px;
  margin-top: 30px;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  transition: transform 0.5s;
  font-family: var(--app-font-family);
}

@media only screen and (max-width: 900px) {
  .IWD-page-wrapper {
    position: relative;
    appearance: none;
    background-image: url("./assets/img/IWD-1.jpeg");
    background-repeat: repeat;
    background-size: contain;
    border-radius: 20px;
    height: auto;
    box-shadow:
      rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .IWD_styling_page {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .IWD_styling_page > h2,
  .IWD_styling_page > span {
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Fugaz One", cursive;
    color: #ffffff !important;
  }

  .scroll_IWD_div {
    display: none !important;
  }

  .IWD_quote_box {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 100px 5px 30px 5px;
    border-radius: 10px;
    padding: 20px;
    display: table;
    background-color: #fff;
    font-weight: 400;
    box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
  }

  .IWD_button_div {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .IWD_Profile_div {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
    gap: 10px;
  }

  .IWD_Profile_image_div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: url("./assets/img/IWD-BG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  }
  .IWD_Profile_image_div > img {
    border-radius: 50%;
  }

  .IWD_Profile_info_div {
    padding: 5px;
    width: 50%;
  }
  .IWD_Profile_info_div > p {
    margin-bottom: 5px;
    font-size: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .IWD_Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }

  .IWD_spinner_icon {
    width: 80%;
    height: 20%;
    border-radius: 10px;
    object-fit: cover;
  }

  .IWD_Spinner > p {
    position: absolute;
    font-family: "Dr Sugiyama", cursive;
    color: #fff;
    top: 53%;
    left: calc(100% - 70%);
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .scroll_IWD_div {
    display: none !important;
  }
}

.IWD_modal {
  background: #00c5fbcc;
  backdrop-filter: blur(10px);
}

/*--------------- 
    Toastify 
----------------*/

.Toastify__toast-container--top-center {
  width: auto !important;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  font-family: var(--app-font-family);
  padding-left: 20px;
  line-height: 20px;
  padding-top: 25px;
  width: auto;
  font-weight: 400;
}

.Toastify__toast > button > svg {
  display: none;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--info {
  border-radius: 20px !important;
  background: #fff !important;
  padding: 0 20px !important;
}

.Toastify__toast--error::before,
.Toastify__toast--success::before,
.Toastify__toast--warning::before,
.Toastify__toast--info::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--error::after,
.Toastify__toast--success::after,
.Toastify__toast--warning::after,
.Toastify__toast--info::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-family: var(--app-font-family);
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

/* 500 Error Page | Who To Fire? */

.internal_server_error_page {
  height: 100vh;
}

.wtf_box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wtf_box > h3 {
  font-family: var(--app-font-family);
  font-weight: 700;
  font-size: 35px;
  color: var(--sec-color);
}

.wtf_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 95vw;
  padding: 2rem;
  gap: 1.5rem;
}

.wtf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.wtf_crown_wrapper {
  width: 100%;
  height: 20%;
}

.wtf_wrapper {
  overflow: hidden;
  margin: 0 20px;
  padding-bottom: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: crosshair;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.wtf_wrapper:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.wtf_wrapper.data {
  width: 100%;
  height: 80%;
}
.wtf_wrapper.skeleton {
  width: 20%;
  height: 100%;
}

.wtf_p_wrapper > p {
  font-family: var(--app-font-family);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}
.wtf_p_wrapper > p > span {
  font-weight: 800;
  font-size: 18px;
}

.wtf_img_wrapper {
  width: 100%;
  height: 60%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back_btn {
  width: auto;
  padding: 1rem 3rem;
  border: none;
  font-family: var(--app-font-family);
  color: #fff;
  background-color: var(--sec-color);
  cursor: pointer;
  border-radius: 10px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.back_btn:hover {
  color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@media only screen and (max-width: 768px) {
  .internal_server_error_page {
    height: auto;
  }

  .wtf_box {
    padding: 2rem;
  }

  .wtf_wrapper {
    width: 40%;
  }

  .wtf_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: auto;
    gap: 2rem;
  }
}

/*------------------
    eCertificate 
-------------------*/

.eCertificate_container {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.eCertificate_img_wrapper {
  width: 50%;
  height: 100%;
  position: relative;
}

.eCertificate_p_wrapper {
  width: 100%;
  height: 5%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0%;
  letter-spacing: 3px;
  top: calc(100% - 69.5%);
}

.eCertificate_p_wrapper > p {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 3px;
  font-family: "Dancing Script", cursive;
}

.eCertificate_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .eCertificate_container {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .eCertificate_img_wrapper {
    width: 100%;
  }

  .eCertificate_p_wrapper > p {
    font-size: 50px !important;
  }
}

.signature_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.signature_container {
  width: 150px;
}

.signature_container > img {
  width: 100%;
  height: 40%;
}

.signature_container > p {
  margin: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;
}

/*------------------
    Announcement 
-------------------*/

.announcement_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  gap: 2rem;
}

.no_annoucement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}

.image_good,
.video_good {
  color: green;
}

.video_upload_spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.video_upload_spinner > p {
  margin: 0;
}

.custom-video-field-div {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
  gap: 1rem;
}

.video-field-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1.5px solid #9999996c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.video-field-div:hover {
  border: 1.5px solid var(--sec-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.video_player {
  max-height: 160px;
  min-height: 160px;
  width: 100% !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1d1d1d;
}

.video_player_info {
  height: 50%;
  padding: 10px;
  margin-top: 1rem;
}

.video_player_info > h3 {
  font-size: 15px;
}

.video_info_wrapper {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.video_info_wrapper > div:first-child > span {
  width: 100px;
}

.video_info_wrapper > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video_info_wrapper > div:last-child > div {
  display: flex;
  align-items: center;
}

.video_info_wrapper > div:last-child > div > h4 {
  font-size: 11px;
  margin-bottom: 0.2rem;
}

.BsDot {
  margin-top: -0.3rem;
}

.video_info_wrapper > div:last-child > div > span {
  font-size: 11px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.video_info_wrapper > div:last-child > p {
  font-size: 12px;
  margin: 0;
}

.modal_uploaded_by {
  font-size: 15px;
  margin: 0;
}

.span_indicator {
  color: #808080;
}

.video-avatar-span {
  border-radius: 50%;
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 400;
  height: 38px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  position: relative;
  white-space: nowrap;
}

/*------------------
    Newsletter 
-------------------*/

.newsletter_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  gap: 2rem;
}

.custom-newsletter-field-div {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
  gap: 1rem;
}

.newsletter-field-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1.5px solid #9999996c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}
.newsletter-field-div:hover {
  border: 1.5px solid var(--sec-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.newsletter_reader {
  max-height: 160px;
  min-height: 160px;
  width: 100% !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  font-size: 8px;
  overflow: hidden;
}

.newsletter_reader_info {
  height: 50%;
  padding: 10px;
  margin-top: 1rem;
  border-top: 1.5px solid #9999996c;
}

.newsletter_reader_info > h3 {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsletter_info_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.newsletter_info_wrapper > div:first-child {
  display: flex;
  align-items: center;
  gap: 5px;
}

.CgNotes {
  margin: 0;
  font-size: 18px;
  color: var(--sec-color);
}

.HiDotsVertical {
  margin: 0;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.HiDotsVertical:hover {
  color: var(--sec-color);
}

.newsletter_span_indicator {
  font-size: 12px;
  color: #808080;
  margin: 0;
}

.newsletter_indicator_div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  background: var(--sec-color);
  bottom: 6rem;
  right: 10px;
}

.newsletter_absolute_span_indicator {
  font-size: 11px;
  color: #fff;
}

/*------------------
      Survey  
-------------------*/

.survey_builder {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.survey_builder_container {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

/*-----------------------------
      Survey Modal
-------------------------------*/

.survey_result_div {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey_result_div > p {
  margin: 2rem 0 0 0;
  font-weight: 600;
}

.survey_result_div > h1 {
  font-size: 100px;
}

.modal-footer.survey_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.survey_action_error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.IoMdAlert {
  margin-right: 0.5rem;
  font-size: 20px;
}

/*-----------------------------
      Payroll Table
-------------------------------*/

.table_disciplinary_deduction,
.table_disciplinary_deduction:hover {
  color: #333333;
}

.table_disciplinary_deduction_link {
  color: #009efb;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.table_disciplinary_deduction_link:hover {
  color: #1677af;
}

/*-----------------------------
      Employee Payroll
-------------------------------*/

.employee_salary_section {
  background-color: #00c5fb4d;
  width: 100%;
  min-height: 200px;
  margin-bottom: 6rem;
  padding: 2rem;
  border-radius: 20px 20px 0 0;
  position: relative;
}

.employee_salary_section > p {
  font-weight: 500;
  margin-bottom: 5px;
}

.employee_salary_section_top_div {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.employee_salary_section_top_div > h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

.employee_salary_section_top_div > p {
  margin: 0;
  color: #4e4e4e;
  font-size: 13px;
  font-weight: 500;
}

.employee_salary_section_cards {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
  position: absolute;
  width: calc(100% - 4rem);
}

.emp_salary_alert_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.emp_salary_alert_slider {
  background-color: #00c5fb4d !important;
  display: flex !important;
  align-items: center !important;
}
.emp_salary_alert_slider > div {
  display: flex !important;
  align-items: center !important;
  gap: 2rem;
}

.emp_salary_BsDot {
  font-size: 25px;
  color: #000;
}

.salary_span {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.salary_span > p {
  margin-bottom: 5px;
  font-size: 14px;
}

.salary_span > h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.salary_card_container {
  width: 30%;
  height: 140px;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.salary_card_container:hover {
  transform: scale(1.05);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.salary_card_icon_div {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.salary_card_container > p {
  margin: 0;
  font-size: 14px;
}

.salary_card_container > h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

/*-----------------------------
      Attendance Average
-------------------------------*/

.page_container {
  padding: 2rem;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #ccc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.average_attendance_content {
  height: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.average_attendance_card_container {
  width: 30%;
  height: 140px;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.average_attendance_icon_div {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.average_attendance_card_container > p {
  margin: 0;
  font-size: 14px;
}

.average_attendance_card_container > h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

.ave_att_top_div {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.ave_att_bottom_div {
  display: flex;
  gap: 2rem;
  width: 30%;
  height: auto;
}

.victoryDoughnut {
  display: flex;
  justify-content: flex-end;
  width: auto;
  height: 100%;
}

.percentage_average_card {
  width: 30%;
  height: 140px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.percentage_average_card > p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

/*------------------ 
    Progress Bar 
-------------------*/

.progress-bar-text {
  margin: 2rem 0 0 0;
}

.progress-bar {
  position: relative;
  width: 100%;
  background-color: #e0e0df !important;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 2rem;
  height: 25px;
}

.progress-bar__fill {
  position: relative;
  height: 100%;
  background: linear-gradient(270deg, #10ad5f, #0099ff, #10ad5f);
  background-size: 200% 100%;
  animation: flow 2s linear infinite;
}

.progress-bar__text {
  position: absolute;
  width: 100%;
  text-align: right;
  top: 0;
  right: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #000;
}

@keyframes flow {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/*----------------- 
    Virtual ID 
------------------*/

.combined_virtual_id_container {
  display: flex;
  gap: 1rem;
  margin: 2rem 2rem 0.5rem 2rem;
}

/*--------------------- 
    Approval Module
-----------------------*/

.add_btn_new {
  padding: 7px 15px;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: auto !important;
  gap: 0.5rem;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.2) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.add_btn_new.disabled {
  opacity: 0.5;
  background-color: var(--prm-gray);
  border: 1px solid var(--prm-gray);
}

.approval_builder {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 2rem;
}

.approval_builder_inner {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem 2rem 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.approval_builder_container {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

.approval_builder_header {
  margin: 0 0 2rem 1rem;
}

.form-radio-group {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.form-radio {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px 5px 20px;
  border-radius: 15px;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  cursor: pointer;
}

.form-radio-input {
  width: 20px !important;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 20px !important;
  cursor: pointer;
}

.form-radio-label,
.form-checkbox-label {
  margin-bottom: 0;
  cursor: pointer;
}

.form-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.form-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
  padding: 5px 10px 5px 20px;
  border-radius: 10px;
  box-shadow:
    rgba(65, 67, 70, 0.4) 0px 0px 0px 1px,
    rgba(65, 67, 70, 0.3) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.329) 0px 1px 0px inset;
}

.form-checkbox-input {
  width: 15px !important;
  color: #495057;
  background-color: #ffffff;
  accent-color: green;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 15px !important;
}

.form_pills_container {
  min-height: 100px;
  padding: 0.5rem 1rem;
}

.form_pills_container_inner {
  background: #f5f5f5;
  min-height: 100px;
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #ececec;
  box-shadow:
    rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.form_pill_stack {
  width: 100%;
  gap: 0.5rem 0.2rem;
  display: flex;
  flex-wrap: wrap;
}

.approval_form_submit_div {
  padding-bottom: 2rem !important;
}

.approval_builder_stages {
  display: flex;
  gap: 3rem;
  justify-content: center;
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem 2rem 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    overflow: auto;
}

.stages_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative; /* Add this line */
}

.stages_container > span {
  background-color: var(--prm-color-shade);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 25px;
  font-weight: 600;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stages_container > p {
  margin: 0;
  font-weight: 500;
}

.stages_container .line {
  position: absolute;
  top: 30%;
  left: 100%;
  width: 3rem;
  height: 2px;
  background-color: var(--prm-color-shade);
  transform: translateY(-50%);
}

.stages_container:last-child .line {
  display: none;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
}

.modal-body-custom {
  padding: 20px;
}

.task-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.task-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.btn-custom {
  padding: 8px 15px;
  border-radius: 5px;
}

.text-muted {
  color: #6c757d;
}






/* Close button */
.close-btn {
  position: absolute; /* Position it relative to the header */
  right: 1rem; /* Adjust as needed for spacing */
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  line-height: 1;

}

.close-btn:hover {
  color: #ff4d4d;
  transform: scale(1.1);
}

.close-btn:focus {
  outline: none;
}

/* VIEW EVALUATION MODAL  rhema */
.modal-body {
  font-family: Arial, sans-serif;
}
.evaluation-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: solid #ccc 2px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.scrollable-body {
  max-height: 550px;
  overflow-y: auto;
  padding-right: 1rem;
}

.parent-item {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.parent-item:hover {
  background-color: #e9f0f5;
}

.parent-title {
  font-size: 1.2rem;
  font-weight: bold;
  /* color: #0099ff; */
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.child-list {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 1rem;
}

.child-item {
  font-size: 1rem;
  color: #333;
  padding: 0.3rem 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between; /* Align items to the left and right */
}

.child-item:last-child {
  border-bottom: none;
}

/* .evaluate-agent-critical-error {
  font-size: 0.9rem;
  color: #e74c3c;
} */

.evaluate-agent-points {
  font-size: 0.9rem;
  font-weight: normal;
  color: #888;
  text-align: right; /* Align the points on the right */
  margin-left: auto; /* Push it to the right */
  width: auto;
}


/*CUSTOM SWITCH rhema*/
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider::before {
  transform: translateX(24px);
}

.slider:hover {
  background-color: #b3d9ff; /* Light blue hover effect */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

/* EvaluateAgent Page Styling rhema*/
.evaluate-agent-container {
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  max-width: 90%;
}

.evaluate-agent-non-question-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px; /* Add consistent spacing */
}

.evaluate-agent-form-group {
  flex: 1 1 calc(33.33% - 15px); /* Allow 3 items per row with margin */
  margin-bottom: 15px;
  min-width: 280px; /* Ensures minimum size for small screens */
}

.evaluate-agent-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #4a5568;
}

.evaluate-agent-date-input,
.evaluate-agent-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.evaluate-agent-date-input:focus,
.evaluate-agent-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}
/* Card Styling for Categories */
/* .evaluate-agent-card {
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.6s ease-in-out;
  max-width: 100%;
} */

/* .evaluate-agent-card-header {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
} */

/* Questions Styling */
/* .evaluate-agent-question-item {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
} */

/* .evaluate-agent-question-item:hover {
  background: #f0f8ff;
  transform: scale(1.02);
  transition: 0.3s ease;
} */

/* .evaluate-agent-question-select {
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
} */

.evaluate-agent-critical-error {
  color: #d9534f;
  font-weight: bold;
}

/* Button Styles */
.evaluate-agent-submit-button {
  display: block;
  width: 100%;
  max-width: 300px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px auto 0;
  animation: fadeIn 0.8s ease-in-out;
}

.evaluate-agent-submit-button:hover {
  background-color: #0056b3;
}

.evaluate-agent-submit-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .evaluate-agent-form-group {
    flex: 1 1 100%; /* Full width for smaller screens */
    margin-left: 0;
  }

  .evaluate-agent-container {
    padding: 15px;
  }

  .evaluate-agent-card-header {
    font-size: 20px;
  }

  .evaluate-agent-question-item {
    padding: 8px;
    font-size: 14px;
  }

  .evaluate-agent-submit-button {
    max-width: 100%; /* Full width button */
  }
}

@media screen and (max-width: 480px) {
  .evaluate-agent-card-header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .evaluate-agent-date-input,
  .evaluate-agent-select {
    font-size: 13px;
    padding: 8px;
  }

  .evaluate-agent-question-item {
    font-size: 13px;
  }
}
/* EvaluateAgent Page Styling */

/* Evaluation Categories Section */
.evaluate-agent-card {
  background: linear-gradient(135deg, #eef2f3, #ffffff);
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.6s ease-in-out;
}

.evaluate-agent-card-header {
  font-size: 24px;
  font-weight: bold;
  color: #4a5568;
  border-bottom: 2px solid #cbd5e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.evaluate-agent-category {
  margin-bottom: 20px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.evaluate-agent-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.evaluate-agent-category-title {
  font-size: 20px;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 15px;
}

.evaluate-agent-critical-error {
  color: #e53e3e;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  background: #fef2f2;
  padding: 4px 8px;
  border-radius: 4px;
  border-radius: 12px;
}

/* Questions Styling */
.evaluate-agent-question-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.evaluate-agent-question-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #edf2f7;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.evaluate-agent-question-item:hover {
  background: #e2e8f0;
}

.evaluate-agent-question-title {
  font-size: 16px;
  color: #2d3748;
  margin-bottom: 8px;
  margin-right: 10px;
}

.evaluate-agent-question-select {
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  background: #ffffff;
  transition: all 0.2s ease;
}

.evaluate-agent-question-select:focus {
  border-color: #63b3ed;
  box-shadow: 0 0 5px rgba(99, 179, 237, 0.4);
}

/* Points Badge Styling */
/* .evaluate-agent-points {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #4a90e2; 
  padding: 4px 10px;
  border-radius: 12px;
  margin-left: 10px;
  vertical-align: middle;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/*QA dashboard Rhema*/

.campaignbar-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.campaign-bar {
  background-color: white;
  flex: 1 1 45%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  min-width: 300px; /* Optional */
  min-height: 350px;
  border-radius: 10px;
  transition: all 0.3s ease; /* Add transition for smooth animation */
}

.campaign-bar:hover {
  transform: scale(1.05); /* Slight scaling on hover */
  background-color:  #e6f2ff; /* Change background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

@media (max-width: 992px) {
  .campaign-bar {
    flex: 1 1 100%; /* On smaller screens, take full width */
  }
}


/* Styles for the overall evaluation report */
/* Container for the entire Evaluation Report */
.evaluation-view-report-container {
  font-family: Arial, sans-serif;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 1100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Title Section */
.evaluation-view-report-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Category Section */
.evaluation-view-report-category {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Category Title */
.evaluation-view-report-category-title {
  font-size: 1.5rem;
  color: #4e4e4e;
  margin-bottom: 15px;
  font-weight: 600;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

/* Container for the Evaluation Data */
.evaluation-view-report-evaluation-container {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Header Row Styling */
.evaluation-view-report-evaluation-header {
  display: flex;
  background-color: #f7f7f7;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

/* Header Cell Styling */
.evaluation-view-report-evaluation-header-cell {
  flex: 1;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
  color: #555;
}

/* Body Section */
.evaluation-view-report-evaluation-body {
  background-color: #fff;
}

/* Row Styling */
.evaluation-view-report-evaluation-row {
  display: flex;
  border-bottom: 1px solid #ddd;
}

/* Cell Styling */
.evaluation-view-report-evaluation-cell {
  flex: 1;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
  color: #333;
}

/* Alternate Row Background */
.evaluation-view-report-evaluation-row:nth-child(even) {
  background-color: #f9f9f9;
}

/* Optional: Add some padding and spacing to make the rows balanced */
.evaluation-view-report-evaluation-row {
  line-height: 1.6;
}

/* Optional: Add some padding to ensure that the content is spaced out nicely */
.evaluation-view-report-evaluation-header-cell,
.evaluation-view-report-evaluation-cell {
  padding-left: 16px;
  padding-right: 16px;
}

/* Card Container */
.evaluation-view-report-card {
  background-color: white;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Card Title */
.evaluation-view-report-card-title {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

/* Information Row Styling */
.evaluation-view-report-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.evaluation-view-report-info-row div {
  flex: 1;
  margin-right: 10px;
}

.evaluation-view-report-info-row div:last-child {
  margin-right: 0;
}

/* Scoring Section */
.evaluation-view-report-card {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title of the Scoring Summary */
.evaluation-view-report-card-title {
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

/* Styling for the Parent Averages List */
.evaluation-view-report-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.evaluation-view-report-card li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.evaluation-view-report-card li strong {
  font-weight: 600;
  color: #333;
}

/* Optional: Styling for the parent average item */
.evaluation-view-report-card h3 {
  font-size: 1.2rem;
  color: #4e4e4e;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Paragraph Styling for Auto Failure Count and Overall Score */
.evaluation-view-report-card p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.evaluation-view-report-card p strong {
  font-weight: 600;
}

/* Add some spacing between sections */
.evaluation-view-report-card .evaluation-view-report-card-summary {
  margin-top: 20px;
}

/* Optional: Add a border around the list for better grouping */
.evaluation-view-report-card-summary {
  border-top: 2px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
}

/* Specific style for the 'Marks' header cell to align to the right */
.evaluation-view-report-evaluation-header-cell.marks {
  text-align: right;
}

/* Specific style for the 'Marks' cell to align to the right */
.evaluation-view-report-evaluation-cell.marks {
  text-align: right;
}

/* Container for the card row */
.QAD-card-row {
  display: flex; /* Using flexbox to arrange the cards in a row */
  flex-wrap: wrap; /* Allowing cards to wrap to the next line on smaller screens */
  justify-content: space-between; /* Distribute cards evenly */
  gap: 20px; /* Space between the cards */
}

/* Styling for individual cards */
.QAD-card {
  background-color: #fff; /* White background */
  padding: 20px; /* Padding inside the card */
  padding-top: 30px;
  padding-bottom:30px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
  width: calc(20% - 20px); /* Cards take up 20% of the row minus the gap space */
  text-align: center; /* Center the content inside the card */
  font-size: 16px; /* Set font size */
  font-weight: 600; /* Make the text bold */
}

.QAD-card span{
  color: rgb(76, 76, 76);
}

/* Hover effect */
.QAD-card:hover {
  transform: translateY(-10px); /* Lift the card slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .QAD-card {
    width: calc(33.33% - 20px); /* Three cards per row on medium to large screens */
  }
}

@media (max-width: 768px) {
  .QAD-card {
    width: calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media (max-width: 480px) {
  .QAD-card {
    width: 100%; /* Full width cards on small screens */
    padding: 15px; /* Less padding for mobile devices */
  }
}
