html {
  scroll-behavior: smooth;
}

:root {
  --prm-color: #00c5fb;
  --prm-color-shade: #00c5fb4d;
  --sidebar-bg-color: #212a36;
  --sec-color: #0253cc;
  --sec-color-shade: #0253cc4d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
}

/* valentines theme */
/* :root {
  --prm-color: #E63946;
  --prm-color-shade: #dfb5bc4d;
  --sidebar-bg-color: #212a36;
  --sec-color: #C2185B;
  --sec-color-shade: #8c3c4f4d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
} */

/* :root {
  --prm-color: #f4f4d;
  --prm-color-shade: #f14f4d4d;
  --sidebar-bg-color: #804e49;
  --sec-color: #ffa345;
  --sec-color-shade: #ffa3454d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
} */

.page-wrapper {
  min-height: 643px;
}
.float-end {
  float: right !important;
}

.account-wrapper {
  padding: 30px;
}

@media only screen and (max-width: 767.98px) {
  .page-title,
  .dash-widget-info > h3,
  .account-title {
    font-size: 1.5rem;
  }
}
.account-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.account-subtitle {
  color: #4c4c4c;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

.error {
  color: crimson;
  font-size: small;
}
.react-bootstrap-table {
  width: 100%;
}
table {
  /* display: table !important; */
  /* overflow-x: scroll !important; */
}
a {
  cursor: pointer;
}
.rc-404-card {
  margin: 3rem auto;
  padding-left: 4rem;
  padding-top: 4rem;
}
.title-403,
.rc-404-card .title-404 {
  color: var(--prm-color);
  /* color: #ed4b8d; */
  font-family: var(--app-font-family);
  margin-top: 1rem;
  font-size: 37px;
  font-weight: 500;
  letter-spacing: 6px;
  line-height: 40px;
}
.rc-404-card .access-denied {
  color: #434455;
  font-family: var(--app-font-family);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
.rc-404-card .no-permi {
  color: #878999;
  font-family: var(--app-font-family);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

.go-back,
.export-csv {
  box-sizing: border-box;
  border: 2px solid var(--prm-color);
  border-radius: 3px;
  background-color: #ffffff;
  color: #161a37 !important;
  font-family: var(--app-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.go-back {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}
.go-back:hover,
.export-csv:hover {
  background: rgb(0, 197, 251);
  border: 2px solid var(--prm-color);
  color: #ffffff !important;
}
.designation-dropdown {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
    background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  /* margin-left: 30px; */
  height: 54px !important;
  width: 100%;
}
.css-yk16xz-control {
  min-height: 44px !important;
}
.sortable {
  border: 1px solid #dee2e6;
}
.clear {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  font-weight: 400;
  color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.page-item.active .page-link,
#pageDropDown,
.btn-primary {
  z-index: 0;
  color: #ffffff;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #23b5dd;
  border-color: var(--prm-color);
}
.card-header {
  background: #f1f1f1;
  color: #000 !important;
}

.date-filter {
  position: absolute;
  top: 0%;
  right: 25%;
}
@media only screen and (max-width: 768px) {
  .date-filter {
    position: relative;
    top: 0%;
    right: 0%;
  }
}
.react-bs-table-no-data {
  text-align: center;
}
.pos-relative {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #495057;
}
.status-online,
.status-pending,
.status-terminated,
.status-ticket,
.status-strict,
.status-flexible {
  border: 2px solid #ffffff;
  bottom: 3px;
  height: 15px;
  color: #4c4c4c !important;
  position: absolute;
  left: 0;
  width: 15px;
  border-radius: 50%;
}
.status-online {
  background-color: #55ce63;
}
.status-pending {
  background-color: #ceb255;
}
.status-terminated {
  background-color: #c41717;
}
.status-open {
  background-color: #2dcecc;
}
.status-strict {
  background-color: var(--prm-color);
}
.status-flexible {
  background-color: #808080;
}
.btn-gray {
  background: #ffffff;
  border: 1px solid #f1f1f1 !important;
}
.edit-employee > .active {
  display: flex;
}
.btn-white {
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #333;
}

/* Active Sidebar Style */
#sidebar-menu a.active {
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: 500;
  border-right: 5px solid var(--prm-color);
  text-transform: var(--prm-color);
  color: var(--prm-color);
  transition: 0.3s;
}

.select-employee {
  width: 100%;
  margin-left: 0;
}
.form-check-input {
  /* display: block; */
  width: 20px !important;
  /* padding: .375rem .75rem !important; */

  color: #495057;
  background-color: #ffffff;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 20px !important;
}
.form-check-label {
  margin-bottom: 0;
  margin-left: 1rem;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
.table::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.react-bootstrap-table-sort-order .caret:before {
  margin-left: 3.5px;
  content: "\2191";
}
.react-bootstrap-table th .caret:after {
  content: "\2193";
  opacity: 0.4;
}
.header-class {
  box-shadow: 0 0 3px #e5e5e5;
}
.header-class,
tr {
  background-color: #ffffff;
}
tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
.filter-label {
  display: block;
}
.invoice-card {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
  border-radius: 0.375rem;
  min-height: 1px;
  padding: 1.5rem;
  flex: 1 1 auto;
}
.invoice-card2 {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}
.pos-relative {
  position: relative !important;
}

@media print {
  .sidebar {
    margin-left: -225px !important;
  }
  .header {
    display: none !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
}
a.nav-link {
  color: #000;
  border: none;
  transition: all 0.5s;
}

.upload-csv {
  border: 1px dashed #000;
  background: #eff9fc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5% 23px;
  font-size: 20px;
  cursor: pointer;
}
.balance-sheet tbody > tr:nth-of-type(2n + 1) {
  background-color: none !important;
}
.welcome-card {
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  z-index: 0;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: var(--prm-color-shade);
}

@media (min-width: 900px) {
  .left-card {
    padding: 0px 0px 0px 40px;
  }
}

.left-card {
  padding: 24px;
  color: rgb(33, 43, 54) !important;
}
.welcome-text {
  margin: 0px 0px 8px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.25rem;
  font-family: var(--app-font-family);
  color: rgb(33, 43, 54) !important;
}

.welcome-p {
  /* margin: 0px auto; */
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: var(--app-font-family);
  font-weight: 400;
  max-width: 480px;
  padding-bottom: 24px;
}
.go {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: var(--app-font-family);
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: rgb(28, 202, 255);
  box-shadow: rgb(28 202 255 / 24%) 0px 8px 16px 0px;
}

.rec-p {
  color: #2b2929;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 40px;
}

.ju-percent-group {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 138px);
  gap: 0px;
}
.ju-percent-group .d-flex {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.ju-percent-group .ju-percent {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.ju-percent-group h1 {
  font-weight: 800;
  font-size: 2.9375rem;
  line-height: 54px;
  text-align: center;
  color: var(--prm-color);
}

.ju-percent-group p {
  font-size: 1rem;
  line-height: 24px;
  margin: 12px 0px 0px;
  /* background-color: #ed4b8d; */
}
.availableOpening {
  font-size: 2.9375rem;
  line-height: 54px;
  color: #000;
  margin-bottom: 81px;
  font-weight: 800;
  position: relative;
}
.availableOpening::before {
  content: "";
  width: 107px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--prm-color);
  position: absolute;
  bottom: -20px;
  left: 0px;
}

/* multi-step form */
.application-form-header {
  background: var(--prm-color);
  /* color: #f7e3e8; */
  text-align: center;
}
/* CSS */
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.step-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  margin-top: 3px;
  margin-bottom: 5px;
  background-color: var(--prm-gray);
  transition: 0.4s;
}

.step-button[aria-expanded="true"] {
  width: 30px;
  height: 30px;
  background-color: var(--prm-color);
  color: #ffffff;
}
.full-btn {
  width: 100px !important;
  border-radius: 8px !important;
}
.done {
  background-color: var(--prm-color);
  color: #ffffff;
  width: 30px;
  height: 30px;
}

.step-item:nth-child(2) {
  z-index: 10;
  text-align: left;
}
.step-item:nth-child(3) {
  z-index: 10;
  text-align: center;
}
.step-item:nth-child(4) {
  z-index: 10;
  text-align: right;
}

#progress {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  width: 100%;
  z-index: 5;
  height: 4px;
  /* margin-left: 2.5%; */
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  #progress {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    width: 90%;
    z-index: 5;
    height: 4px;
    margin-left: 18px;
    margin-bottom: 18px;
  }
}

/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: var(--prm-color);
  transition: 0.5s ease;
}

#progress::-webkit-progress-bar {
  background-color: var(--prm-gray);
}
.job-field {
  font-weight: 500;
}
.job-field::first-letter {
  text-transform: uppercase;
}
.sliding-text {
  transition: all 1s ease;
  overflow: hidden;
}
.sliding-text div {
  overflow: hidden;
  display: flex;
  animation: scrollText 33s infinite linear;
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.letter-box {
  width: 80%;
  margin: 10% auto;
}

.offer-letter-content {
  padding: 5em;
}

.confirmation {
  text-align: center;
}

.confirmation-btns {
  display: flex;
  justify-content: center;
}

.confirmation-btn {
  margin: 10px;
}

.applicant-signature {
  font-family: "Babylonica", cursive;
}

.signature-line {
  width: 50%;
}

.signature-line > hr {
  background: black;
}
/* multi-step form */

.shift_update_div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.shift_update-warning {
  font-size: 18px;
}

.shift_update_div > p {
  margin: 0;
  color: #999;
}

.dashboard-hero-icon > img {
  width: 100%;
  height: 100%;
}

.sportDayGIF {
  position: absolute;
  width: 100px;
  right: 16%;
}

.slide-out-left {
  -webkit-animation: slide-out-left 10s linear infinite both;
  animation: slide-out-left 10s linear infinite both;
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 1;
  }
}



.question-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Footer */
.modal-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
  text-align: right;
}

/* Valentine stuff Rhema */

.val-wrapper {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
  /* display: flex; */
  z-index: -10;
  justify-content: center;
  align-items: center;
}

.hearts-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.heart {
  position: absolute;
  width: 30px; /* Base width */
  height: 30px; /* Base height */
  background-color: #ff6b6b;
  transform: rotate(-45deg) scale(1); /* Scale for size variation */
  animation: floatUp 8s infinite ease-in-out;
}

.heart::before,
.heart::after {
  content: '';
  position: absolute;
  width: 100%; /* Inherit parent width */
  height: 100%; /* Inherit parent height */
  background-color: inherit;
  border-radius: 50%;
}

.heart::before {
  top: -50%; /* Position to form the top-left lobe */
  left: 0;
}

.heart::after {
  top: 0;
  left: 50%; /* Position to form the top-right lobe */
}
@keyframes floatUp {
  0% {
    transform: translateY(100vh) rotate(-45deg) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-10vh) rotate(-45deg) scale(1);
    opacity: 0;
  }
}
@keyframes float {
  0% {
      transform: translateY(0) rotate(-45deg) scale(1);
      opacity: 1;
  }
  50% {
      transform: translateY(-20px) rotate(-45deg) scale(1);
      opacity: 0.7;
  }
  100% {
      transform: translateY(0) rotate(-45deg) scale(1);
      opacity: 1;
  }
}

/* Different sizes and colors for variety */
.heart:nth-child(1) {
  top: 10%;
  left: 20%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(1.5); /* Scale for size */
  background-color: #ff7675;
  animation-duration: 6s;
}

.heart:nth-child(2) {
  top: 30%;
  left: 50%;
  transform: rotate(-45deg) scale(1.2); /* Scale for size */
  background-color: #ff4757;
  animation-duration: 4s;
}

.heart:nth-child(3) {
  top: 50%;
  left: 90%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(2); /* Scale for size */
  background-color: #ff6b81;
  animation-duration: 7s;
}

.heart:nth-child(4) {
  top: 70%;
  left: 20%;
  transform: rotate(-45deg) scale(1); /* Scale for size */
  background-color: #ffa502;
  animation-duration: 5s;
}

.heart:nth-child(5) {
  top: 80%;
  left: 40%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(1.8); /* Scale for size */
  background-color: #ff6348;
  animation-duration: 6s;
}

.heart:nth-child(6) {
  top: 20%;
  left: 80%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(7) {
  top: 70%;
  left: 80%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(8) {
  top: 70%;
  left: 60%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(9) {
  top: 30%;
  left: 5%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}

.val_login_icon {
  /* position: absolute; */
  width: 50px;
  height: auto; /* Maintain aspect ratio */
}
.val_login_icon2 {
  /* position: absolute; */
  transform: scaleX(-1);
  width: 50px;
  height: auto; /* Maintain aspect ratio */
}