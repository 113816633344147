@import url(https://fonts.googleapis.com/css2?family=Babylonica&display=swap);
.filter {
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  /* justify-content: ; */
  margin-bottom: 30px;
  font-family: var(--app-font-family);
  color: #1f1f1f;
}

.interview_status_filter {
  margin-right: 20px;
}

.monthly_filter select,
.interview_status_filter select,
.processing_stage_filter select {
  padding: 10px;
  margin-top: 5px;
  border: 1px;
  border-color: var(--prm-color);
  border-radius: 5px;
}

/* ERP - Login */

.account-logo {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
}

.account-logo {
  margin-bottom: 50px;
}

.account-logo img {
  width: 150px;
}

.container {
  height: 90vh;
}

.account-box {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 0 auto;
  overflow: hidden;
  width: 45%;
}

.account-box label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}

.account-box .form-control {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height: 46px;
}

.account-box .form-group {
  margin-bottom: 25px;
}
.account-box .account-btn {
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  border: 0;
  border-radius: 4px;
  display: block;
  font-size: 22px;
  padding: 10px 26px;
  width: 100%;
}

.account-box .account-btn:hover,
.account-box .account-btn:focus {
  border: 0;
  opacity: 0.8;
}

.close-account-btn {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 12px !important;
}

.create_ticket_div {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  right: 5%;
  height: 100px;
  width: 100px;
}
.create_ticket_text {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  text-align: center;
  width: 200px;
  bottom: -25%;
  font-family: var(--app-font-family);
  font-weight: 600;
}
.create_ticket_div:hover .create_ticket_text {
  opacity: 1;
}

.login-input::placeholder {
  color: #ccc !important;
  font-weight: 400 !important;
  opacity: 0.5 !important;
}

.emp-dashboard-leave-card {
  height: 240px;
  border-radius: 5px;
  /* min-height: 35vh */
}

.emp-dashboard-leave-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 15px;
}

.app-table-div {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 15px !important;
  padding: 15px !important;
  background-color: #ffffff !important;
}

.table {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border: 1px solid #ccc !important;
}

.custom-table-div {
  background-color: #ffffff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
  overflow-x: auto;
  width: 100%;
}
.custom-field-div {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 100%;
  height: 100%;
}

.monthly_attendance_spinner {
  position: absolute;
  left: 30rem;
}

.rep-siever-status-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #ffffff;
  border-radius: 10px;
  grid-gap: 20px 10px;
  gap: 20px 10px;
  padding: 20px 30px 20px 10px;
  margin-bottom: 30px;
}

.rep-siever-status-card {
  background-color: #ffffff;
  min-width: 20%;
  margin-left: 20px;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.rep-siever-status-card:hover {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.rep-siever-status-card > span {
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
}

.rep-widget-icon {
  border-radius: 100%;
  display: inline-block;
  float: left;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  text-align: center;
  width: 30px;
}

.rep-card-info > h3 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.proof_upload {
  color: #595959;
  height: 40px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: 1.5px solid #528dc280;
  margin-bottom: 25px;
  cursor: pointer;
}

.proof_upload::-webkit-file-upload-button {
  background-color: #528dc2;
  color: #ffffff;
  border: none;
  padding: 4px 15px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.proof_upload:focus {
  outline: 2px solid #528dc280;
}
.proof_upload::-webkit-input-placeholder {
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .hr-filter-select {
    flex-direction: column;
  }

  .rep-siever-status-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: #ffffff;
    border-radius: 10px;
    grid-gap: 20px 10px;
    gap: 20px 10px;
    padding: 20px 30px 20px 10px;
    margin-bottom: 30px;
  }
}

.tl.row {
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 10vh;
  -webkit-user-select: none;
          user-select: none;
}

.tl.card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 400px;
  height: 180px;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.3s ease-in;
  cursor: pointer;
}

.tl.card > h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.tl.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  height: 220px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.tl.card:hover::before {
  transition-delay: 0.2s;
  transform: scale(40);
}

.tl.card:hover {
  transition-delay: 0.2s;
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.26) 0px 22px 70px 4px;
  z-index: 1;
}

.tl.card:hover,
.tl.card:hover h4,
.tl.card:hover p,
.tl.card:hover .tl.link {
  color: #ffffff;
}

.tl.card > p {
  margin-bottom: 5px;
  font-weight: 500;
}

.tl.link {
  color: var(--sec-color);
  text-decoration: none;
}

.edit-icon.teams {
  height: 0;
  margin-left: 50px;
  background-color: #ffffff;
  border-color: var(--prm-color);
}

.logout {
  color: #ff4136 !important;
}

.date_error {
  color: #ff4136;
  font-size: 12px;
  font-style: italic;
  font-family: var(--app-font-family);
  font-weight: 600;
}

.date_error_icon {
  color: #ff4136;
  font-size: 12px;
  margin-right: 5px;
}

/* Payroll Earnings & Deductions */

.payroll_earnings_div {
  margin-bottom: 20px;
  display: flex;
}

.payroll_earnings_container {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-weight: 500;
}
.payroll_earnings_icon {
  color: green;
  font-weight: 500;
  margin-right: 10px;
  font-size: 25px;
}
.payroll_earnings {
  color: green;
}

.payroll_deductions_container {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.payroll_deductions_icon {
  color: red;
  font-weight: 500;
  margin-right: 10px;
  font-size: 25px;
}
.payroll_deductions {
  color: red;
}

/*--------------------- 
Employee Salary Table 
-----------------------*/
.emp_salary_custom-table-div {
  background-color: #ffffff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.emp_salary_custom-table-div table {
  border-collapse: collapse;
  width: 100%;
}

.emp_salary_custom-table-thead th,
.emp_salary_custom-table-tbody th {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

.emp_salary_custom-table-tbody td {
  border: 1px solid #999;
  text-align: center;
  padding: 8px;
}

.emp_salary_tr_th.exempt {
  border: none !important;
}

.emp_salary_custom-table-thead_sub_tr {
  background-color: #f2f2f2;
}

.emp_salary_custom-table-thead_sub_tr > th:nth-child(1),
.emp_salary_custom-table-thead_sub_tr > th:nth-child(2),
.emp_salary_custom-table-thead_sub_tr > th:nth-child(3),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(1),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(2),
.emp_salary_custom-table-tbody_sub_tr > td:nth-child(3) {
  text-align: left;
}

.emp_salary_custom-table-tbody.loading,
.emp_salary_custom-table-tbody.no-data {
  font-weight: 500;
}

table.custom-table thead th {
  border: 2px solid #6c757d !important;
}

/*--------------------- 
    Deduction Card 
-----------------------*/

.deduction_card {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: auto !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.deduction_card > span {
  margin-bottom: 10px;
}

.department_leave_history_span {
  font-size: 14px;
}

/*---------------------- 
    Header Dropdown 
----------------------*/

.header-display-name {
  max-width: 205px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.header_user_profile_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid #999;
}

.profile_image_div {
  height: 100%;
  width: 40%;
  border-radius: 10px;
}

.profile_info_div {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_user_info {
  display: flex;
  flex-direction: column;
}

.profile_name,
.profile_designation,
.profile_ogid {
  margin-bottom: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.profile_name {
  font-weight: 500;
}

.profile_designation,
.profile_ogid {
  font-size: 11px;
  color: #999;
}

.profile_image_div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow:
    rgba(67, 71, 85, 0.733) 0px 0px 0.25em,
    rgba(90, 124, 188, 0.459) 0px 0.25em 1em;
}

.profile_button {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  background-color: var(--prm-color);
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profile_button:hover {
  background-color: #00aedf;
  transition: all 0.3s ease-in-out;
}

.profile_email_div {
  padding: 0 20px;
  margin-bottom: 15px;
}
.profile_email_div > p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profile_email_div > p:hover {
  color: #007bff;
  transition: all 0.3s ease-in-out;
}

.profile_items {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #999;
}
.profile_item_inner {
  padding: 5px 20px;
  cursor: pointer;
}
.profile_item_inner > p {
  margin-bottom: 0;
}
.profile_item_inner:hover {
  background-color: #ececec;
  transition: all 0.3s ease-in-out;
}

.profile_logout_items {
  padding: 10px 0 0 0;
}
.profile_logout_item_inner {
  padding: 5px 20px;
  cursor: pointer;
}
.profile_logout_item_inner > p {
  margin-bottom: 0;
  color: #ff4136;
}
.profile_logout_item_inner:hover {
  background-color: #ececec;
  transition: all 0.3s ease-in-out;
}

/*---------------------- 
    Avatar Span
----------------------*/

.avatar-span {
  background-color: var(--prm-color);
  border-radius: 50%;
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 400;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}

.payroll-table-avatar {
  display: flex;
  align-items: center;
}

.payroll-table-avatar-name {
  display: flex;
  flex-direction: column;
}
.payroll-table-avatar-bankDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payroll-table-avatar-name > span,
.payroll-table-avatar-bankDetails > span {
  color: #808080;
  font-size: 12px;
}

.payroll_month_indicator,
.stage_indicator {
  color: #808080;
  font-weight: 500;
  font-family: var(--app-font-family);
}

/*------------------------ 
    Monthly Attendance
--------------------------*/

.monthly-attendance-table.true {
  min-height: 10vh;
}

.monthly-attendance-table > thead > tr > th:first-child,
.monthly-attendance-table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #d8d8d8;
  border-right: 2px solid #dee2e6;
}

.monthly-attendance-table.true > tbody > tr > td:first-child {
  height: 10vh;
  padding-bottom: 50px;
  background-color: #f6f6f6;
}

/*------------------------ 
    Payslip
--------------------------*/

.payslip-table > table > thead > tr:nth-child(2) > th:first-child,
.payslip-table > table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #d8d8d8;
  border-right: 2px solid #dee2e6;
}

/*---------------------- 
    Resignation
----------------------*/

.resignation_form_inner {
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  outline: 0;
}

.resignation_reason {
  min-height: 100px !important;
}

.resignation_form_footer {
  border-top: none !important;
}

@media only screen and (max-width: 991px) {
  .resignation_icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------- 
    Payroll Date Config
----------------------------*/

.payroll_alert_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.payroll_alert_left {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.edit-icon.payday {
  height: 0;
  margin-left: 10px;
  background-color: #ffffff;
  border-color: var(--prm-color);
}

.form-control.resignation_effective_today {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}

.custom-modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payroll_action_btn_div {
  display: flex;
  justify-content: space-between;
}

/*------------------------- 
    Checkbox Input
----------------------------*/

.dropdown_checkbox > button {
  width: 100%;
  height: 45px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  margin-bottom: 20px;
  background-color: #ffffff;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkblue' height='25' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: top 9px right 0;
}

.checkbox_options {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #667080;
  border-radius: 6px;
  padding: 10px;
  z-index: 1;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1fr);
}

.checkbox_input {
  width: 100%;
  font-size: 14px;
  padding-right: 10px;
}
.checkbox_input > input {
  margin-right: 10px;
}

.reason_for_leaving-not-ok {
  border: 1px solid red !important;
}

.resignation_note {
  font-size: 14px;
}

.resignation_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resignation_search_div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.payroll_search_div {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.payroll-custom-search {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10px 10px 0;
}

.resignation-custom-search {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10px 10px 0;
}

.custom-payroll-search-input {
  width: 100%;
  height: 45px;
  border: 1px solid #66708080;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  background-color: #ffffff;
}

.survey_answers_view {
  padding: 20px 0 0 0;
  border-top: 1px solid #ccc;
}

.feedback_subInfo {
  color: #999;
  font-size: 13px;
  font-weight: 400 !important;
}

/*------------------- 
  Manual Attendance
-------------------- */

.manual-attendance-clock {
  font-family: var(--app-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
}

.clock-container {
  background-color: #242424;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.clock-col {
  text-align: center;
  margin: 0 40px;
  min-width: 100px;
  position: relative;
}

.clock-col:not(:last-child):before,
.clock-col:not(:last-child):after {
  content: "";
  background-color: rgba(255, 255, 255, 0.3);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -42px;
}

.clock-col:not(:last-child):before {
  top: 25%;
}
.clock-col:not(:last-child):after {
  top: 40%;
}
.clock-timer {
  color: #ffffff;
  font-size: 4.2rem;
  text-transform: uppercase;
  font-weight: 500;
}
.clock-label {
  color: rgb(221, 221, 221);
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-top: 10px;
}

@media (max-width: 825px) {
  .clock-container {
    flex-direction: column;
    padding: 40px 0;
  }

  .clock-col + .clock-col {
    margin-top: 20px;
  }
  .clock-col:before,
  .clock-col:after {
    display: none !important;
  }
}

/*----------------
    Extras  
-----------------*/

.login_logo_icons {
  margin-left: -8px;
}
.login_logo_icons.far {
  margin-left: 8px;
}

.login_logo_icons > img {
  border-radius: 50%;
  /* border-radius: 0 0 50% 50%; */
}

.header_logo_icons {
  position: absolute;
  top: 20%;
  right: 13%;
}

.valentine_styling_page {
  font-size: 50px !important;
  font-weight: 700 !important;
  color: var(--sec-color);
  font-family: "Dr Sugiyama", cursive;
  background: -webkit-linear-gradient(#ffffff, var(--sec-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 20px 0 0 20px;
}

.hearts {
  font-size: 15px;
  margin-left: 5px !important;
}

.rose_page {
  font-size: 30px;
}
.hearts_page {
  font-size: 30px;
  margin-left: -15px !important;
}

.page-wrapper-new {
  -webkit-appearance: none;
          appearance: none;
  background-image: url(/static/media/valentines-day-bg-1.d8a003a9.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 82vh;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.valentine_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
}

.find_val_btn {
  font-family: "Dr Sugiyama", cursive;
  color: #fff;
  width: auto;
  height: auto;
  padding: 10px 20px;
  font-size: 28px;
  border: none;
  cursor: pointer;
  background-color: var(--sec-color);
  transition: all 0.3 ease-in-out;
  border-radius: 5px;
}

.find_val_btn:hover {
  font-size: 28px;
  border: none;
  background-color: var(--prm-color);
  transition: all 0.3 ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.val_pair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.val_pair > p {
  font-family: "Dr Sugiyama", cursive;
  font-size: 40px;
  color: var(--sec-color);
}

.val_pair > h1 {
  font-weight: 600;
}

.confetti {
  position: absolute;
  z-index: 1000;
}

.IWD-page-wrapper {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  background-image: url(/static/media/IWD-1.121188c9.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: contain; */
  /* background-position: top right 50%; */
  border-radius: 20px;
  height: 95vh;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.IWD_page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.IWD_page-header .breadcrumb a {
  color: #333;
}

.IWD_styling_page {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.IWD_styling_page > h2,
.IWD_styling_page > span {
  font-size: 30px !important;
  font-weight: 600 !important;
  font-family: "Fugaz One", cursive;
  color: #ffffff !important;
}

.IWD_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.IWD_Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.IWD_spinner_icon {
  width: 20%;
  height: 20%;
  border-radius: 10px;
  object-fit: cover;
}

.IWD_Spinner > p {
  position: absolute;
  font-family: "Dr Sugiyama", cursive;
  color: #fff;
  top: 70%;
  left: calc(100% - 54%);
  font-size: 30px !important;
}

#IWD_card_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--app-font-family);
  transition: all 1s ease-in-out;
  border-radius: 20px;
  padding: 0 20px;
}

.scroll_IWD_div {
  background: #fff;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
}
.scroll_IWD_div:hover {
  transform: scale(1.1);
}

.scroll_IWD_button {
  font-size: 18px;
}
.scroll_IWD_button.back {
  rotate: 180deg;
}

.IWD_quote_box {
  min-width: 900px;
  max-width: 900px;
  display: grid;
  margin: auto auto;
  border-radius: 20px;
  padding: 40px 50px;
  display: table;
  background-color: #fff;
  font-weight: 400;
  box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
}

.IWD_quote_text {
  text-align: center;
  height: auto;
  font-weight: 500;
  font-size: 1.75em;
}

.IWD_quote_text > i {
  font-size: 1em;
  margin-right: 0.4em;
}

.IWD_quote_author {
  height: auto;
  clear: both;
  padding-top: 20px;
  font-size: 1em;
  text-align: right;
}

.IWD_button_div {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 150px;
  margin-top: 30px;
}

#new-IWD-quote {
  float: right;
  padding-bottom: 10px;
  border-radius: 19px;
  font-weight: 600px;
  background-color: #333;
}

#new-IWD-quote:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.IWD_Profile_div {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 70%;
  grid-gap: 15px;
  gap: 15px;
}

.IWD_Profile_image_div {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url(/static/media/IWD-BG.792cd5ae.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
}
.IWD_Profile_image_div > img {
  border-radius: 50%;
}

.IWD_Profile_info_div {
  padding: 5px;
  width: 50%;
}
.IWD_Profile_info_div > p {
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.IWD_countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.new_IWD_button {
  height: 38px;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #333;
  outline: none;
  font-size: 0.85em;
  padding: 8px 18px 6px 18px;
  margin-top: 30px;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  transition: transform 0.5s;
  font-family: var(--app-font-family);
}

@media only screen and (max-width: 900px) {
  .IWD-page-wrapper {
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    background-image: url(/static/media/IWD-1.121188c9.jpeg);
    background-repeat: repeat;
    background-size: contain;
    border-radius: 20px;
    height: auto;
    box-shadow:
      rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .IWD_styling_page {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .IWD_styling_page > h2,
  .IWD_styling_page > span {
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Fugaz One", cursive;
    color: #ffffff !important;
  }

  .scroll_IWD_div {
    display: none !important;
  }

  .IWD_quote_box {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 100px 5px 30px 5px;
    border-radius: 10px;
    padding: 20px;
    display: table;
    background-color: #fff;
    font-weight: 400;
    box-shadow: 0px 0px 5px 5px rgba(88, 88, 85, 0.4);
  }

  .IWD_button_div {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .IWD_Profile_div {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
    grid-gap: 10px;
    gap: 10px;
  }

  .IWD_Profile_image_div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: url(/static/media/IWD-BG.792cd5ae.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 5px 2px rgb(88, 88, 85, 0.4);
  }
  .IWD_Profile_image_div > img {
    border-radius: 50%;
  }

  .IWD_Profile_info_div {
    padding: 5px;
    width: 50%;
  }
  .IWD_Profile_info_div > p {
    margin-bottom: 5px;
    font-size: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .IWD_Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }

  .IWD_spinner_icon {
    width: 80%;
    height: 20%;
    border-radius: 10px;
    object-fit: cover;
  }

  .IWD_Spinner > p {
    position: absolute;
    font-family: "Dr Sugiyama", cursive;
    color: #fff;
    top: 53%;
    left: calc(100% - 70%);
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .scroll_IWD_div {
    display: none !important;
  }
}

.IWD_modal {
  background: #00c5fbcc;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

/*--------------- 
    Toastify 
----------------*/

.Toastify__toast-container--top-center {
  width: auto !important;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  font-family: var(--app-font-family);
  padding-left: 20px;
  line-height: 20px;
  padding-top: 25px;
  width: auto;
  font-weight: 400;
}

.Toastify__toast > button > svg {
  display: none;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--info {
  border-radius: 20px !important;
  background: #fff !important;
  padding: 0 20px !important;
}

.Toastify__toast--error::before,
.Toastify__toast--success::before,
.Toastify__toast--warning::before,
.Toastify__toast--info::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--error::after,
.Toastify__toast--success::after,
.Toastify__toast--warning::after,
.Toastify__toast--info::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-family: var(--app-font-family);
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

/* 500 Error Page | Who To Fire? */

.internal_server_error_page {
  height: 100vh;
}

.wtf_box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wtf_box > h3 {
  font-family: var(--app-font-family);
  font-weight: 700;
  font-size: 35px;
  color: var(--sec-color);
}

.wtf_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 95vw;
  padding: 2rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.wtf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.wtf_crown_wrapper {
  width: 100%;
  height: 20%;
}

.wtf_wrapper {
  overflow: hidden;
  margin: 0 20px;
  padding-bottom: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: crosshair;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.wtf_wrapper:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.wtf_wrapper.data {
  width: 100%;
  height: 80%;
}
.wtf_wrapper.skeleton {
  width: 20%;
  height: 100%;
}

.wtf_p_wrapper > p {
  font-family: var(--app-font-family);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}
.wtf_p_wrapper > p > span {
  font-weight: 800;
  font-size: 18px;
}

.wtf_img_wrapper {
  width: 100%;
  height: 60%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back_btn {
  width: auto;
  padding: 1rem 3rem;
  border: none;
  font-family: var(--app-font-family);
  color: #fff;
  background-color: var(--sec-color);
  cursor: pointer;
  border-radius: 10px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.back_btn:hover {
  color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@media only screen and (max-width: 768px) {
  .internal_server_error_page {
    height: auto;
  }

  .wtf_box {
    padding: 2rem;
  }

  .wtf_wrapper {
    width: 40%;
  }

  .wtf_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: auto;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

/*------------------
    eCertificate 
-------------------*/

.eCertificate_container {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.eCertificate_img_wrapper {
  width: 50%;
  height: 100%;
  position: relative;
}

.eCertificate_p_wrapper {
  width: 100%;
  height: 5%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0%;
  letter-spacing: 3px;
  top: calc(100% - 69.5%);
}

.eCertificate_p_wrapper > p {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 3px;
  font-family: "Dancing Script", cursive;
}

.eCertificate_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .eCertificate_container {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .eCertificate_img_wrapper {
    width: 100%;
  }

  .eCertificate_p_wrapper > p {
    font-size: 50px !important;
  }
}

.signature_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.signature_container {
  width: 150px;
}

.signature_container > img {
  width: 100%;
  height: 40%;
}

.signature_container > p {
  margin: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;
}

/*------------------
    Announcement 
-------------------*/

.announcement_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.no_annoucement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.image_good,
.video_good {
  color: green;
}

.video_upload_spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.video_upload_spinner > p {
  margin: 0;
}

.custom-video-field-div {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.video-field-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1.5px solid #9999996c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.video-field-div:hover {
  border: 1.5px solid var(--sec-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.video_player {
  max-height: 160px;
  min-height: 160px;
  width: 100% !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1d1d1d;
}

.video_player_info {
  height: 50%;
  padding: 10px;
  margin-top: 1rem;
}

.video_player_info > h3 {
  font-size: 15px;
}

.video_info_wrapper {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.video_info_wrapper > div:first-child > span {
  width: 100px;
}

.video_info_wrapper > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video_info_wrapper > div:last-child > div {
  display: flex;
  align-items: center;
}

.video_info_wrapper > div:last-child > div > h4 {
  font-size: 11px;
  margin-bottom: 0.2rem;
}

.BsDot {
  margin-top: -0.3rem;
}

.video_info_wrapper > div:last-child > div > span {
  font-size: 11px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.video_info_wrapper > div:last-child > p {
  font-size: 12px;
  margin: 0;
}

.modal_uploaded_by {
  font-size: 15px;
  margin: 0;
}

.span_indicator {
  color: #808080;
}

.video-avatar-span {
  border-radius: 50%;
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 400;
  height: 38px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  position: relative;
  white-space: nowrap;
}

/*------------------
    Newsletter 
-------------------*/

.newsletter_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.custom-newsletter-field-div {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.newsletter-field-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1.5px solid #9999996c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}
.newsletter-field-div:hover {
  border: 1.5px solid var(--sec-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.newsletter_reader {
  max-height: 160px;
  min-height: 160px;
  width: 100% !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  font-size: 8px;
  overflow: hidden;
}

.newsletter_reader_info {
  height: 50%;
  padding: 10px;
  margin-top: 1rem;
  border-top: 1.5px solid #9999996c;
}

.newsletter_reader_info > h3 {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsletter_info_wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.newsletter_info_wrapper > div:first-child {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.CgNotes {
  margin: 0;
  font-size: 18px;
  color: var(--sec-color);
}

.HiDotsVertical {
  margin: 0;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.HiDotsVertical:hover {
  color: var(--sec-color);
}

.newsletter_span_indicator {
  font-size: 12px;
  color: #808080;
  margin: 0;
}

.newsletter_indicator_div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  background: var(--sec-color);
  bottom: 6rem;
  right: 10px;
}

.newsletter_absolute_span_indicator {
  font-size: 11px;
  color: #fff;
}

/*------------------
      Survey  
-------------------*/

.survey_builder {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.survey_builder_container {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

/*-----------------------------
      Survey Modal
-------------------------------*/

.survey_result_div {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey_result_div > p {
  margin: 2rem 0 0 0;
  font-weight: 600;
}

.survey_result_div > h1 {
  font-size: 100px;
}

.modal-footer.survey_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.survey_action_error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.IoMdAlert {
  margin-right: 0.5rem;
  font-size: 20px;
}

/*-----------------------------
      Payroll Table
-------------------------------*/

.table_disciplinary_deduction,
.table_disciplinary_deduction:hover {
  color: #333333;
}

.table_disciplinary_deduction_link {
  color: #009efb;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.table_disciplinary_deduction_link:hover {
  color: #1677af;
}

/*-----------------------------
      Employee Payroll
-------------------------------*/

.employee_salary_section {
  background-color: #00c5fb4d;
  width: 100%;
  min-height: 200px;
  margin-bottom: 6rem;
  padding: 2rem;
  border-radius: 20px 20px 0 0;
  position: relative;
}

.employee_salary_section > p {
  font-weight: 500;
  margin-bottom: 5px;
}

.employee_salary_section_top_div {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.employee_salary_section_top_div > h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

.employee_salary_section_top_div > p {
  margin: 0;
  color: #4e4e4e;
  font-size: 13px;
  font-weight: 500;
}

.employee_salary_section_cards {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  position: absolute;
  width: calc(100% - 4rem);
}

.emp_salary_alert_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.emp_salary_alert_slider {
  background-color: #00c5fb4d !important;
  display: flex !important;
  align-items: center !important;
}
.emp_salary_alert_slider > div {
  display: flex !important;
  align-items: center !important;
  grid-gap: 2rem;
  gap: 2rem;
}

.emp_salary_BsDot {
  font-size: 25px;
  color: #000;
}

.salary_span {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.salary_span > p {
  margin-bottom: 5px;
  font-size: 14px;
}

.salary_span > h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.salary_card_container {
  width: 30%;
  height: 140px;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.salary_card_container:hover {
  transform: scale(1.05);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.salary_card_icon_div {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.salary_card_container > p {
  margin: 0;
  font-size: 14px;
}

.salary_card_container > h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

/*-----------------------------
      Attendance Average
-------------------------------*/

.page_container {
  padding: 2rem;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #ccc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.average_attendance_content {
  height: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.average_attendance_card_container {
  width: 30%;
  height: 140px;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.average_attendance_icon_div {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.average_attendance_card_container > p {
  margin: 0;
  font-size: 14px;
}

.average_attendance_card_container > h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

.ave_att_top_div {
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.ave_att_bottom_div {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  width: 30%;
  height: auto;
}

.victoryDoughnut {
  display: flex;
  justify-content: flex-end;
  width: auto;
  height: 100%;
}

.percentage_average_card {
  width: 30%;
  height: 140px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.percentage_average_card > p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

/*------------------ 
    Progress Bar 
-------------------*/

.progress-bar-text {
  margin: 2rem 0 0 0;
}

.progress-bar {
  position: relative;
  width: 100%;
  background-color: #e0e0df !important;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 2rem;
  height: 25px;
}

.progress-bar__fill {
  position: relative;
  height: 100%;
  background: linear-gradient(270deg, #10ad5f, #0099ff, #10ad5f);
  background-size: 200% 100%;
  animation: flow 2s linear infinite;
}

.progress-bar__text {
  position: absolute;
  width: 100%;
  text-align: right;
  top: 0;
  right: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #000;
}

@keyframes flow {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/*----------------- 
    Virtual ID 
------------------*/

.combined_virtual_id_container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 2rem 0.5rem 2rem;
}

/*--------------------- 
    Approval Module
-----------------------*/

.add_btn_new {
  padding: 7px 15px;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: auto !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.2) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.add_btn_new.disabled {
  opacity: 0.5;
  background-color: var(--prm-gray);
  border: 1px solid var(--prm-gray);
}

.approval_builder {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 2rem;
}

.approval_builder_inner {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem 2rem 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.approval_builder_container {
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

.approval_builder_header {
  margin: 0 0 2rem 1rem;
}

.form-radio-group {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.form-radio {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 5px 10px 5px 20px;
  border-radius: 15px;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  cursor: pointer;
}

.form-radio-input {
  width: 20px !important;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 20px !important;
  cursor: pointer;
}

.form-radio-label,
.form-checkbox-label {
  margin-bottom: 0;
  cursor: pointer;
}

.form-checkbox-group {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.form-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 5px 10px 5px 20px;
  border-radius: 10px;
  box-shadow:
    rgba(65, 67, 70, 0.4) 0px 0px 0px 1px,
    rgba(65, 67, 70, 0.3) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.329) 0px 1px 0px inset;
}

.form-checkbox-input {
  width: 15px !important;
  color: #495057;
  background-color: #ffffff;
  accent-color: green;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 15px !important;
}

.form_pills_container {
  min-height: 100px;
  padding: 0.5rem 1rem;
}

.form_pills_container_inner {
  background: #f5f5f5;
  min-height: 100px;
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #ececec;
  box-shadow:
    rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.form_pill_stack {
  width: 100%;
  grid-gap: 0.5rem 0.2rem;
  gap: 0.5rem 0.2rem;
  display: flex;
  flex-wrap: wrap;
}

.approval_form_submit_div {
  padding-bottom: 2rem !important;
}

.approval_builder_stages {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;
  justify-content: center;
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: 0 1rem 2rem 1rem;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    overflow: auto;
}

.stages_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative; /* Add this line */
}

.stages_container > span {
  background-color: var(--prm-color-shade);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 25px;
  font-weight: 600;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stages_container > p {
  margin: 0;
  font-weight: 500;
}

.stages_container .line {
  position: absolute;
  top: 30%;
  left: 100%;
  width: 3rem;
  height: 2px;
  background-color: var(--prm-color-shade);
  transform: translateY(-50%);
}

.stages_container:last-child .line {
  display: none;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
}

.modal-body-custom {
  padding: 20px;
}

.task-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.task-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.btn-custom {
  padding: 8px 15px;
  border-radius: 5px;
}

.text-muted {
  color: #6c757d;
}






/* Close button */
.close-btn {
  position: absolute; /* Position it relative to the header */
  right: 1rem; /* Adjust as needed for spacing */
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  line-height: 1;

}

.close-btn:hover {
  color: #ff4d4d;
  transform: scale(1.1);
}

.close-btn:focus {
  outline: none;
}

/* VIEW EVALUATION MODAL  rhema */
.modal-body {
  font-family: Arial, sans-serif;
}
.evaluation-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: solid #ccc 2px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.scrollable-body {
  max-height: 550px;
  overflow-y: auto;
  padding-right: 1rem;
}

.parent-item {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.parent-item:hover {
  background-color: #e9f0f5;
}

.parent-title {
  font-size: 1.2rem;
  font-weight: bold;
  /* color: #0099ff; */
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.child-list {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 1rem;
}

.child-item {
  font-size: 1rem;
  color: #333;
  padding: 0.3rem 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between; /* Align items to the left and right */
}

.child-item:last-child {
  border-bottom: none;
}

/* .evaluate-agent-critical-error {
  font-size: 0.9rem;
  color: #e74c3c;
} */

.evaluate-agent-points {
  font-size: 0.9rem;
  font-weight: normal;
  color: #888;
  text-align: right; /* Align the points on the right */
  margin-left: auto; /* Push it to the right */
  width: auto;
}


/*CUSTOM SWITCH rhema*/
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider::before {
  transform: translateX(24px);
}

.slider:hover {
  background-color: #b3d9ff; /* Light blue hover effect */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

/* EvaluateAgent Page Styling rhema*/
.evaluate-agent-container {
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  max-width: 90%;
}

.evaluate-agent-non-question-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px; /* Add consistent spacing */
}

.evaluate-agent-form-group {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.33% - 15px); /* Allow 3 items per row with margin */
  margin-bottom: 15px;
  min-width: 280px; /* Ensures minimum size for small screens */
}

.evaluate-agent-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #4a5568;
}

.evaluate-agent-date-input,
.evaluate-agent-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.evaluate-agent-date-input:focus,
.evaluate-agent-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}
/* Card Styling for Categories */
/* .evaluate-agent-card {
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.6s ease-in-out;
  max-width: 100%;
} */

/* .evaluate-agent-card-header {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
} */

/* Questions Styling */
/* .evaluate-agent-question-item {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
} */

/* .evaluate-agent-question-item:hover {
  background: #f0f8ff;
  transform: scale(1.02);
  transition: 0.3s ease;
} */

/* .evaluate-agent-question-select {
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
} */

.evaluate-agent-critical-error {
  color: #d9534f;
  font-weight: bold;
}

/* Button Styles */
.evaluate-agent-submit-button {
  display: block;
  width: 100%;
  max-width: 300px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px auto 0;
  animation: fadeIn 0.8s ease-in-out;
}

.evaluate-agent-submit-button:hover {
  background-color: #0056b3;
}

.evaluate-agent-submit-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .evaluate-agent-form-group {
    flex: 1 1 100%; /* Full width for smaller screens */
    margin-left: 0;
  }

  .evaluate-agent-container {
    padding: 15px;
  }

  .evaluate-agent-card-header {
    font-size: 20px;
  }

  .evaluate-agent-question-item {
    padding: 8px;
    font-size: 14px;
  }

  .evaluate-agent-submit-button {
    max-width: 100%; /* Full width button */
  }
}

@media screen and (max-width: 480px) {
  .evaluate-agent-card-header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .evaluate-agent-date-input,
  .evaluate-agent-select {
    font-size: 13px;
    padding: 8px;
  }

  .evaluate-agent-question-item {
    font-size: 13px;
  }
}
/* EvaluateAgent Page Styling */

/* Evaluation Categories Section */
.evaluate-agent-card {
  background: linear-gradient(135deg, #eef2f3, #ffffff);
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.6s ease-in-out;
}

.evaluate-agent-card-header {
  font-size: 24px;
  font-weight: bold;
  color: #4a5568;
  border-bottom: 2px solid #cbd5e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.evaluate-agent-category {
  margin-bottom: 20px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.evaluate-agent-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.evaluate-agent-category-title {
  font-size: 20px;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 15px;
}

.evaluate-agent-critical-error {
  color: #e53e3e;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  background: #fef2f2;
  padding: 4px 8px;
  border-radius: 4px;
  border-radius: 12px;
}

/* Questions Styling */
.evaluate-agent-question-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.evaluate-agent-question-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #edf2f7;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.evaluate-agent-question-item:hover {
  background: #e2e8f0;
}

.evaluate-agent-question-title {
  font-size: 16px;
  color: #2d3748;
  margin-bottom: 8px;
  margin-right: 10px;
}

.evaluate-agent-question-select {
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  background: #ffffff;
  transition: all 0.2s ease;
}

.evaluate-agent-question-select:focus {
  border-color: #63b3ed;
  box-shadow: 0 0 5px rgba(99, 179, 237, 0.4);
}

/* Points Badge Styling */
/* .evaluate-agent-points {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #4a90e2; 
  padding: 4px 10px;
  border-radius: 12px;
  margin-left: 10px;
  vertical-align: middle;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/*QA dashboard Rhema*/

.campaignbar-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.campaign-bar {
  background-color: white;
  flex: 1 1 45%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  min-width: 300px; /* Optional */
  min-height: 350px;
  border-radius: 10px;
  transition: all 0.3s ease; /* Add transition for smooth animation */
}

.campaign-bar:hover {
  transform: scale(1.05); /* Slight scaling on hover */
  background-color:  #e6f2ff; /* Change background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

@media (max-width: 992px) {
  .campaign-bar {
    flex: 1 1 100%; /* On smaller screens, take full width */
  }
}


/* Styles for the overall evaluation report */
/* Container for the entire Evaluation Report */
.evaluation-view-report-container {
  font-family: Arial, sans-serif;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 1100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Title Section */
.evaluation-view-report-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Category Section */
.evaluation-view-report-category {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Category Title */
.evaluation-view-report-category-title {
  font-size: 1.5rem;
  color: #4e4e4e;
  margin-bottom: 15px;
  font-weight: 600;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

/* Container for the Evaluation Data */
.evaluation-view-report-evaluation-container {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Header Row Styling */
.evaluation-view-report-evaluation-header {
  display: flex;
  background-color: #f7f7f7;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

/* Header Cell Styling */
.evaluation-view-report-evaluation-header-cell {
  flex: 1 1;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
  color: #555;
}

/* Body Section */
.evaluation-view-report-evaluation-body {
  background-color: #fff;
}

/* Row Styling */
.evaluation-view-report-evaluation-row {
  display: flex;
  border-bottom: 1px solid #ddd;
}

/* Cell Styling */
.evaluation-view-report-evaluation-cell {
  flex: 1 1;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
  color: #333;
}

/* Alternate Row Background */
.evaluation-view-report-evaluation-row:nth-child(even) {
  background-color: #f9f9f9;
}

/* Optional: Add some padding and spacing to make the rows balanced */
.evaluation-view-report-evaluation-row {
  line-height: 1.6;
}

/* Optional: Add some padding to ensure that the content is spaced out nicely */
.evaluation-view-report-evaluation-header-cell,
.evaluation-view-report-evaluation-cell {
  padding-left: 16px;
  padding-right: 16px;
}

/* Card Container */
.evaluation-view-report-card {
  background-color: white;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Card Title */
.evaluation-view-report-card-title {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

/* Information Row Styling */
.evaluation-view-report-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.evaluation-view-report-info-row div {
  flex: 1 1;
  margin-right: 10px;
}

.evaluation-view-report-info-row div:last-child {
  margin-right: 0;
}

/* Scoring Section */
.evaluation-view-report-card {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title of the Scoring Summary */
.evaluation-view-report-card-title {
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

/* Styling for the Parent Averages List */
.evaluation-view-report-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.evaluation-view-report-card li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.evaluation-view-report-card li strong {
  font-weight: 600;
  color: #333;
}

/* Optional: Styling for the parent average item */
.evaluation-view-report-card h3 {
  font-size: 1.2rem;
  color: #4e4e4e;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Paragraph Styling for Auto Failure Count and Overall Score */
.evaluation-view-report-card p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.evaluation-view-report-card p strong {
  font-weight: 600;
}

/* Add some spacing between sections */
.evaluation-view-report-card .evaluation-view-report-card-summary {
  margin-top: 20px;
}

/* Optional: Add a border around the list for better grouping */
.evaluation-view-report-card-summary {
  border-top: 2px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
}

/* Specific style for the 'Marks' header cell to align to the right */
.evaluation-view-report-evaluation-header-cell.marks {
  text-align: right;
}

/* Specific style for the 'Marks' cell to align to the right */
.evaluation-view-report-evaluation-cell.marks {
  text-align: right;
}

/* Container for the card row */
.QAD-card-row {
  display: flex; /* Using flexbox to arrange the cards in a row */
  flex-wrap: wrap; /* Allowing cards to wrap to the next line on smaller screens */
  justify-content: space-between; /* Distribute cards evenly */
  grid-gap: 20px;
  gap: 20px; /* Space between the cards */
}

/* Styling for individual cards */
.QAD-card {
  background-color: #fff; /* White background */
  padding: 20px; /* Padding inside the card */
  padding-top: 30px;
  padding-bottom:30px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
  width: calc(20% - 20px); /* Cards take up 20% of the row minus the gap space */
  text-align: center; /* Center the content inside the card */
  font-size: 16px; /* Set font size */
  font-weight: 600; /* Make the text bold */
}

.QAD-card span{
  color: rgb(76, 76, 76);
}

/* Hover effect */
.QAD-card:hover {
  transform: translateY(-10px); /* Lift the card slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .QAD-card {
    width: calc(33.33% - 20px); /* Three cards per row on medium to large screens */
  }
}

@media (max-width: 768px) {
  .QAD-card {
    width: calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media (max-width: 480px) {
  .QAD-card {
    width: 100%; /* Full width cards on small screens */
    padding: 15px; /* Less padding for mobile devices */
  }
}

/* .slimscollbar-style {
  background: rgb(204, 204, 204);
  width: 7px;
  position: absolute;
  top: 0px;
  opacity: 0.4;
  display: block;
  border-radius: 7px;
  z-index: 99;
  right: 1px;
  height: 148.547px;
}
.slimscollRail-style {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0px;
  display: none;
  border-radius: 7px;
  background: rgb(51, 51, 51);
  opacity: 0.2;
  z-index: 90;
  right: 1px;
}
.slimScrollDiv-style {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 880px;
}
.slimScroll-style {
    overflow: hidden; 
    width: 100%;
    height: 880px;
} */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

/*-----------------------------
    Employee Appreciation  
------------------------------*/

.extra_sidebar_styling {
  font-size: 15px !important;
  font-weight: 700 !important;
  font-family: var(--app-font-family);
}

.EA_styling {
  color: #ffbf00 !important;
}
.IWD_styling {
  color: #f8b9d4 !important;
}

/** @format */

/*--------------------------
	Client Login
----------------------------*/

.go-to-client {
  margin-top: 20px;
}

.go-to-client > p {
  text-align: center;
  font-family: var(--app-font-family);
  font-size: 18px;
}

.go-to-client-link {
  color: #009ce7;
  /* color: #780303; */
  cursor: pointer;
  transition: 0.2s;
}

.go-to-client-link:hover {
  color: #007bff;
  /* color: #3f0202; */
  transition: 0.2s;
}

/*--------------------------
	HR Client
----------------------------*/

.client-card-container {
  display: flex;
  justify-content: space-between;
}

.client-info-card {
  width: auto;
  border-radius: 10px;
  background-color: #fff;
  padding: 2% 5%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.client-count-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  background-color: #fff;
  padding: 2% 5%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.client-info-div {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.client-title {
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.client-list {
  font-size: 16px;
}
.client-list > li {
  margin-top: 10px;
}

.client-card-div > p {
  text-align: center;
  font-size: 50px;
}

.client-count-title {
  word-spacing: 5px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.create-account-modal {
  position: fixed;
  top: 8%;
  left: 0;
  width: 100vw;
  height: 92vh;
  z-index: 900;
}

.create-account-modal-container {
  position: absolute;
  left: 35%;
  top: 5%;
  width: 43%;
  padding: 20px;
  z-index: 1000;
  height: 90%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #00000080;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.disable-create-account {
  pointer-events: none;
}

.deactivate-modal {
  position: fixed;
  top: 8%;
  left: 0;
  width: 100vw;
  height: 92vh;
  z-index: 900;
}

.deactivate-modal-container {
  position: absolute;
  left: 35%;
  top: 25%;
  width: 43%;
  padding: 20px;
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #00000080;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.deactivate-modal-close {
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
  float: right;
}

.deactivate-modal-close:hover {
  font-size: 25px;
  cursor: pointer;
  color: #999;
  transition: 0.2s;
}

.deactivate-modal-items {
  height: 90%;
}

.deactivate-modal-items > p {
  color: #000;
  text-align: center;
  padding: 15% 0 5% 0;
  font-family: var(--app-font-family);
  font-size: 18px;
  font-weight: 600;
}

.deactivate-modal-btns {
  display: flex;
  justify-content: space-evenly;
  height: 45px;
}

.deactivate-modal-yes,
.deactivate-modal-cancel {
  height: 100%;
  width: 100px;
  border: 1px solid #999;
  border-radius: 5px;
  font-weight: 600;
  font-family: var(--app-font-family);
}

.loading-spinner {
  margin-top: -5px !important;
}

.loading-btn {
  height: 38px !important;
}

/* ---- */

.success-modal {
  position: fixed;
  top: 8%;
  left: 0;
  width: 100vw;
  height: 92vh;
  z-index: 900;
}

.success-modal-container {
  position: absolute;
  left: 35%;
  top: 10%;
  width: 43%;
  padding: 20px;
  z-index: 1000;
  height: 80%;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #00000080;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.success-modal-close {
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
  float: right;
}

.success-modal-close:hover {
  font-size: 25px;
  cursor: pointer;
  color: #999;
  transition: 0.2s;
}

.success-modal-items {
  height: 100%;
}

.success-modal-img {
  display: flex;
  justify-content: center;
}

.success-modal-img > img {
  width: 40%;
  height: 40%;
  object-fit: cover;
  border-radius: 50%;
}

.success-info {
  color: #000;
  text-align: center;
  padding: 2% 0 2% 0;
  font-weight: 600;
  font-size: 18px;
}

.success-modal-btns {
  display: flex;
  justify-content: space-evenly;
  height: 45px;
}

.success-modal-yes,
.success-modal-cancel {
  height: 100%;
  width: 100px;
  border: 1px solid #999;
  border-radius: 5px;
  font-weight: 600;
  font-family: var(--app-font-family);
}

/*------------------------------------
	HR Dashboard & Department Dashboard
--------------------------------------*/

.hr-dashboard-card-group {
  display: flex;
  justify-content: left;
  margin-bottom: 30px;
}

.hr-employee-card-group {
  display: flex;
  justify-content: left;
}

.hr-dashboard-card {
  background-color: #fff;
  min-width: 25%;
  margin-left: 20px;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.hr-dashboard-card > span {
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
}
.daily-attendance-card-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust minmax value based on card width */
  grid-gap: 20px;
  gap: 20px; /* Space between grid items */
  margin-bottom: 30px;
}

.daily-attendance-card {
  background-color: #fff;
  min-width: 20%;
  margin-left: 20px;
  border: 1px solid #ededed;
  padding: 15px 10px;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}
.daily-attendance-card:last-child {
  background-color: #fff;
  width: auto;
  min-width: 35%;
  margin-left: 20px;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body.inner {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daily-attendance-card > span {
  font-size: 16px;
  display: flex;
  justify-content: right;
  padding: 5px 10px;
}

.daily-attendance-card-info > h3 {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}
.card-info > h3 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.dept-dashboard-card-group {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.dept-dashboard-card {
  background-color: #fff;
  min-width: 30%;
  border: 1px solid #ededed;
}

.dept-dashboard-card > span {
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
}

.hr-dashboard-charts {
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.employee-gender-pie {
  min-height: 35vh;
}

@media only screen and (max-width: 765px) {
  .hr-dashboard-card-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .hr-dashboard-card {
    background-color: #fff;
    min-width: 20%;
    border: 1px solid #ededed;
    margin-bottom: 10px;
  }

  .daily-attendance-card-group {
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
  }

  .daily-attendance-card {
    background-color: #fff;
    min-width: 20%;
    margin-left: 20px;
    border: 1px solid #ededed;
    padding-bottom: 10px;
  }
  .daily-attendance-card:last-child {
    background-color: #fff;
    width: 100%;
    margin-left: 20px;
    border: 1px solid #ededed;
  }

  .daily-attendance-card > span {
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
  }

  .daily-attendance-card-info > h3 {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
}

/*--------------------------
	Job applicant pagination
----------------------------*/

.custom-search {
  display: flex;
  margin-bottom: 20px;
}

.custom-search-input {
  width: 100%;
  height: 45px;
  border: 1px solid #66708080;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  margin-bottom: 20px;
  background-color: #fff;
}

.custom-search-btn {
  height: 45px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.job-app-search {
  display: flex;
  margin-bottom: 20px;
}

.application-table-pagination {
  position: relative;
  display: flex;
  padding: 5px;
  width: 100%;
}
.application-table-pagination-stack {
  margin: auto !important;
}

.application-table-sizePerPage {
  background-color: var(--prm-color);
  width: 80px;
  border: none;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  padding: 0 15px;

  font-weight: 400;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='20' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: top 9px right 0;
}
.application-table-sizePerPage:focus {
  outline: none;
}

.application-table-sizePerPage > option {
  background-color: #fff;
  border: none;
  height: 50px;
  border-radius: 5px;
  color: #000;
  padding: 0 5px;
}

.css-wjh20t-MuiPagination-ul {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto !important;
}
.css-wjh20t-MuiPagination-ul > li > button {
  color: black;
}
.Mui-selected {
  background-color: var(--prm-color) !important;
  color: #f8f9fa !important;
  /* color: #faf8f8 !important; */
}

@media only screen and (max-width: 765px) {
  .application-table-pagination {
    padding: 5px;
    width: 100% !important;
  }
}

/*-----------------
  Leave Application
-----------------------*/

.leave-reportee-action-btns {
  display: flex;
  justify-content: space-between;
}

.leave-user-action-btns {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.leave-btn {
  margin: auto 10px;
}

.rejection-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 90%;
  width: 100%;
}

.rejection-textarea {
  width: 100%;
  height: 20vh !important;
}

.leave-filter-control {
  /* border-color: #e3e3e3; */

  width: 100%;
  height: 45px;
  border: 1px solid #66708080;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  outline: #c2c2c2;
  font-weight: 400;
  margin-bottom: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkblue' height='25' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: top 9px right 0;
}

.signature-card-body {
  width: 100%;
}

.leave-card-body {
  padding: 20px;
}

.remaining-leave-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5%;
  position: relative;
}

.remaining-leave-row-card {
  width: 17%;
}
.active-leave-row-card {
  width: 17%;
  margin-left: 20px;
}

.leave-application-progress {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 20%;
}

.leave-application-progress > p {
  text-align: center;
}
.leave-application-progress > div {
  display: flex;
}

.approver-progress-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.approver-progress {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  border: none;
  transition: all 0.5s ease-in-out;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 5px;
}

.approver-progress-name {
  margin-bottom: 0;
  margin-right: 8px;
}

.approver-progress-status {
  display: flex;
  margin-bottom: 0;
}

.approver-progress-icon,
.approver-status-icon {
  font-size: 20px;
}

.approver-progress-user-icon {
  font-size: 45px;
  margin-top: 1%;
  display: flex;
}

#leave-application-count-div {
  width: 35px;
  height: 35px;
  color: #fff;
  background-color: #999;
  padding: 5px;
  margin-left: 5px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

.tla.nav-link {
  padding-top: 0;
}

#leave-application-count {
  color: #fff;
}

.office-count {
  color: #fff;
  background-color: #999;
  padding: 5px;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 15px;
}

.attendance-record-for-office {
  color: #000;
}

.attendance-record-for-office:hover {
  color: var(--prm-color);
}

.Hr-cards {
  display: flex;
}

.hr-filter-select {
  display: flex;
  margin-bottom: 20px;
  z-index: 2;
}

.home-notification-count {
  position: absolute;
  top: -15%;
  right: -15%;
  background-color: #ff0000;
  color: #fff;
  font-weight: 400;
  height: 50%;
  width: 50%;
  border-radius: 50%;
}

.home-notification-count > p {
  text-align: center;
  padding: 1px;
  font-weight: 400 !important;
}

.home-notification-icon {
  position: absolute;
  top: 25%;
  left: 25%;
  font-size: 20px;
  color: #002564;
}

.home-notification-icon-active {
  position: absolute;
  top: 25%;
  left: 25%;
  font-size: 20px;
  color: #002564;
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(7deg);
  }
  60% {
    transform: rotate(-7deg);
  }
  75% {
    transform: rotate(5deg);
  }
  85% {
    transform: rotate(-5deg);
  }
  92% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0);
  }
}

.clipboard-notification-div {
  height: 50px;
  width: 200px;
  position: fixed;
  background-color: #fff;
  top: 200px;
  left: 50%;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.clipboard-notification-div > p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: var(--app-font-family);
}

.clipboard-notification-icon {
  font-size: 20px;
  margin-left: 10px;
  margin-top: -5px;
}

@media only screen and (min-width: 991px) {
  .home-notification-div {
    position: absolute;
    display: flex;
    right: 1.5%;
    top: 15%;
    background-color: #fff;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .home-notification-div:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
    transition: all 0.2s;
  }

  .home-notification-count {
    position: absolute;
    top: -15%;
    right: -15%;
    background-color: #ff0000;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 50%;
    width: 50%;
    border-radius: 50%;
  }

  .home-notification-count > p {
    text-align: center;
    padding: 1px;
    font-weight: 600;
  }

  .home-notification-icon {
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: 20px;
    color: #002564;
  }

  .home-notification-icon-active {
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: 20px;
    color: #002564;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(10deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    45% {
      transform: rotate(7deg);
    }
    60% {
      transform: rotate(-7deg);
    }
    75% {
      transform: rotate(5deg);
    }
    85% {
      transform: rotate(-5deg);
    }
    92% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0);
    }
  }
}

/* ----- Employee Shift ------- */

.btn-group {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.shift-off-toggle {
  border-right: 1px solid #ccc;
  margin: 0 10px;
  width: 10%;
}

.shift-item-toggle {
  margin: 0 10px;
}

/* ----- Employee Shift ------- */

@media only screen and (max-width: 991px) {
  .home-notification-div {
    position: absolute;
    display: flex;
    right: 15%;
    top: 15%;
    background-color: #fff;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .home-notification-div:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
    transition: all 0.2s;
  }

  .home-notification-count {
    position: absolute;
    top: -15%;
    right: -15%;
    background-color: #ff0000;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 50%;
    width: 50%;
    border-radius: 50%;
  }

  .home-notification-count > p {
    text-align: center;
    padding: 1px;
    font-weight: 600;
  }

  .home-notification-icon {
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: 20px;
    color: #002564;
  }

  .home-notification-icon-active {
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: 20px;
    color: #002564;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(10deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    45% {
      transform: rotate(7deg);
    }
    60% {
      transform: rotate(-7deg);
    }
    75% {
      transform: rotate(5deg);
    }
    85% {
      transform: rotate(-5deg);
    }
    92% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0);
    }
  }
}

/*-----------------
	Add Department
-----------------------*/

.horizontal-department-rule {
  height: 20px;
}

/*-----------------
	1. General
-----------------------*/

html {
  height: 100%;
}
body {
  font-family: var(--app-font-family);
  font-size: 0.9375rem;
  color: #1f1f1f;
  /* background-color: #f7f7f7; */
  background: #f2f5f9;
  min-height: 100%;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--app-font-family);
  margin-top: 0;
  font-weight: 500;
}
h1,
.h1 {
  font-size: 2.25rem;
}
h2,
.h2 {
  font-size: 1.875rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 0.875rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}
.checkbox-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 0 20px 0;
}
.checkbox-control {
  display: flex;
  justify-content: center;
}
.form-control {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 15px;
  height: 44px;
}
.form-control:focus {
  border-color: #ccc;
  box-shadow: none;
  outline: 0 none;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
input,
input:focus,
button,
button:focus {
  outline: none;
}
input[type="file"] {
  height: auto;
}
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  appearance: none;
}
textarea.form-control {
  resize: vertical;
}
.navbar-nav > li {
  float: left;
}
.form-control-lg.form-control {
  border-radius: 4px;
  height: 46px;
}
.form-control-sm.form-control {
  height: 30px;
  border-radius: 0;
}
.input-group.input-group-lg .form-control {
  height: 46px;
}
.material-icons {
  font-family: var(--app-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.input-group-text {
  background-color: #f7f7f7;
  border-color: #e3e3e3;
}
select.form-control[multiple] option,
select.form-control[size] option {
  border-radius: 3px;
  padding: 7px 12px;
}
select.form-control[multiple],
select.form-control[size] {
  height: 200px;
  padding: 7px;
}

/*-----------------
	2. Table
-----------------------*/

.earnings-table-header {
  font-weight: 600;
  margin-left: 20px;
  font-size: 18px;
  text-align: center;
  border-top: 2px solid #999;
  border-bottom: 2px solid #999;
}

.table-responsive {
  white-space: nowrap;
  width: 100%;
}
.table {
  color: #333;
}
.table.table-white {
  background-color: #fff;
}
.table thead th {
  font-weight: 500;
  letter-spacing: 0.05em;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}

table.table td h2.table-avatar.blue-link a {
  color: #007bff;
  /* color: #780303; */
}
table.table td h2 a {
  color: #333;
}
table.table td h2 a:hover {
  color: var(--prm-color);
}
table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
table.dataTable {
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-hover tbody tr:hover {
  background-color: #f7f7f7;
  color: #212529;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px !important;
}
.table.custom-table > tbody > tr > td,
.table.custom-table > tbody > tr > th,
.table.custom-table > tfoot > tr > td,
.table.custom-table > tfoot > tr > th,
.table.custom-table > thead > tr > td,
.table.custom-table > thead > tr > th {
  padding: 10px 20px !important;
}
/*-----------------
	3. Helper Class
-----------------------*/

.p-20 {
  padding: 20px !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.w-40 {
  width: 40px;
}
.btn-md {
  height: 40px;
}
.block {
  display: block !important;
}
.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-muted-light {
  color: #aaa;
}
.text-xs {
  font-size: 0.75rem !important;
}
.text-sm {
  font-size: 0.875rem !important;
}
.text-lg {
  font-size: 1.25rem !important;
}
.text-xl {
  font-size: 1.5rem !important;
}
.font-18 {
  font-size: 18px;
}
.btn.btn-rounded {
  border-radius: 50px;
}
.rounded {
  border-radius: 100px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn {
  border-radius: 5px;
}
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}
.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.btn-white:focus,
.btn-white:hover {
  background-color: #f7f7f7;
}
.rounded,
.circle {
  border-radius: 500px;
}
.bg-purple,
.badge-purple {
  background-color: #7460ee !important;
}
.bg-primary,
.badge-primary {
  background-color: var(--prm-color) !important;
}
.bg-success,
.badge-success {
  background-color: #55ce63 !important;
}
.bg-info,
.badge-info {
  background-color: #009efb !important;
  /* background-color: #780303 !important; */
}
.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}
.bg-danger,
.badge-danger {
  background-color: #f62d51 !important;
}
.bg-white {
  background-color: #fff;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
  color: var(--prm-color) !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
  color: #55ce63 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #f62d51 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
  color: #009efb !important;
  /* color:#780303 !important; */
}
.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}
.text-muted {
  color: #8e8e8e !important;
}
.btn-purple {
  background-color: #7460ee;
  border: 1px solid #7460ee;
  color: #fff;
}
.btn-purple:hover,
.btn-purple:focus {
  background-color: #482fe9;
  border: 1px solid #482fe9;
}
.btn-primary {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #00b7ed;
  border: 1px solid #00b7ed;
  /* background-color: #780303;
  border: 1px solid #780303; */
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #00b7ed;
  border: 1px solid #00b7ed;
  /* background-color: #780303;
  border: 1px solid#780303; */
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #780303;
  border-color: #780303;
  color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--prm-color);
  border-color: var(--prm-color);
  color: #fff;
}
.btn-success {
  background-color: #55ce63;
  border: 1px solid #55ce63;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background: #4ab657;
  border: 1px solid #4ab657;
  color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background: #4ab657;
  border: 1px solid #4ab657;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #55ce63;
  border-color: #55ce63;
  color: #fff;
}
.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
  /* background-color: #780303;
  border: 1px solid #780303; */
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
  /* background-color: #780303;
  border: 1px solid #780303; */
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
  /* background-color: #780303;
  border: 1px solid #780303; */
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}
.btn-warning {
  background: #ffbc34;
  border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}
.btn-danger {
  background: #f62d51;
  border: 1px solid #f62d51;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #e6294b;
  border: 1px solid #e6294b;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}
.btn-custom {
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  color: #fff;
}
.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: var(--prm-color);
  border-color: var(--prm-color);
}
.pagination > li > a,
.pagination > li > span {
  color: var(--prm-color);
}
.page-link:hover {
  color: var(--prm-color);
}
.page-link:focus {
  box-shadow: unset;
}
.page-item.active .page-link {
  background-color: var(--prm-color);
  border-color: var(--prm-color);
}
.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}
.header-dropdown-menu {
  width: 270px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}
.navbar-nav .open .dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.dropdown-menu {
  font-size: 13px;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  box-shadow: none;
}
.btn-link {
  color: #333;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.form-text {
  color: #999;
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 7px;
}
.btn.focus,
.btn:focus {
  box-shadow: unset;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--prm-color);
}
.list-group-item {
  border: 1px solid #e3e3e3;
}
.custom-file {
  height: 44px;
}
.custom-file .custom-file-label {
  border: 1px solid #e3e3e3;
  height: 44px;
  margin-bottom: 0;
  padding: 10px 15px;
}
.custom-file-label::after {
  background-color: #f2f2f2;
  height: 42px;
  padding: 10px 15px;
}
.custom-file input[type="file"] {
  height: 44px;
}
.bg-inverse-primary {
  background: rgba(77, 138, 240, 0.2) !important;
  color: #4d8af0 !important;
}
.bg-inverse-secondary {
  background: rgba(194, 198, 209, 0.2) !important;
  color: #c2c6d1 !important;
}
.bg-inverse-success {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}
.bg-inverse-purple {
  background: rgba(108, 97, 246, 0.2) !important;
  color: #6c61f6 !important;
}
.bg-inverse-warning {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}
.bg-inverse-danger {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}
.bg-inverse-light {
  background: rgba(223, 223, 223, 0.2) !important;
  color: #dfdfdf !important;
}
.bg-inverse-dark {
  background: rgba(116, 118, 123, 0.2) !important;
  color: #74767b !important;
}
.bg-inverse-white {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
}
.bg-inverse-info {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}
.bg-inverse-default {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}
.bg-gradient-primary {
  background: linear-gradient(to right, #6e00ff 10%, #6e00ff 100%);
}
.bg-gradient-secondary {
  background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}
.bg-gradient-success {
  background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}
.bg-gradient-info {
  background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}
.bg-gradient-warning {
  background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%);
}
.bg-gradient-teal {
  background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}
.bg-gradient-blue {
  background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}
.bg-gradient-danger {
  background: linear-gradient(to right, #f95058 0, #f33540 100%);
}
.bg-gradient-purple {
  background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}

/*-----------------
	5. Animation
-----------------------*/
@keyframes taskHighlighter {
  0% {
    background: #feeb99;
  }
  100% {
    background: #fff;
  }
}

/*-----------------
	6. Toggle Button
-----------------------*/

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: #e0001a;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
  background-color: #55ce63;
}
.check:checked + .checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0;
}
.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}
.onoffswitch-inner:before {
  background-color: #55ce63;
  color: #fff;
  content: "ON";
  padding-left: 14px;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.onoffswitch-switch {
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  display: block;
  height: 20px;
  margin: 5px;
  position: absolute;
  right: 43px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.switch {
  cursor: pointer;
  position: relative;
}
.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.switch input:checked + span {
  background-color: #55ce63;
}
.switch input:checked + span:after {
  left: 31px;
}
.switch span {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  transition: background-color 0.2s;
}
.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 26px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  transition: left 0.2s;
}
.rating-list {
  display: flex;
  justify-content: center;
}
.rating-list > span {
  display: inline-block;
  margin-right: 4px;
}
.rating-list span a {
  background-color: #ccc;
  border-radius: 4px;
  color: #333;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rating-list span.rating-bad a {
  background-color: #ad0d20;
  color: #fff;
}
.rating-list span.rating-normal a {
  background-color: #dccc00;
  color: #fff;
}
.rating-list span.rating-good a {
  background-color: #00a100;
  color: #fff;
}
.rating-text {
  font-size: 12px;
  max-width: 157px;
  display: inline-block;
  margin-top: 5px;
}
.rating-list > span.rating-normal {
  text-align: center;
}
.rating-list > span.rating-good {
  text-align: right;
}
.custom_check {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #ff9b44;
  border: 1px solid transparent;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_radio {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
}
.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff9b44;
  transition: all 0.3s ease-in-out;
}
.setting-performance-table.table > tbody > tr > td,
.setting-performance-table.table > tbody > tr > th,
.setting-performance-table.table > tfoot > tr > td,
.setting-performance-table.table > tfoot > tr > th,
.setting-performance-table.table > thead > tr > td,
.setting-performance-table.table > thead > tr > th {
  vertical-align: middle;
}

.setting-performance-table textarea.form-control {
  resize: none;
  height: 44px !important;
  padding-top: 10px !important;
}
/*-----------------
	7. Modal
-----------------------*/

.modal {
  -webkit-overflow-scrolling: touch;
}
.confirm_modal {
  top: 30%;
}
.modal-footer.text-center {
  justify-content: center;
}
.modal-footer.text-left {
  justify-content: flex-start;
}
.modal-dialog.modal-md {
  max-width: 600px;
}
.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}
.custom-modal .modal-footer {
  border: 0;
}
.custom-modal .modal-header {
  border: 0;
  justify-content: center;
  padding: 30px 30px 0;
}
.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px;
}
.custom-modal .modal-body {
  padding: 30px;
}
.custom-modal .close {
  background-color: #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}
.custom-modal .modal-title {
  font-size: 22px;
}
.custom-modal-backdrop{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}
.modal-backdrop.show {
  opacity: 0.4;
  transition-duration: 400ms;
}
.modal .card {
  box-shadow: unset;
}

/*-----------------
	8. Header
-----------------------*/

.header {
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1002;
}
.header-left {
  background-color: var(--sidebar-bg-color);
  position: relative
}
.header .header-left {
  float: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 230px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.header .header-left .cropped-logo {
  display: none;
  line-height: 60px;
}
.bar-icon {
  display: inline-block;
  width: 21px;
}
.bar-icon span {
  background-color: #fff;
  border-radius: 500px;
  display: block;
  float: left;
  height: 2px;
  margin-bottom: 5px;
  width: 21px;
}
.bar-icon span:nth-child(2) {
  width: 15px;
}
.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}
.header .navbar-nav .badge {
  position: absolute;
  right: 7px;
  top: 4px;
}
.header .dropdown-menu > li > a {
  position: relative;
}
.header .dropdown-toggle::after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
}
.page-title-box {
  border-radius: 0;
  float: left;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 55px;
}
.page-title-box h3 {
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}
.user-menu {
  float: right;
  margin-right: 5%;
  position: relative;
  z-index: 99;
}
.user-menu.nav > li > a {
  color: #fff;
  font-size: 15px;
  line-height: 60px;
  padding: 0 20px 0 35px;
  height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.flag-nav img {
  margin-right: 5px;
}
.flag-nav .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}
.flag-nav .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}
.user-img {
  display: inline-block;
  position: relative;
}
.main-drop .user-img img {
  border-radius: 50%;
  width: 30px;
}
.user-img .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  margin: 0;
  position: absolute;
  right: 0;
  width: 10px;
}
.user-menu .user-img .status {
  bottom: 12px;
}
.status {
  background-color: #263238;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.status.online {
  background-color: #55ce63;
}
.status.offline {
  background-color: #f62d51;
}
.status.away {
  background-color: #faa937;
}
.main-drop .dropdown-menu {
  min-width: 100px;
}

/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {
  /* background-color: #34444c; */
  background-color: var(--sidebar-bg-color);
  border-right: 1px solid transparent;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 60px;
  transition: all 0.2s ease-in-out 0s;
  width: 230px;
  z-index: 1001;
}
.sidebar.opened {
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  padding: 10px 0;
  background-color: var(--sidebar-bg-color);
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.sidebar-menu li a {
  color: #b7c0cd;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu li a:hover {
  color: #fff;
}
.sidebar-menu li.active a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
.menu-title {
  color: #ebecf1;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.sidebar-menu li.menu-title a {
  color: var(--prm-color);
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  color: var(--prm-color);
  text-decoration: underline;
}
.mobile-user-menu {
  color: #fff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10;
}
.mobile-user-menu > a {
  color: #fff;
  padding: 0;
}
.mobile-user-menu a:hover {
  color: #fff;
}
.mobile-user-menu > .dropdown-menu {
  min-width: 130px;
}
.mobile-user-menu > .dropdown-menu > a {
  line-height: 23px;
}
.profile-rightbar {
  display: none !important;
  color: #bbb;
  font-size: 26px;
  margin-left: 15px;
}
.mobile_btn {
  display: none;
  float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  padding: 9px 10px 9px 50px;
  position: relative;
}
.sidebar-menu ul ul {
  display: none;
}
.sidebar-menu ul ul ul a {
  padding-left: 70px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 90px;
}
.sidebar-menu > ul > li {
  position: relative;
}
.sidebar-menu .menu-arrow {
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: var(--app-font-family);
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
  transform: rotate(90deg);
}

.custom-menu-arrow {
  position: absolute;
  right: 15px;
  top: 8px;
}

.custom-menu-arrow > img {
  width: 15px;
}

.custom-menu-arrow.lg {
  position: absolute;
  right: 10px;
  top: -6px;
}

.noti-dot:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 5px solid var(--prm-color);
  border-radius: 30px;
  background-color: var(--prm-color);
  z-index: 10;
  position: absolute;
  right: 37px;
  top: 15px;
}
.noti-dot:after {
  content: "";
  border: 4px solid var(--prm-color);
  background: transparent;
  border-radius: 60px;
  height: 24px;
  width: 24px;
  animation: pulse 3s ease-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 8px;
  right: 30px;
  z-index: 1;
  opacity: 0;
}
.sidebar-menu ul ul a .menu-arrow {
  top: 6px;
}
.sidebar-menu a {
  line-height: 1.3;
  transition: unset;
  -moz-transition: unset;
  -o-transition: unset;
  -ms-transition: unset;
  -webkit-transition: unset;
}
.sidebar-menu > ul > li > a {
  padding: 8px 15px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}

/*-----------------
	10. Content
-----------------------*/

.page-wrapper {
  left: 0;
  margin-left: 230px;
  padding-top: 60px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.page-wrapper > .content {
  padding: 30px;
}
.page-header {
  margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.page-header .breadcrumb a {
  color: #333;
}
.card {
  border: 1px solid #ededed;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 30px;
}
.card-title {
  color: #1f1f1f;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.card-header {
  background-color: #fff;
}
.card-footer {
  background-color: #fff;
}
.page-title {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
}
.page-sub-title {
  color: #565656;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.add-btn {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  border-radius: 5px;
  color: #fff;
  float: right;
  font-weight: 500;
  min-width: 140px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.add-btn:hover,
.add-btn:focus,
.add-btn:active {
  background-color: #00b7ed;
  border: 1px solid #00b7ed;
  /* background-color: #780303;
  border: 1px solid #780303; */
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.5) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.add-btn i {
  margin-right: 5px;
}

.add-academy-btn {
  position: absolute;
  top: -60px;
  right: 0;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  border-radius: 50px;
  color: #fff;
  float: right;
  font-weight: 500;
  min-width: 140px;
}
.add-academy-btn:hover,
.add-academy-btn:focus,
.add-academy-btn:active {
  background-color: #00b7ed;
  border: 1px solid #00b7ed;
  /* background-color: #780303;
  border: 1px solid #780303; */
  color: #fff;
}
.add-academy-btn i {
  margin-right: 5px;
}

.academy-pieChart {
  margin-left: 10%;
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: var(--app-font-family);
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}
.form-focus .cal-icon:after {
  top: 15px;
}

/*-----------------
	11. Login
-----------------------*/

.account-page {
  align-items: center;
  display: flex;
}
.account-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.account-content {
  padding: 20px 0;
}
.account-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.account-subtitle {
  color: #4c4c4c;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

.account-wrapper {
  padding: 30px;
}

.form-group > label > span {
  color: red;
}

.account-footer {
  text-align: center;
}
.account-footer p {
  margin-bottom: 0;
}
.account-footer a {
  color: var(--prm-color);
}
.account-footer a:hover {
  color: var(--prm-color);
}

/*-----------------
	12. Dashboard
-----------------------*/

.card-table .card-header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 15px;
}
.card-table .card-body {
  padding: 0;
}
.card-body-application {
  cursor: pointer;
}
.card-body-application:hover {
  border: 1px solid var(--prm-color);
  font-weight: 600;
  box-shadow: 0px 0px 10px var(--prm-color);
  transition: 0.2s;
}
.card-table .card-footer {
  background-color: #fff;
  text-align: center;
}
.card-table .card-footer a {
  color: #333;
}
.card-title > a.btn {
  color: #fff;
}
.custom-table tr {
  background-color: #fff;
  box-shadow: 0 0 3px #e5e5e5;
}
.table.custom-table > tbody > tr > td,
.table.custom-table > tbody > tr > th,
.table.custom-table > tfoot > tr > td,
.table.custom-table > tfoot > tr > th,
.table.custom-table > thead > tr > td,
.table.custom-table > thead > tr > th {
  padding: 10px 8px;
  vertical-align: middle;
}
.table.custom-table > tbody > tr > td:first-child,
.table.custom-table > thead > tr > th:first-child {
  padding-left: 15px;
}
.table.custom-table > tbody > tr > td:last-child,
.table.custom-table > thead > tr > th:last-child {
  padding-right: 15px;
}

.biometrics-widget-icon {
  border-radius: 100%;
  float: left;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
}
.dash-widget-icon {
  background-color: rgba(0, 197, 251, 0.2);
  /* background-color: rgba(251, 0, 0, 0.2); */
  border-radius: 100%;
  color: var(--prm-color);
  float: left;
  font-size: 25px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
}
.dash-widget-info {
  text-align: right;
}
.dash-widget-info > h3 {
  font-size: 30px;
  font-weight: 600;
}
.dash-widget-info > span {
  font-size: 16px;
}
.dash-statistics .stats-info {
  margin-bottom: 5px;
}
.dash-statistics .stats-info:last-child {
  margin-bottom: 0;
}
.dash-statistics .progress {
  height: 4px;
}
.dash-statistics .stats-list {
  height: auto;
}
.leave-info-box {
  border: 1px solid #e5e5e5;
  padding: 15px;
  margin-bottom: 15px;
}
.leave-info-box:last-child {
  margin-bottom: 0;
}
.load-more a {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: inline-block;
  font-size: 14px;
  padding: 5px 15px;
}

/*-----------------
	13. Activity
-----------------------*/

.activity-box {
  position: relative;
}
.activity-list {
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: relative;
}
.activity .activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.activity .activity-list > li .activity-user {
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 32px;
}
.activity .activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 0 40px;
  padding: 0;
  position: relative;
}
.activity-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 23px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.activity .activity-list li::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 22px;
  position: absolute;
  top: 12px;
  width: 2px;
}
.activity-list li::before {
  background: #eee;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.activity-list > li {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.activity-list > li:last-child .activity-content {
  margin-bottom: 0;
}
.activity-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}
.activity-list > li .activity-user {
  background: #fff;
  height: 32px;
  left: -7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3px;
  width: 32px;
}
.activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}
.activity-list > li .activity-content .timeline-content {
  color: #9e9e9e;
}
.activity-list > li .activity-content .timeline-content a {
  color: #616161;
}
.activity-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 13px;
}

/*-----------------
	14. Select2
-----------------------*/

.select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 44px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 42px;
  right: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 15px;
  font-weight: normal;
  line-height: 42px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: var(--prm-color);
}

/*-----------------
	15. Nav Tabs
-----------------------*/

.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: var(--prm-color);
  border-color: var(--prm-color);
  color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.tab-content {
  padding-top: 20px;
}

/*-----------------
	16. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
  color: #aaa;
}
.dropdown.action-label {
  display: inline-block;
}
.action-label > a {
  display: inline-block;
  min-width: 103px;
}
.action-label .btn-sm {
  cursor: pointer;
  padding: 4px;
}

/*-----------------
	17. Delete Modal
-----------------------*/

.form-header {
  text-align: center;
  margin-bottom: 30px;
}
.form-header h3 {
  color: #333;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.form-header p {
  color: #929292;
  font-size: 15px;
  margin-bottom: 0;
}
.modal-btn > .row {
  margin-left: -8px;
  margin-right: -8px;
}
.modal-btn > .row > div {
  padding-left: 8px;
  padding-right: 8px;
}
.continue-btn {
  background-color: #fff;
  border: 1px solid var(--prm-color);
  border-radius: 50px;
  color: var(--prm-color);
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}
.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  color: #fff;
}
.cancel-btn {
  background-color: #fff;
  border: 1px solid var(--prm-color);
  border-radius: 50px;
  color: var(--prm-color);
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}
.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  color: #fff;
}

/*-----------------
	18. Edit Profile
-----------------------*/

.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}
.profile-basic {
  margin-left: 140px;
}
.profile-basic .cal-icon {
  width: 100%;
}
.profile-img-wrap img {
  border-radius: 10px;
  height: 120px;
  width: 120px;
}

.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}
.btn-text {
  color: #fff;
}
.submit-section {
  text-align: center;
  margin-top: 40px;
}
.submit-btn {
  font-weight: 500;
  transition: 0.3s;
}
.submit-btn:hover {
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: scale(1.05);
}
.form-scroll {
  max-height: 400px;
  overflow-y: auto;
}
.profile-img-wrap.edit-img {
  border-radius: 50%;
  margin: 0 auto 30px;
  position: relative;
}
.profile-img-wrap.edit-img .fileupload.btn {
  left: 0;
}

/*-----------------
	19. Chat
-----------------------*/

.chat-avatar-sm {
  display: inline-block;
  float: left;
  margin-left: 0 !important;
  margin-right: 10px;
  position: relative;
  width: 24px;
}
.chat-avatar-sm img {
  width: 24px;
}
.chat-avatar-sm.user-img .status {
  bottom: 0;
  right: -3px;
}
.chat-main-row {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  right: 0;
  top: 0;
}
.chat-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}
.message-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: 75%;
}
.chat-window {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  background-color: #f7f7f7;
}
.fixed-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}
.fixed-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
  padding: 0;
}
.fixed-header .user-info a {
  color: #555;
  text-transform: uppercase;
}
.typing-text {
  color: var(--prm-color);
  font-size: 12px;
  text-transform: lowercase;
}
.last-seen {
  color: #888;
  display: block;
  font-size: 12px;
}
.custom-menu {
  margin-top: 6px;
}
.fixed-header .custom-menu {
  margin: 0 0 1px;
}
.custom-menu.nav > li > a {
  color: #bbb;
  font-size: 26px;
  line-height: 32px;
  margin-left: 15px;
  padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
  background-color: transparent;
}
.custom-menu .dropdown-menu {
  left: auto;
  right: 0;
}
.search-box {
  margin-top: 5px;
}
.search-box .input-group {
  background-color: #f7f7f7;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
}
.search-box .input-group .form-control {
  background: transparent;
  border: none;
}
.search-box .btn {
  background-color: transparent;
}
.search-input {
  border-radius: 5px;
}
.chat-contents {
  display: table-row;
  height: 100%;
}
.chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}
.chat-wrap-inner {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.chats {
  padding: 30px 15px;
}
.chat-body {
  display: block;
  margin: 10px 0 0;
  overflow: hidden;
}
.chat-body:first-child {
  margin-top: 0;
}
.chat-right .chat-content {
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-radius: 20px 2px 2px 20px;
  clear: both;
  color: #727272;
  display: block;
  float: right;
  margin: 0;
  max-width: 60%;
  padding: 8px 15px;
  position: relative;
}
.chat-right .chat-body {
  padding-left: 48px;
  padding-right: 10px;
}
.chat-bubble {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.chat-bubble:hover .chat-action-btns {
  display: block;
  float: left;
}
.chat-right .chat-bubble:hover .chat-action-btns {
  float: right;
  display: block;
}
.chat.chat-right .chat-bubble:last-child .chat-content {
  border-bottom-right-radius: 20px;
}
.chat.chat-right .chat-bubble:first-child .chat-content {
  border-top-right-radius: 20px;
}
.chat-content > p {
  margin-bottom: 0;
}
.chat-time {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-size: 12px;
}
.chat-right .chat-time {
  text-align: right;
}
.chat-bubble .chat-action-btns {
  display: none;
}
.chat-action-btns {
  float: right;
}
.chat-action-btns ul {
  list-style: none;
  padding: 0;
  margin: 15px 15px 0 15px;
}
.chat-action-btns ul > li {
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
}
.chat-right .chat-action-btns {
  float: left;
}
.chat-bubble .chat-action-btns a {
  color: #8c8c8c;
}
.chat-line {
  border-bottom: 1px solid #eaeaea;
  height: 12px;
  margin: 7px 0 20px;
  position: relative;
  text-align: center;
}
.chat-date {
  background-color: #f7f7f7;
  color: #727272;
  font-size: 12px;
  padding: 0 11px;
}
.chat-avatar {
  float: right;
}
.chat-avatar .avatar {
  line-height: 30px;
  height: 30px;
  width: 30px;
}
.chat-left .chat-avatar {
  float: left;
}
.chat-left .chat-body {
  margin-right: 0;
  margin-left: 30px;
  padding-right: 20px;
}
.chat-left .chat-content {
  background-color: #fff;
  border: 1px solid #eaeaea;
  color: #888;
  float: left;
  position: relative;
  padding: 8px 15px;
  border-radius: 2px 20px 20px 2px;
  max-width: 60%;
}
.avatar {
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}
.avatar:hover {
  color: #fff;
}
.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}
.avatar > img {
  border-radius: 50%;
  display: block;
  overflow: hidden;
  width: 100%;
}
.chat.chat-left .chat-bubble:first-child .chat-content {
  border-top-left-radius: 20px;
  margin-top: 0;
}
.chat.chat-left .chat-bubble:last-child .chat-content {
  border-bottom-left-radius: 20px;
}
.chat-left .chat-time {
  color: #a9a9a9;
}
.attach-list {
  color: #a3a3a3;
  padding: 5px 0 0;
  line-height: 24px;
  margin: 0;
  list-style: none;
}
.attach-list i {
  margin-right: 3px;
  font-size: 16px;
}
.chat-footer {
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  padding: 15px;
}
.message-bar {
  display: table;
  height: 44px;
  position: relative;
  width: 100%;
}
.message-bar .message-inner {
  display: table-row;
  height: 100%;
  padding: 0 8px;
  width: 100%;
}
.message-bar .link {
  color: #777;
  display: table-cell;
  font-size: 20px;
  padding: 0 10px;
  position: relative;
  vertical-align: middle;
  width: 30px;
}
.message-bar .message-area {
  display: table-cell;
}
.message-area .input-group .form-control {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: none;
  color: #555;
  display: block;
  font-size: 14px;
  height: 44px;
  margin: 0;
  padding: 6px 12px;
  resize: none;
}
.message-area .btn {
  height: 44px;
  width: 50px;
}
.profile-right {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
}
.display-table {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.table-row {
  display: table-row;
  height: 100%;
}
.table-body {
  position: relative;
  height: 100%;
  width: 100%;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.table-content {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.chat-profile-img {
  padding: 30px;
  position: relative;
  text-align: center;
}
.edit-profile-img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  width: 120px;
  cursor: pointer;
}
.edit-profile-img img {
  border-radius: 50%;
  height: auto;
  margin: 0;
  width: 120px;
}
.change-img {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: #fff;
  display: none;
  height: 100%;
  left: 0;
  line-height: 120px;
  position: absolute;
  top: 0;
  width: 100%;
}
.edit-profile-img:hover .change-img {
  display: block;
}
.edit-profile-img img {
  height: auto;
  margin: 0;
  width: 120px;
  border-radius: 50%;
}
.user-name {
  color: #333;
}
.edit-btn {
  border-radius: 40px;
  height: 36px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
}
.chat-profile-info {
  padding: 15px;
}
.user-det-list {
  list-style: none;
  padding: 0;
}
.user-det-list > li {
  padding: 6px 15px;
}
.user-det-list .text-muted {
  color: #888;
}
.transfer-files .tab-content {
  padding-top: 0;
}
.files-list {
  list-style: none;
  padding-left: 0;
}
.files-list > li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  margin: 2px 0 0;
  padding: 10px;
}
.files-cont {
  position: relative;
}
.file-type {
  height: 48px;
  position: absolute;
  width: 48px;
}
.files-icon {
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.files-icon i {
  color: #76838f;
  font-size: 20px;
}
.files-info {
  padding: 0 30px 0 50px;
}

.file-name a {
  color: #333;
}
.files-action {
  display: none;
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}
.files-list > li:hover .files-action {
  display: block;
}
.file-date {
  color: #888;
  font-size: 12px;
}
.file-author a {
  color: var(--prm-color);
  font-size: 12px;
  text-decoration: underline;
}
.files-action .dropdown-menu {
  left: auto;
  right: 0;
}
.files-action > li > a {
  color: #777;
}
.chat-img-attach {
  border-radius: 4px;
  margin-bottom: 12px;
  margin-left: 12px;
  position: relative;
  float: right;
}
.chat-img-attach img {
  border-radius: 4px;
  display: inline-block;
  height: auto;
  max-width: 100%;
}
.chat-placeholder {
  background: rgba(69, 81, 97, 0.6);
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 13px;
  position: absolute;
  top: 0;
  width: 100%;
}
.chat-img-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.chat-file-desc {
  font-size: 11px;
}
.chat-right .chat-content.img-content {
  background-color: transparent;
  border: 0;
  color: #76838f;
  padding: 0;
  text-align: right;
}
.chat-right .img-content .chat-time {
  color: #a3a3a3;
}
.chat-left .chat-img-attach {
  float: left;
}
.chat-left .chat-img-attach {
  float: left;
  margin-left: 0;
  margin-right: 12px;
}
.input-group .form-control-lg.form-control {
  border-radius: 4px 0 0 4px;
  height: 46px;
}
.chat-user-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.chat-user-list .media {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
  background-color: #fff;
}
.chat-user-list .media:hover {
  background-color: #f7f7f7;
}
.designation {
  color: #9e9e9e;
  font-size: 12px;
}
.online-date {
  color: #9e9e9e;
  font-size: 12px;
}
.drop-zone {
  width: 100%;
  height: 205px;
  border: 2px dashed #adb7be;
  text-align: center;
  padding: 25px 0 0;
  margin-bottom: 20px;
}
.drop-zone .drop-zone-caption {
  font-weight: 600;
}
.upload-list {
  padding: 0;
  list-style: none;
  margin: 0;
}
.upload-list .file-list {
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  padding: 10px 0;
}
.upload-list .file-list:first-child {
  border-top: none;
}
.upload-list .upload-wrap {
  position: relative;
  padding: 0 20px 0 0;
  margin: 0 0 5px;
}
.upload-list .file-name,
.upload-list .file-size {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.upload-list .file-name {
  padding-right: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.upload-list .file-size {
  color: #888;
}
.upload-list .file-close {
  border: none;
  background: none;
  color: #ccc;
  position: absolute;
  right: 0;
  top: 2px;
}
.upload-list .file-close:hover {
  color: #f62d51;
}
.upload-list .upload-process {
  font-size: 10px;
  color: #888;
}
.upload-list .progress {
  margin-bottom: 5px;
}
.upload-list .file-name i {
  color: #888;
  margin: 0 5px 0 0;
  vertical-align: middle;
}
.upload-drop-zone {
  background-color: #fff;
  border: 2px dashed #e3e3e3;
  color: #ccc;
  height: 200px;
  line-height: 200px;
  margin-bottom: 20px;
  text-align: center;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}
.upload-text {
  font-size: 24px;
  margin-left: 10px;
}
.files-share-list {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
}

/*-----------------
	20. Focus Label
-----------------------*/

.form-focus {
  height: 50px;
  position: relative;
}
.form-focus .focus-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #888;
  margin-bottom: 0;
}
.form-focus.focused .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}
.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 240ms;
  transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
  -webkit-transition: none;
  transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}
.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}
.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

/*-----------------
	21. Leave
-----------------------*/

.filter-row .btn {
  min-height: 50px;
  padding: 12px;
  text-transform: uppercase;
}
.action-label .label {
  display: inline-block;
  min-width: 85px;
  padding: 0.5em 0.6em;
}
.action-label i {
  margin-right: 3px;
}
.action-label .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}

/*-----------------
	22. Employee
-----------------------*/

.action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}
.table .dropdown-menu {
  font-size: 13px;
  min-width: 120px;
  padding: 0;
}
.table .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}
.dropdown-action .dropdown-toggle::after {
  display: none;
}
.profile-widget {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.profile-widget .user-name > a {
  color: #333;
}
.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}
.profile-action .dropdown-toggle::after {
  display: none;
}
.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}
.profile-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}
.mobile-no > a {
  color: #777;
  display: inline-block;
}
.staff-mail > a {
  color: #777;
  display: inline-block;
  margin-top: 5px;
}
.staff-action-btns {
  margin-top: 10px;
}
.staff-id {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.view-icons {
  float: right;
  margin-right: 10px;
}
.view-icons .btn {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  color: #888;
  font-size: 18px;
  margin-right: 5px;
  min-width: 40px;
  padding: 4px;
}
.view-icons .btn.active {
  color: #333;
}

/*-----------------
	23. Events
-----------------------*/

.calendar {
  float: left;
  margin-bottom: 0;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--app-font-family);
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
  font-family: var(--app-font-family);
}
.fc-day {
  background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}
.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}
.fc-text-arrow {
  font-family: var(--app-font-family);
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: var(--prm-color) !important;
  color: #fff !important;
  text-shadow: none !important;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #fff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.external-event.bg-primary {
  background-color: rgba(127, 193, 252, 0.3) !important;
  color: #7fc1fc;
  /* color: #fc7f7f; */
}
.external-event.bg-success {
  background-color: rgba(75, 211, 150, 0.3) !important;
  color: #4bd396;
}
.external-event.bg-info {
  background-color: rgba(58, 201, 214, 0.3) !important;
  color: #3ac9d6;
}
.external-event.bg-warning {
  background-color: rgba(249, 200, 81, 0.3) !important;
  color: #f9c851;
}
.external-event.bg-danger {
  background-color: rgba(245, 112, 122, 0.3) !important;
  color: #f5707a;
}
.external-event.bg-pink {
  background-color: rgba(240, 98, 146, 0.3) !important;
  color: #f06292;
}
.external-event.bg-purple {
  background-color: rgba(107, 95, 181, 0.3) !important;
  color: #6b5fb5;
}
.external-event.bg-inverse {
  background-color: rgba(59, 62, 71, 0.3) !important;
  color: #3b3e47;
}
.external-event.bg-orange {
  background-color: rgba(255, 152, 0, 0.3) !important;
  color: #ff9800;
}
.external-event.bg-brown {
  background-color: rgba(141, 110, 99, 0.3) !important;
  color: #8d6e63;
}
.external-event.bg-teal {
  background-color: rgba(38, 166, 154, 0.3) !important;
  color: #26a69a;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: var(--app-font-family);
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: var(--app-font-family);
}
.event-form .input-group .form-control {
  height: 40px;
}

/*-----------------
	24. Profile
-----------------------*/

.profile-details {
  text-align: center;
}
.personal-info li .title {
  color: #4f4f4f;
  float: left;
  font-weight: 500;
  margin-right: 20px;
  width: 30%;
}
.personal-info li .text {
  color: #8e8e8e;
  display: flex;
  overflow: hidden;
}
.personal-info li {
  margin-bottom: 10px;
}
.personal-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.profile-view {
  position: relative;
}
.profile-view .profile-img-wrap {
  height: 120px;
  width: 120px;
}
.profile-view .profile-img {
  width: 120px;
  height: 120px;
}
.profile-view .profile-img .avatar {
  font-size: 24px;
  height: 120px;
  line-height: 120px;
  margin: 0;
  width: 120px;
}
.profile-view .profile-basic {
  margin-left: 140px;
  padding-right: 50px;
}
.profile-view .pro-edit {
  position: absolute;
  right: 0;
  top: 0;
}
.report-to-link {
  margin-right: 20px;
}
.edit-icon {
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #bbb;
  float: right;
  font-size: 12px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
}
.edit-icon:hover {
  background-color: var(--prm-color);
  border-color: var(--prm-color);
  color: #fff;
}
.delete-icon {
  color: #e30b0b;
  float: right;
  font-size: 18px;
}
.delete-icon:hover {
  color: #e30b0b;
}
.staff-msg {
  margin-top: 30px;
}
.experience-box {
  position: relative;
}
.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.experience-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.experience-list > li {
  position: relative;
}
.experience-list > li:last-child .experience-content {
  margin-bottom: 0;
}
.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}
.experience-list > li .experience-user {
  background: #fff;
  height: 10px;
  left: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}
.experience-list > li .experience-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}
.experience-list > li .experience-content .timeline-content {
  color: #9e9e9e;
}
.experience-list > li .experience-content .timeline-content a.name {
  color: #616161;
  font-weight: bold;
}
.experience-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}
.before-circle {
  background-color: #ddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.skills > span {
  border: 1px solid #ccc;
  border-radius: 500px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 6px 12px;
  text-align: center;
}
.profile-info-left {
  border-right: 2px dashed #ccc;
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  box-shadow: unset;
  display: block;
  min-height: 44px;
  padding: 6px 6px 0;
}
.bootstrap-tagsinput .badge {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 6px;
  padding: 10px 15px;
}
.add-more a {
  color: var(--prm-color);
}
.add-more a:hover {
  color: #00b7ed;
  /* color: #780303; */
}
.avatar-box {
  float: left;
}
.pro-overview .personal-info li .title {
  width: 50%;
}
.profile-box {
  min-height: 250px;
}

/*-----------------
	25. Notifications
-----------------------*/

.notifications {
  padding: 0;
}
.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}
.notifications .notification-heading {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.45;
  color: #616161;
}
.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}
.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}
.user-menu.nav > li > a .badge {
  color: #fff;
  font-weight: 700;
  position: absolute;
  right: 3px;
  top: 6px;
}
.user-menu.nav > li > a .badge {
  background-color: #7460ee;
}
.user-menu.nav > li > a > i {
  font-size: 20px;
  line-height: 60px;
}
.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}
.noti-title {
  color: #333;
}
.noti-time {
  margin: 0;
}

/*-----------------
	26. Roles & Permissions
-----------------------*/

.roles-menu {
  margin-top: 20px;
  height: 400px;
  overflow: auto;
}
.roles-menu > ul {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  list-style: none;
  margin: 0;
  padding: 0;
}
.roles-menu > ul > li {
  display: block;
  position: relative;
}
.roles-menu > ul > li a {
  border-left: 3px solid transparent;
  color: #333;
  display: block;
  padding: 10px 15px;
  position: relative;
}
.roles-menu > ul > li.active a {
  border-color: var(--prm-color);
  color: var(--prm-color);
}
.roles-menu > ul > li a:hover {
  background-color: #eee;
  border-color: var(--prm-color);
}
.role-action {
  float: right;
  display: none;
}
.roles-menu > ul > li a:hover .role-action {
  display: block;
}
.slide-nav .sidebar {
  margin-left: 0;
}

/*-----------------
	27. Chat Right Sidebar
-----------------------*/

.fixed-header .custom-menu {
  margin: 1px 0 0;
}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}
.list-box {
  list-style: none;
  padding-left: 0;
}
.list-item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  position: relative;
}
.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}
.files-icon {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.files-icon i {
  color: #777;
  font-size: 20px;
}
.list-body {
  padding: 0 0 0 50px;
}
.message-author {
  color: #333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-time {
  color: #888;
  float: right;
  font-size: 11px;
}
.message-content {
  color: #333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.files-action .dropdown-menu {
  left: auto;
  right: 0;
}

/*-----------------
	28. Projects
-----------------------*/

.team-members {
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.team-members > li:first-child a {
  margin-left: 0;
}
.team-members > li > a {
  border: 2px solid #fff;
  border-radius: 100%;
  display: block;
  height: 30px;
  overflow: hidden;
  width: 30px;
}
.team-members .all-users {
  line-height: 28px;
  opacity: 0.8;
}
.all-users {
  background-color: #ff5e3a;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  line-height: 34px;
  text-align: center;
}
.all-users:hover,
.all-users:active {
  color: #fff;
}
.team-members img {
  width: 100%;
}
.project-title {
  margin: 0 0 5px;
}
.project-title > h5 {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}
.project-title > a {
  color: #333;
}
.avatar-dropdown .dropdown-menu {
  min-width: unset;
  width: 100px;
  padding: 0;
}
.avatar-dropdown .avatar-group {
  min-height: 88px;
  padding: 2px;
  white-space: normal;
}
.avatar-dropdown .avatar-group .avatar {
  margin: 2px;
}
.avatar-dropdown .avatar-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.avatar-dropdown .page-link {
  padding: 0.3rem 0.55rem;
  border-radius: 0 !important;
  border: 0;
}
.avatar-dropdown .dropdown-toggle::after {
  display: none;
}

/*-----------------
	29. Invoice
-----------------------*/

.invoice-details,
.invoice-payment-details > li span {
  float: right;
  text-align: right;
}
.table .team-members {
  flex-wrap: nowrap;
}
.table .team-members > li > a {
  width: 32px;
  height: 32px;
}
.table .team-members .all-users {
  line-height: 30px;
}
.attach-files > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attach-files li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.attach-files li img {
  width: 50px;
}
.file-remove {
  color: #f00;
  position: absolute;
  right: -6px;
  top: -7px;
}
.attach-files li:last-child {
  margin-right: 0;
}
.inv-logo {
  height: auto;
  margin-bottom: 20px;
  max-height: 100px;
  width: auto;
}

/*-----------------
	30. Task
-----------------------*/

.task-chat-contents {
  background-color: #fff;
}
.task-chat-contents .chat-left .chat-content {
  max-width: 100%;
}
.task-chat-view {
  border-left: 1px solid #eaeaea;
}
.task-left-sidebar {
  width: 58.3333%;
}
.task-right-sidebar {
  width: 41.6667%;
}
.task-chat-view .chat-left .chat-content {
  border: 0;
  padding: 0;
}
.task-chat-view .chat-left .chat-body {
  margin-left: 50px;
}
.task-chat-view .chat-date {
  background-color: #fff;
}
.task-chat-view .chats {
  padding: 15px 15px 30px;
}
.task-chat-view .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}
.followers-add:hover {
  border: 1px solid #8c8c8c;
  color: #8c8c8c;
}
.completed-task-msg {
  margin-bottom: 30px;
  margin-left: 50px;
}
.task-chat-contents .chat.chat-left {
  margin-bottom: 20px;
}
.file-attached {
  color: #333;
  font-size: 13px;
}
.file-attached i {
  font-size: 16px;
}
.task-attach-img > img {
  border: 1px solid #eaeaea;
  max-width: 100%;
}
.attach-img-download {
  margin-bottom: 5px;
}
.task-chat-view .chat-left .chat-time {
  color: #a3a3a3;
  display: inline-block;
  font-size: 11px;
}
.task-chat-user {
  color: #333;
  font-size: 13px;
}
.task-chat-view .chat-content > p {
  font-size: 13px;
  margin-bottom: 0;
}
.task-time {
  color: #a3a3a3;
  display: inline-block;
  font-size: 11px;
}
.task-success {
  color: #55ce63;
  font-size: 13px;
}
.task-success a {
  color: #55ce63;
}
.task-attach-img {
  margin-bottom: 20px;
}
.task-assign {
  float: left;
}
.assign-title {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}
.task-assign > a {
  float: left;
}
.followers-add > i {
  line-height: 28px;
}
.task-followers {
  display: flex;
  margin-top: 15px;
}
.followers-title {
  margin: 5px 10px 0 0;
}
.task-line {
  border-color: #eaeaea;
}
.task-information {
  color: #a3a3a3;
  font-size: 11px;
  line-height: 17px;
  min-width: 1px;
  margin-left: 50px;
}
.task-info-line {
  overflow-wrap: break-word;
}
.task-user {
  color: #888;
  font-weight: 500;
}
.task-information .task-time {
  display: inline-block;
  padding-left: 10px;
}
.task-btn-right {
  display: none !important;
}
.task:hover .task-btn-right {
  display: table-cell !important;
}
.task-action-btn.task-check {
  text-align: left !important;
  width: 40px;
}
.add-task-btn {
  cursor: pointer;
  padding: 6px 10px;
  text-transform: uppercase;
}
.action-circle {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
}
.action-circle .material-icons {
  color: #ccc;
  font-size: 18px;
  vertical-align: -3px;
}
.action-circle.completed {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.action-circle.completed .material-icons {
  color: #fff;
}
.action-circle.large {
  height: 24px;
  width: 24px;
}
.action-circle.large .material-icons {
  font-size: 16px;
  vertical-align: -4px;
}
.task-wrapper {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.task-wrapper .mark-all-tasks {
  text-align: right;
  padding-bottom: 15px;
}
.task-wrapper .mark-all-tasks .mark-all-tasks-container {
  overflow: hidden;
  position: relative;
}
.task-wrapper .mark-all-tasks .mark-all-btn {
  cursor: pointer;
  display: inline-block;
  transition: all 200ms ease;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
  color: #35ba67;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
  color: #666;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
  border: 1px solid #666;
}
.task-wrapper
  .mark-all-tasks
  .mark-all-btn#mark-all-incomplete
  .action-circle
  .material-icons {
  color: #666;
}
.task-wrapper .mark-all-tasks .mark-all-btn.move-up {
  transform: translateY(-30px);
  position: absolute;
  right: 0;
}
.task-wrapper .mark-all-tasks .mark-all-btn.move-down {
  transform: translateY(30px);
  position: absolute;
  right: 0;
}
.task-wrapper .task-list-header h3,
.task-wrapper .task-list-footer h3 {
  font-size: 24px;
  font-weight: 400;
  color: #666;
}
.task-wrapper .task-list-body {
  max-height: 500px;
  overflow: auto;
}
.task-wrapper #task-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #eaeaea;
}
.task-wrapper #task-list li {
  margin: 0;
  padding: 0;
}
.task-wrapper #task-list li .task-container {
  display: table;
  background: #fff;
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eaeaea;
  border-bottom: none;
  box-sizing: border-box;
  position: relative;
  transition: background 200ms ease;
}
.task-wrapper #task-list li .task-container .task-label {
  display: table-cell;
  font-weight: 400;
  vertical-align: middle;
  color: #333;
  word-break: break-all;
}
.task-wrapper #task-list li .task-container .task-action-btn {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover {
  border: 1px solid #8c8c8c;
  background: #fff;
}
.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover
  .material-icons {
  color: #8c8c8c;
}
.task-wrapper #task-list li .task-container:hover {
  background: #ebeeef;
}
.task-wrapper #task-list li.completed .task-container {
  background: #f9f9f9;
}
.task-wrapper #task-list li.completed .task-container .complete-btn {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn
  .material-icons {
  color: #fff;
}
.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn:hover
  .material-icons {
  color: #fff;
}
.task-wrapper #task-list li.completed .task-container .task-label {
  color: #ccc;
}
.task-wrapper #task-list li.new .task-container {
  animation: taskHighlighter 2000ms linear 1;
}
.task-wrapper .task-list-footer {
  position: relative;
}
.task-wrapper .task-list-footer .add-task-btn-wrapper {
  text-align: center;
}
.task-wrapper .task-list-footer .add-task-btn {
  cursor: pointer;
  border: 2px solid #9b1bcc;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  transition: all 300ms ease;
}
.task-wrapper .task-list-footer .add-task-btn.hide {
  opacity: 1;
  visibility: visible;
  display: block;
}
.task-wrapper .task-list-footer .add-task-btn:hover {
  background: #9b1bcc;
}
.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
  color: #ebeeef;
}
.task-wrapper .task-list-footer .add-task-btn .material-icons {
  color: #9b1bcc;
  font-size: 34px;
}
.task-wrapper .task-list-footer .new-task-wrapper {
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden;
}
.task-wrapper .task-list-footer .new-task-wrapper::after {
  content: "";
  display: block;
  clear: both;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eaeaea;
  border-top: 0;
  height: 45px;
  resize: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  transition: all 300ms ease;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
  border: 1px solid #bfbfbf;
  outline: none;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
  border: 1px solid #d93737;
  outline: none;
}
.task-wrapper .task-list-footer .new-task-wrapper .error-message {
  color: #d93737;
  font-style: italic;
}
.task-wrapper .task-list-footer .new-task-wrapper .btn {
  padding: 6px 12px;
  float: right;
  cursor: pointer;
}
.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
  background: #fff;
  border: 1px solid #ccc;
}
.task-wrapper .task-list-footer .new-task-wrapper.visible {
  max-height: 300px;
  overflow: auto;
}
.notification-popup {
  z-index: 1051;
  position: fixed;
  top: 20px;
  right: 10px;
  width: 300px;
  display: inline-block;
  background: #1f8fef;
  border: 1px solid #1082e4;
  color: #fff;
  padding: 20px;
  opacity: 0.8;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 300ms ease;
}
.notification-popup.success {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.notification-popup p {
  margin-top: 0;
  margin-bottom: 0px;
}
.notification-popup .task {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
.notification-popup .notification-text {
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}
.notification-popup.hide {
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay {
  display: none;
  position: fixed;
  z-index: 90;
  height: 100%;
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.6);
}
.sidebar-overlay.opened {
  display: block;
}
.sidebar-menu.opened {
  opacity: 1;
  transform: translateX(0px);
  margin-left: 0;
}
html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}
.task-chat-view.opened {
  margin-right: 0;
}
.chat-profile-view.opened {
  margin-right: 0;
}
.task-header {
  display: flex;
}
.task-header .assignee-info {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 1 180px;
  padding: 5px 10px 5px 5px;
  position: relative;
}
.task-header .assignee-info > a {
  align-items: center;
  color: #333;
  display: flex;
}
.task-header .task-head-title {
  color: #8e8e8e;
  font-size: 12px;
}
.task-assignee {
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assignee-info:hover {
  border-color: #e3e3e3;
}
.remove-icon {
  align-items: center;
  background-color: #e3e3e3;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: 20px;
}
.remove-icon:hover {
  background-color: #d3d3d3;
}
.task-header .assignee-info:hover .remove-icon {
  visibility: visible;
}
.remove-icon i {
  color: #8e8e8e;
  font-size: 12px;
}
.due-icon span {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 30px;
  margin-right: 10px;
  width: 30px;
}
.due-icon span i {
  font-size: 18px;
}
.task-due-date {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex: 0 0 160px;
  margin-left: 10px;
  padding: 5px 10px 5px 5px;
  position: relative;
}
.task-due-date:hover {
  border-color: #e3e3e3;
}
.task-header .task-due-date > a {
  align-items: center;
  color: #333;
  display: flex;
}
.due-info .due-date {
  color: #f62d51;
  font-size: 13px;
}
.task-header .task-due-date:hover .remove-icon {
  visibility: visible;
}
.task-desc {
  align-items: flex-start;
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
}
.task-textarea {
  display: flex;
  width: 100%;
}
.task-desc-icon {
  color: #ccc;
  margin-right: 10px;
  margin-top: 5px;
}
.task-textarea .form-control {
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 13px;
  resize: none;
}
.task-textarea:hover .form-control {
  border-color: #e3e3e3;
}
.task-complete-btn {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  display: flex;
  font-size: 14px;
  padding: 6px 10px;
}
.task-complete-btn:hover {
  background-color: #f7f7f7;
  color: #333;
}
.task-complete-btn i {
  font-size: 20px;
  margin-right: 3px;
}
.task-completed {
  background-color: #35ba67;
  border-color: #2fa65c;
  color: #fff;
}
.task-completed:hover {
  background-color: #35ba67;
  border-color: #2fa65c;
  color: #fff;
}

/*-----------------
	31. Project View
-----------------------*/

.project-user .list-box {
  margin: 0;
}
.project-files i {
  color: #76838f;
  font-size: 50px;
}
.project-user .list-item {
  border: 0 none;
  padding: 10px 0;
}
.table-border {
  border: 1px solid #ddd;
}
.file-size {
  color: #888;
}
.project-task .task-wrapper {
  padding: 0;
}
.project-task .tab-content {
  min-height: 200px;
}
.uploaded-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  margin-bottom: 20px;
  padding: 4px;
}
.uploaded-box .uploaded-img-name {
  color: #333;
  padding: 9px;
  text-align: center;
}

/*-----------------
	32. Payslip
-----------------------*/

.payslip-title {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}

/*-----------------
	33. Attendance
-----------------------*/

.first-off {
  white-space: pre-wrap;
}
.stats-info {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
  padding: 30px 15px;
  text-align: center;
  border-radius: 10px;
}
.stats-info h6 {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 5px;
}
.stats-info h4 {
  font-size: 24px;
  margin-bottom: 0;
}
.stats-info h4 span {
  color: #727272;
  font-size: 12px;
}
.punch-det {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.punch-det h6 {
  line-height: 20px;
  margin-bottom: 0;
}
.punch-det p {
  color: #727272;
  font-size: 14px;
  margin-bottom: 0;
}
.punch-info {
  margin-bottom: 20px;
}
.punch-hours {
  align-items: center;
  background-color: #f9f9f9;
  border: 5px solid #e3e3e3;
  border-radius: 50%;
  display: flex;
  font-size: 18px;
  height: 120px;
  justify-content: center;
  margin: 0 auto;
  width: 120px;
}
.punch-btn-section {
  margin-bottom: 20px;
  text-align: center;
}
.punch-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  padding: 8px 40px;
}
.punch-status .stats-box {
  margin-bottom: 0;
}
.stats-list {
  height: 331px;
  overflow-y: auto;
}
.att-statistics .stats-info {
  margin-bottom: 5px;
}
.att-statistics .progress {
  height: 4px;
}
.statistics .row {
  margin-left: -5px;
  margin-right: -5px;
}
.statistics .row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.stats-box {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  margin-bottom: 15px;
  padding: 5px;
}
.stats-box p {
  margin: 0;
  font-size: 12px;
}
.stats-box > h6 {
  margin-bottom: 0;
}
.stats-info:last-child .progress {
  margin-bottom: 0;
}
.stats-info p {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.recent-activity .res-activity-list {
  height: 328px;
  list-style-type: none;
  margin-bottom: 0;
  overflow-y: auto;
  padding-left: 30px;
  position: relative;
}
.recent-activity .res-activity-list li {
  margin-bottom: 15px;
  position: relative;
}
.recent-activity .res-activity-list li:last-child {
  margin-bottom: 0;
}
.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -30px;
  top: 0px;
  border: 2px solid var(--prm-color);
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.recent-activity .res-activity-time {
  color: #bbb;
  font-size: 12px;
}
.recent-activity h6 {
  line-height: 14px;
  margin-bottom: 0;
}
.recent-activity p {
  font-size: 13px;
  margin-bottom: 0;
}
.half-day {
  width: 15px;
}

/*-----------------
	34. Ticket
-----------------------*/

.ticket-header {
  padding: 6px 0;
}
.ticket-priority {
  font-size: 14px;
}
.ticket-chat-view {
  width: 33.3333%;
}
.dataTables_wrapper.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/*-----------------
	35. Client Profile
-----------------------*/

.tab-box {
  border-bottom: 0;
  margin-bottom: 10px;
  padding: 0;
}
.team-members a.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

/*-----------------
	36. Inbox
-----------------------*/

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}
.mail-list {
  list-style: none;
  padding: 0;
}
.mail-list > li > a {
  color: #333;
  display: block;
  padding: 10px;
}
.mail-list > li.active > a {
  color: var(--prm-color);
  font-weight: bold;
}
.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 600;
}
.table-inbox .fa-star {
  color: #ffd200;
}
.table-inbox .starred.fa-star {
  color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}
.table-inbox {
  font-size: 14px;
  margin-bottom: 0;
}
.note-editor.note-frame {
  border: 1px solid #ccc;
  box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}
.note-editor.note-frame.fullscreen {
  top: 60px;
}
.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}
.form-control.search-message {
  border-radius: 4px;
  height: 38px;
  margin-left: 5px;
  width: 180px;
}
.table-inbox tr {
  cursor: pointer;
}
table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}
.mail-label {
  font-size: 16px !important;
  margin-right: 5px;
}

/*-----------------
	37. Mail View
-----------------------*/

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 200px;
}
.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  text-align: center;
  min-height: 153px;
}
.attach-file > i {
  line-height: 133px;
}
.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}
.attach-filename {
  color: #777;
  font-weight: bold;
}
.attach-filesize {
  color: #999;
  font-size: 12px;
}
.attach-file img {
  height: auto;
  max-width: 100%;
}
.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}
.mailview-footer .btn-white {
  min-width: 102px;
}
.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}
.sender-name {
  display: block;
}
.receiver-name {
  color: #777;
}
.right-action {
  text-align: right;
}
.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}
.mail-view-action {
  float: right;
}
.mail-sent-time {
  float: right;
}

/*-----------------
	38. Voice call
-----------------------*/

.voice-call-avatar {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2 1;
}
.voice-call-avatar .call-avatar {
  margin: 15px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  background-color: #fff;
}
.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}
.voice-call-avatar .call-timing-count {
  padding: 5px;
}
.voice-call-avatar .username {
  font-size: 18px;
  text-transform: uppercase;
}
.call-icons {
  text-align: center;
  position: relative;
}
.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}
.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  font-size: 20px;
}
.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}
.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}
.my-video ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}
.my-video ul li img {
  border: 3px solid #fff;
  border-radius: 6px;
}
.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}
.end-call a {
  background-color: #f06060;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  line-height: 10px;
  padding: 8px 25px;
  text-transform: uppercase;
}
.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}
.call-users ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}
.call-users ul li img {
  border-radius: 6px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  display: none;
  top: 0;
  border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
  display: block;
}
.call-details {
  margin: 10px 0 0px;
  display: flex;
}
.call-info {
  margin-left: 10px;
  width: 100%;
}
.call-user-details,
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}
.call-timing {
  color: #727272;
  display: flex;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}

/*-----------------
	39. Video Call
-----------------------*/

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}
.video-window .fixed-header {
  padding: 0;
  border: 0;
}
.video-window .fixed-header .nav > li > a {
  padding: 18px 15px;
}

/*-----------------
	40. Outgoing Call
-----------------------*/

.call-box {
  display: block;
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  position: sticky;
  top: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
}
.call-box .call-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  text-align: center;
}
.call-box .call-wrapper .call-avatar {
  margin-bottom: 50px;
  cursor: pointer;
  animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
  margin-bottom: 50px;
}
.call-box .call-wrapper .call-user h4 {
  color: #fff;
}
.call-box .call-wrapper .call-user span {
  display: block;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.call-box .call-wrapper .call-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 100%;
  color: #fff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
  opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
  padding: 20px;
  margin: 30px 20px 0;
  background: #f06060;
  border: 1px solid #f06060;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
  padding: 20px;
  margin: 30px 20px 0;
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
  margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 10px;
}
.call-box .call-avatar {
  border-radius: 100%;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  position: relative;
  width: 100%;
}
.call-box .btn {
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease 0s;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

/*-----------------
	41. Incoming Call
-----------------------*/

.incoming-btns {
  margin-top: 20px;
}

/*-----------------
	42. Contacts
-----------------------*/

.contacts-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}
.contacts-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
}
.contacts-header .user-info a {
  color: #76838f;
  text-transform: uppercase;
}
.contact-left {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  transition: all 0.4s ease;
  width: 100%;
}
.contacts-list {
  position: relative;
  padding: 0 70px 0 20px;
}
.contact-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.contact-list > li {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
}
.contact-cont {
  position: relative;
}
.contact-type {
  height: 48px;
  position: absolute;
  width: 48px;
}
.contact-icon {
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.contact-icon i {
  color: #76838f;
  font-size: 20px;
}
.contact-info {
  padding: 0 30px 0 50px;
}
.contact-action {
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}
.contact-date {
  color: #888;
  font-size: 12px;
}
.contact-author a {
  color: #1d6ad2;
  font-size: 12px;
  text-decoration: underline;
}
.contact-action .dropdown-menu {
  left: auto;
  right: 0;
}
.contact-alphapets {
  background-color: #fff;
  border-left: 1px solid #ddd;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  padding-bottom: 114px;
  position: fixed;
  right: 0;
  top: 114px;
  width: 50px;
}
.contact-box {
  display: inline-table;
  height: 100%;
  padding: 30px 15px;
  position: relative;
  width: 100%;
}
.alphapets-inner {
  height: 100%;
  overflow: auto;
}
.alphapets-inner a {
  display: block;
  text-align: center;
  padding: 2px;
  color: #333;
}

/*-----------------
	43. Chat Sidebar
-----------------------*/

.chat-sidebar {
  background-color: #fff;
  border-left: 1px solid #eaeaea;
  width: 300px;
}
.chat-sidebar .chat-contents {
  background-color: #fff;
}
.chat-sidebar .chat-left .chat-content {
  border: 0;
  padding: 0;
  border-radius: 0 !important;
  background-color: transparent;
  max-width: 100%;
}
.chat-sidebar .chat-left .chat-body {
  margin-left: 50px;
  padding-right: 0;
}
.chat-sidebar .chat-date {
  background-color: #fff;
}
.chat-sidebar .chats {
  padding: 15px 15px 30px;
}
.chat-sidebar .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.chat-sidebar .chat.chat-left {
  margin-bottom: 20px;
}
.chat-sidebar .chat.chat-left:last-child {
  margin-bottom: 0;
}
.chat-sidebar .chat-left .chat-time {
  color: #888;
  display: inline-block;
  font-size: 11px;
}
.chat-sidebar .chat-content > p {
  font-size: 13px;
  margin-bottom: 0;
}
.chat-sidebar.opened {
  margin-right: 0;
}

/*-----------------
	44. Jobs
-----------------------*/

.apply-btn {
  background: var(--prm-color);
  background: linear-gradient(to right, var(--prm-color) 0%, var(--sec-color) 100%);
  border-color: transparent;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.job-list {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
  display: block;
  margin-bottom: 35px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.job-list-det {
  align-items: flex-start;
  display: flex;
  align-items: center;
  padding: 20px;
}
.job-list .job-list-desc {
  flex: 1 1;
}
.job-list h4.job-department {
  color: #777;
  font-size: 14px;
  margin-bottom: 0;
}
.job-list h3.job-list-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.job-list .job-list-footer {
  background-color: #f9f9f9;
  border-radius: 0 0 4px 4px;
  position: relative;
  padding: 20px;
}
.job-list .job-list-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.job-list .job-list-footer ul li {
  color: #777;
  display: inline-block;
  margin-right: 14px;
}
.job-list .job-list-footer ul li i {
  color: #777;
  margin-right: 3px;
  position: relative;
}
.job-list .job-list-footer ul li:last-child {
  margin-right: 0;
}
.page-wrapper.job-wrapper {
  margin-left: 0;
}
.job-types {
  background-color: transparent;
  border: 1px solid var(--prm-color);
  color: var(--prm-color);
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  text-align: center;
}

/*-----------------
	45. Job Details
-----------------------*/

.job-info {
  background-color: #fff;
  border: 1px solid #efefef;
  float: left;
  margin: 0 0 30px;
  padding: 30px;
  position: relative;
  width: 100%;
}
.job-title {
  color: #585858;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
.job-info .job-dept {
  color: #737882;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.job-post-det {
  display: inline-block;
  margin-bottom: 5px;
  width: 100%;
  color: #737882;
  list-style: none;
  margin: 0;
  padding: 0;
}
.job-content li {
  line-height: 28px;
}
.job-post-det li {
  float: left;
  list-style: none;
  margin-bottom: 10px;
  margin-right: 20px;
}
.job-det-info {
  background-color: #fff;
  margin: 0 0 15px;
  padding: 0 0 6px;
}
.info-list {
  color: #888;
  padding-top: 20px;
}
.info-list span {
  color: var(--prm-color);
  float: left;
  font-size: 20px;
  line-height: 14px;
}
.info-list p {
  line-height: 1.7;
  margin: 0 0 0 40px;
}
.info-list > h5 {
  color: #585858;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 40px;
  text-transform: uppercase;
}
.app-ends {
  color: #46cd38;
  font-size: 13px;
}
.job-btn {
  border: 2px solid var(--prm-color);
  color: var(--prm-color);
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.job-btn:hover {
  background-color: var(--prm-color);
  color: #fff;
}
.job-widget {
  background-color: #fff;
  border: 1px solid #efefef;
  float: left;
  margin: 0 0 30px;
  padding: 30px;
  position: relative;
  width: 100%;
}
.job-desc-title h2 {
  font-size: 20px;
  margin-bottom: 0;
}
.job-widget h4 {
  color: #585858;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px;
}
.job-description > p {
  color: #727272;
  line-height: 28px;
}
.square-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.square-list li {
  color: #727272;
  line-height: 24px;
  list-style: none;
  margin: 10px 0 0 20px;
  position: relative;
}
.square-list li:first-child {
  margin-top: 0;
}
.square-list li:before {
  font-family: var(--app-font-family);
  margin: 0;
  position: relative;
  color: var(--prm-color);
  float: left;
  margin-left: -20px;
  display: block;
  content: "\f0c8";
  font-size: 8px;
}
.text-blue {
  color: #009ce7;
}

/*-----------------
	46. Notification Settings
-----------------------*/

.notification-list .list-group-item {
  padding: 15px;
}
.notification-list .status-toggle {
  float: right;
}

/*-----------------
	47. Leave Settings
-----------------------*/

.leave-item {
  max-width: 653px;
}
.leave-row {
  display: flex;
  margin-bottom: 15px;
}
.carry-forward-itemview {
  margin-bottom: 15px;
}
.earned-leave-itemview {
  margin-bottom: 15px;
}
.leave-row .leave-left {
  flex: 1 1 auto;
}
.leave-row .leave-left .input-box {
  max-width: 410px;
}
.leave-item .form-group {
  margin-bottom: 0;
}
.leave-right {
  align-items: center;
  display: flex;
  flex: 0 0 200px;
  justify-content: flex-end;
  margin-top: 31px;
  min-height: 44px;
  text-align: right;
}
.leave-right .btn {
  min-height: 44px;
  min-width: 75px;
}
.leave-right .btn + .btn {
  margin-left: 10px;
}
.leave-edit-btn {
  color: #216ef4;
  background-color: transparent;
  border: 0;
  padding: 0 6px;
  transition: unset;
}
.leave-edit-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.leave-inline-form {
  align-items: center;
  display: flex;
  min-height: 44px;
}
.leave-header {
  align-items: center;
  color: #212536;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.leave-header .title {
  flex: 1 1 auto;
}
.leave-header .leave-action {
  flex: 1 1 auto;
  text-align: right;
}
.leave-table .l-name {
  width: 200px;
}
.leave-table .l-days {
  width: 140px;
}
.leave-box .subtitle {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: normal;
  margin: 5px auto 0 5px;
}
.leave-duallist {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  display: block;
  padding: 15px;
}
.card-title.with-switch {
  display: flex;
  justify-content: space-between;
}
.btn.leave-delete-btn {
  font-weight: 500;
  margin-left: 10px;
  min-height: 30px;
  padding: 2px 15px;
}

/*-----------------
	48. Termination
-----------------------*/

.add-group-btn {
  display: flex;
}
.add-group-btn .btn {
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.add-group-btn .btn i {
  margin-right: 5px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: var(--prm-color);
  text-shadow: unset;
}

/*-----------------
	49. Loader
-----------------------*/

#loader-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
#loader {
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}
.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 64px;
  margin: 100px auto 0;
}
.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}
.loader-ellips__dot:nth-child(3) {
  left: 1.5em;
}
.loader-ellips__dot:nth-child(4) {
  left: 3em;
}
.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}
.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}
.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}
@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slide {
  to {
    transform: translateX(1.5em);
  }
}
.loader-ellips__dot:nth-child(1) {
  background: #404040;
}
.loader-ellips__dot:nth-child(2) {
  background: var(--prm-color);
}
.loader-ellips__dot:nth-child(3) {
  background: #404040;
}
.loader-ellips__dot:nth-child(4) {
  background: #404040;
}

/*-----------------
	50. Payroll Items
-----------------------*/

.payroll-batch-dropdown-action {
  display: flex;
}

.payroll-batch-dropdown-item {
  padding: 5px 10px;
  border: none;
  width: 80px;
  border-radius: 5px;
  background-color: var(--prm-color);
}

.page-menu .nav-tabs > li > a {
  font-size: 18px;
}
.table-radius.table {
  width: 100%;
  background: #fff;
  border-spacing: 0;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.table-radius.table thead td,
.table-radius.table thead th {
  border-top: 0 none !important;
  padding: 20px !important;
}

.table-radius.table tbody td,
.table-radius.table tbody th {
  padding: 15px 20px !important;
}

/*-----------------
	51. Error
-----------------------*/

.error-page {
  align-items: center;
  background-color: rgba(0, 197, 251, 0.2);
  color: #1f1f1f;
  display: flex;
}
.error-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.error-box {
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
  width: 100%;
}
.error-box h1 {
  color: var(--prm-color);
  font-size: 10em;
}
.error-box p {
  margin-bottom: 30px;
}
.error-box .btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

/*-----------------
	52. Lock Screen
-----------------------*/

.lock-user {
  margin-bottom: 20px;
  text-align: center;
}
.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

/*-----------------
	53. OTP
-----------------------*/

.otp-wrap {
  margin-bottom: 30px;
  text-align: center;
}
.otp-input {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  height: 70px;
  line-height: 29px;
  margin-right: 15px;
  text-align: center;
  width: 70px;
}
.otp-input:last-child {
  margin-right: 0;
}

/*-----------------
	54. Salary Settings
-----------------------*/

.settings-widget + .settings-widget {
  margin-top: 30px;
}
.row.row-sm {
  margin-left: -5px;
  margin-right: -5px;
}
.row.row-sm > div {
  padding-left: 5px;
  padding-right: 5px;
}
.set-btn {
  min-height: 44px;
}

/*-----------------
	55. Components
-----------------------*/

.comp-buttons .btn {
  margin-bottom: 5px;
}
.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}
.progress-example .progress {
  margin-bottom: 1.5rem;
}
.progress-xs {
  height: 4px;
}
.progress-sm {
  height: 15px;
}
.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}
.stickyside .sidebar-menu > ul > li > a.active {
  color: var(--prm-color);
}
.comp-section {
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.comp-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.comp-section .section-title {
  font-size: 1.125rem;
  margin-bottom: 20px;
  text-decoration: underline;
  text-transform: uppercase;
}

/*-----------------
	56. Search
-----------------------*/

.main-search {
  margin-bottom: 20px;
}
.search-result {
  margin-bottom: 30px;
}
.search-result u {
  color: #00b7ed;
}
.search-result p {
  color: #777;
  margin-bottom: 0;
}
.search-lists .nav-tabs.nav-tabs-solid {
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}
.top-nav-search .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  color: #fff;
  height: 40px;
  padding: 10px 50px 10px 15px;
}
.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}
.top-nav-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.responsive-search {
  display: none;
  color: #333;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
}
.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute;
}

/*-----------------
	57. Knowledgebase
-----------------------*/

.topics {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 20px;
}
.topics .topic-title {
  color: #333;
  margin-bottom: 20px;
  padding: 0 25px 0 0;
  position: relative;
}
.topics .topic-title a {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}
.topics .topic-title a:hover {
  color: #00b7ed;
}
.topics .topic-title a span {
  color: #00b7ed;
}
.topics .topic-title a i {
  color: #00b7ed;
  margin-right: 5px;
}
.topics .topics-list {
  padding: 0;
  margin: 0;
}
.topics .topics-list li {
  list-style: none;
  line-height: 2;
}
.topics .topics-list li a {
  padding-left: 25px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  outline: 0 none;
}
.topics .topics-list li a:hover {
  color: #00b7ed;
}
.topics .topics-list li:before {
  font-family: var(--app-font-family);
  content: "\f016";
  color: #555;
  font-size: 15px;
  left: 0;
  position: absolute;
  top: 0;
}
.topics .topics-list li {
  position: relative;
}
.widget {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 20px;
}
.widget:last-child {
  margin-bottom: 0;
}
.widget h4 {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.widget > ul {
  margin-bottom: 0;
  padding: 0;
  margin: 0;
}
.widget-category li {
  line-height: 35px;
  list-style: none;
  position: relative;
}
.widget-category li:before {
  font-family: var(--app-font-family);
  content: "\f016";
  color: #555;
  font-size: 18px;
  left: 0;
  position: absolute;
  top: 0;
}
.widget-category li a {
  color: #5e6977;
  padding-left: 25px;
}
.widget-category li a:hover {
  color: #00b7ed;
}
.post {
  margin-bottom: 50px;
}
.post .meta {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  font-size: 12px;
  margin: 0 0 20px;
  padding: 10px;
}
.post .meta p {
  padding: 15px 0;
}
.post .meta li {
  display: inline-block;
  color: #86939e;
}
.post .meta li span {
  color: #43484d;
}
.post .meta li:after {
  content: "/";
  padding: 0 10px;
}
.post .meta li:last-child:after {
  content: none;
}
.post img {
  padding: 40px 0;
  max-width: 100%;
}
.feedback {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  padding: 20px;
  margin-bottom: 50px;
}
.feedback h3 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}
.feedback p:last-child {
  margin-bottom: 0;
}
.post .meta a {
  color: #00b7ed;
}
.widget .widget-title i {
  color: #00b7ed;
  font-size: 24px;
  margin-right: 10px;
}
.post h1.post-title {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}
.post h2 {
  font-size: 24px;
  font-weight: 400;
}
.post > p {
  margin-bottom: 20px;
}
.comment-section {
  float: left;
  width: 100%;
}
.comment-section h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
ul.comment-list {
  border-top: 0;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul.comment-list li {
  border: 0;
  box-sizing: border-box;
  clear: both;
  float: left;
  list-style: outside none none;
  margin: 0 0 30px;
  padding: 0 0 0 70px;
  position: relative;
  width: 100%;
}
ul.comment-list li:last-child {
  margin-bottom: 0;
}
ul.comment-list li div.comment {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
ul.comment-list li .comment-author {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
ul.comment-list li .comment-author img {
  border-radius: 50px;
  float: left;
  height: 50px;
  margin: 0;
  max-height: 50px;
  max-width: 50px;
  width: 50px;
}
ul.comment-list li .comment-details {
  margin: 10px 0;
  padding: 0 0 10px;
  position: relative;
}
ul.comment-list li .author-name > a {
  color: #2f363f;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
}
ul.comment-list li .author-name span {
  float: left;
  font-size: 14px;
  width: 100%;
}
ul.comment-list li .comment-body {
  float: left;
  margin: 10px 0 0;
  width: 100%;
}
ul.comment-list li .comment-body p {
  line-height: 28px;
}
ul.comment-list li .reply a {
  clear: both;
  color: #2f363f;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0;
}
ul.comment-list li ul.children {
  border: 0 none;
  clear: both;
  float: left;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
.comment-section .comment-reply {
  float: left;
  margin-top: 30px;
  width: 100%;
}
.comment-reply form {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}
.comment-reply .form-control {
  background-color: #f9f9f9;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  min-height: 50px;
}
.comment-reply .form-control:focus {
  box-shadow: unset;
}

/*-----------------
	58. FAQ
-----------------------*/

.faq-card .card-header {
  background-color: #fff;
  padding: 20px;
  position: relative;
}
.faq-card .card {
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: hidden;
}
.faq-card .card .card-header h4 {
  margin-bottom: 0;
}
.faq-card .card .card-header h4 > a {
  color: #090909;
  display: block;
  font-size: 20px;
  font-weight: 400;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > a:after {
  font-family: var(--app-font-family);
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
}
.faq-card .card .card-header h4 > a.collapsed:after {
  content: "\f067";
}
.faq-card .card .card-header h4 > a:not(.collapsed):after {
  content: "\f068";
}
.faq-card .card .card-header h4 > label {
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > label:after {
  font-family: var(--app-font-family);
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
  top: 0;
}
.faq-card .card .card-header h4 > label.collapsed:after {
  content: "\f067";
}
.faq-card .card .card-header h4 > label:not(.collapsed):after {
  content: "\f068";
}
.small-container {
  margin: auto;
  max-width: 1100px;
  padding: 30px;
}
.inner-header {
  margin-bottom: 30px;
}

/*-----------------
	59. Employee Dashboard
-----------------------*/

.welcome-box {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  display: flex;
  margin: -30px -30px 30px;
  padding: 20px;
  position: relative;
}
.welcome-img {
  margin-right: 15px;
}
.welcome-img img {
  border-radius: 8px;
  width: 60px;
}
.welcome-det p {
  color: #777;
  font-size: 18px;
  margin-bottom: 0;
}
.dash-section {
  margin-bottom: 30px;
}
.dash-list {
  background-color: white;
  border-color: #f1f6f8;
  color: #2e424d;
  display: flex;
  margin: 10px 0 0;
}
.dash-card-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.dash-card-icon {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 26px;
  padding-right: 15px;
}
.dash-card-icon i {
  width: 30px;
}
.dash-card-content {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.e-avatar {
  background-color: #fff;
  border: 3px solid #e7e7e7;
  border-radius: 50%;
  height: 45px;
  position: relative;
  width: 45px;
  margin-left: -20px;
}
.e-avatar:first-of-type {
  margin-left: 0 !important;
}
.dash-card-avatars {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 15px;
}
.e-avatar > img {
  border-radius: 50%;
  width: 100%;
}
.dash-sec-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.dash-info-list .dash-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #575757;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  padding: 15px;
}
.dash-card-content p {
  font-size: 22px;
  margin-bottom: 0;
}
.dash-sec-content .dash-info-list + .dash-info-list {
  margin-top: 15px;
}
.dash-sidebar h5 {
  color: inherit;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.dash-sidebar p {
  color: #777;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  text-transform: uppercase;
}
.dash-sidebar .card {
  border-radius: 15px;
}
.time-list {
  margin-bottom: 20px;
}
.request-btn {
  text-align: center;
}
.dash-stats-list {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
}
.dash-stats-list + .dash-stats-list {
  border-left: 1px solid #e7e7e7;
}
.dash-stats-list h4 {
  color: #1f1f1f;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
}

/*-----------------
	60. Performance Review
-----------------------*/

.review-table tr {
  background-color: #fff;
}
.review-section {
  margin-bottom: 30px;
}
.review-header {
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-bottom: 0;
  padding: 15px;
  text-align: center;
}
.review-header h3 {
  font-size: 1.4rem;
  margin-bottom: 3px;
}
.review-header p {
  margin-bottom: 0;
}
.review-table .form-control {
  height: 36px;
}
.grade-span .badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  min-width: 75px;
  padding: 4px 12px;
  text-align: center;
}
.review-table .select2-container .select2-selection--single {
  height: 36px;
}
.review-table
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 34px;
}
.review-table
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 34px;
}

/*-----------------
	61. Kanban Board
-----------------------*/

.card > .task-header {
  line-height: 15px;
  display: flex;
  justify-content: space-between;
}
.task-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
}
.task-header a {
  color: #1f1f1f;
}
.task-body {
  padding: 0 15px 15px;
  font-size: 13px;
}
.kanban-list:last-child {
  margin-right: 0;
}
.kanban-list > .kanban-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.kanban-list > .kanban-header > .status-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.kanban-list > .kanban-wrap {
  padding: 20px;
}
.kanban-list > .kanban-wrap > .drag-placeholder {
  height: 146px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
}
.kanban-list > .kanban-wrap.ks-empty > .card.drop-here {
  display: block;
}
.kanban-list > .kanban-wrap > .card {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: unset;
  margin: 0 0 12px;
}
.kanban-list > .kanban-wrap > .card.drop-here {
  display: none;
  text-align: center;
  background: transparent;
  padding: 15px;
  border-style: dashed;
}
.kanban-list > .kanban-wrap > .card.drop-here > a {
  color: #333;
  font-weight: 500;
}
.kanban-list > .kanban-wrap > .card:last-child {
  margin-bottom: 0;
}
.kanban-list > .kanban-wrap > .card.ui-sortable-helper {
  box-shadow: 0 2px 20px 0 #d7dceb;
}
.kanban-list > .kanban-wrap > .card > .task-header {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px 15px 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kanban-list > .kanban-wrap > .card > .task-body {
  padding: 0 15px 15px;
}

.kanban-list > .kanban-wrap > .card > .kanban-box > .task-body > .kanban-info {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress {
  margin-bottom: 0;
  margin-right: 10px;
  width: 100%;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #42a5f5;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  .ks-icon {
  color: #d7dceb;
  font-size: 20px;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  > .task-users
  > .task-avatar
  > img {
  width: 24px;
  height: 24px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  > .task-users
  > .ks-amount {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 1px;
}
.kanban-list.kanban-danger {
  background-color: #fef7f6;
}
.kanban-list.kanban-danger > .kanban-header {
  background-color: #ef5350;
}
.kanban-list.kanban-danger > .kanban-wrap > .card.drop-here {
  border-color: #f7c1b7;
}
.kanban-list.kanban-danger
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ef5350;
}
.kanban-list.kanban-success {
  background-color: #edf7ee;
}
.kanban-list.kanban-success > .kanban-header {
  background-color: #4caf50;
}
.kanban-list.kanban-success > .kanban-wrap > .card.drop-here {
  border-color: #abebc6;
}
.kanban-list.kanban-success
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #4caf50;
}
.kanban-list.kanban-info {
  background-color: #e7f3fe;
}
.kanban-list.kanban-info > .kanban-header {
  background-color: #42a5f5;
}
.kanban-list.kanban-info > .kanban-wrap > .card.drop-here {
  border-color: #a6dbf9;
}
.kanban-list.kanban-warning {
  background-color: #fdfcf3;
}
.kanban-list.kanban-warning > .kanban-header {
  background-color: #ffb300;
}
.kanban-list.kanban-warning > .kanban-wrap > .card.drop-here {
  border: 1px solid #f2e49e;
}
.kanban-list.kanban-warning
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ffb300;
}
.task-avatar {
  border-radius: 50%;
  position: relative;
}
.kanban-box.ui-sortable-handle {
  cursor: move;
}
.card > .task-board-header {
  line-height: 15px;
  display: flex;
  justify-content: space-between;
}
.task-board-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
  display: flex;
  justify-content: space-between;
}
.task-board-header a {
  color: #1f1f1f;
}
.task-board-body {
  padding: 0 15px 15px;
  font-size: 13px;
}
.progress > .progress-bar {
  background-color: var(--prm-color);
}
.kanban-cont {
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
}
.kanban-list {
  align-self: flex-start;
  margin-right: 30px;
  min-width: 300px;
  width: 300px;
}
.kanban-list:last-child {
  margin-right: 0;
}
.kanban-list > .kanban-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.kanban-list > .kanban-header > .status-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.kanban-list > .kanban-wrap {
  padding: 20px;
}
.kanban-list > .kanban-wrap > .drag-placeholder {
  height: 146px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
}
.kanban-list > .kanban-wrap > .card {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: unset;
  margin: 0 0 12px;
}
.kanban-list > .kanban-wrap > .card.active {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.kanban-list > .kanban-wrap > .card:last-child {
  margin-bottom: 0;
}
.kanban-list > .kanban-wrap > .card.ui-sortable-helper {
  box-shadow: 0 2px 20px 0 #d7dceb;
}
.kanban-list > .kanban-wrap > .card > .task-board-header {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px 15px 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kanban-list > .kanban-wrap > .card > .task-board-body {
  padding: 0 15px 15px;
}

.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress {
  margin-bottom: 0;
  margin-right: 10px;
  width: 100%;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #42a5f5;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
  align-items: flex-end;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer
  > .task-users
  > .task-avatar
  > img {
  width: 24px;
  height: 24px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer
  > .task-users
  > .task-user-count {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 1px;
}
.kanban-list.kanban-danger > .kanban-wrap > .card.active {
  border-color: #ef5350;
}
.kanban-list.kanban-success > .kanban-wrap > .card.active {
  border-color: #4caf50;
}
.kanban-list.kanban-info > .kanban-wrap > .card.active {
  border-color: #42a5f5;
}
.kanban-list.kanban-warning > .kanban-wrap > .card.active {
  border-color: #ffb300;
}
.kanban-list.kanban-danger {
  background-color: #fef7f6;
}
.kanban-list.kanban-danger > .kanban-header {
  background-color: #ef5350;
}
.kanban-list.kanban-danger
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ef5350;
}
.kanban-list.kanban-success {
  background-color: #edf7ee;
}
.kanban-list.kanban-success > .kanban-header {
  background-color: #4caf50;
}
.kanban-list.kanban-success
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #4caf50;
}
.kanban-list.kanban-info {
  background-color: #e7f3fe;
}
.kanban-list.kanban-info > .kanban-header {
  background-color: #42a5f5;
}
.kanban-list.kanban-warning {
  background-color: #fdfcf3;
}
.kanban-list.kanban-warning > .kanban-header {
  background-color: #ffb300;
}
.kanban-list.kanban-warning
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ffb300;
}
.kanban-list.kanban-purple {
  background-color: #f1effd;
}
.kanban-list.kanban-purple > .kanban-header {
  background-color: #7460ee;
}
.kanban-list.kanban-purple
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #7460ee;
}
.kanban-list.kanban-primary {
  background-color: #fff5ec;
}
.kanban-list.kanban-primary > .kanban-header {
  background-color: #ff9b44;
}
.kanban-list.kanban-primary
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ff9b44;
}
.task-avatar {
  border-radius: 50%;
  position: relative;
}
.kanban-box.ui-sortable-handle {
  cursor: move;
}
.kanban-action > a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  display: inline-flex;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  justify-content: center;
}
.task-board-color .board-color-list {
  display: flex;
  height: 45px;
}
.task-board-color .board-color-list .board-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  padding-left: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
.task-board-color .board-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.board-control > .board-control-input:checked ~ .board-indicator:before {
  content: "\f00c";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  color: #fff;
  border: none;
  position: relative;
  font-family: var(--app-font-family);
  text-align: center;
  line-height: 16px;
}
.board-control > .board-control-input:checked ~ .board-indicator::before {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.board-control > .board-indicator {
  width: 36px;
  height: 36px;
}
.board-control.board-primary > .board-indicator {
  background: #ff9b44;
}
.board-control.board-success > .board-indicator {
  background: #4caf50;
}
.board-control.board-info > .board-indicator {
  background: #42a5f5;
}
.board-control.board-purple > .board-indicator {
  background: #7460ee;
}
.board-control.board-warning > .board-indicator {
  background: #ffb300;
}
.board-control.board-danger > .board-indicator {
  background: #ef5350;
}
.kanban-board.task-wrapper .kanban-list {
  width: 100%;
}
.board-view-header {
  margin-bottom: 30px;
}
.pro-teams {
  display: inline-flex;
  margin-right: 15px;
  text-align: center;
  margin-bottom: 20px;
}
.pro-teams .pro-team-members {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.pro-teams h4 {
  margin-bottom: 0;
  margin-right: 6px;
  font-size: 16px;
}
.pro-team-lead {
  display: flex;
  align-items: center;
}
.board-view-header .view-icons {
  float: right;
  margin-right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2px 7px;
  border-radius: 4px;
}
.board-view-header .view-icons .btn {
  margin: 0;
  padding: 0;
}
.board-view-header .view-icons a.btn + a.btn {
  margin-left: 5px;
}
.pro-progress-bar {
  display: flex;
  align-items: center;
}
.pro-progress-bar h4 {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 13px;
  text-transform: uppercase;
}
.pro-progress-bar .progress {
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
  height: 15px;
  background-color: #e3e3e3;
  box-shadow: unset;
}
.pro-progress-bar .progress > .progress-bar {
  background-color: #ff9b44;
}
.pro-teams .avatar-group {
  display: inline-flex;
}
.pro-teams .avatar-group .avatar {
  margin-right: 0;
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-color: transparent;
}
.pro-teams .avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.pro-teams .avatar-group .avatar .border {
  border: 3px solid #fff !important;
}
.pro-teams .avatar-group .avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.border-white {
  border-color: #fff !important;
}
.pro-teams .avatar-group .avatar:hover {
  z-index: 1;
}
.pro-teams .avatar .avatar-title {
  font-size: 14px;
  border-radius: 50%;
}
.pro-teams .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #ff9b44;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-date {
  display: block;
}
.task-priority {
  padding: 0.3em 0.6em 0.3em;
}
.task-follower-list {
  display: flex;
  margin-top: 15px;
}
.task-follower-list span {
  position: relative;
}
.task-follower-list span i {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 5px;
  color: #f00;
}
.add-new-task {
  text-align: center;
  padding: 0 20px 20px;
}
.add-new-task a {
  color: #333;
  font-size: 14px;
}
.project-add-form {
  display: none;
  margin-top: 10px;
}
.project-add-form.opened {
  display: block;
}
#myOverviewDiv {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 10px;
  left: 10px;
  background-color: #f2f2f2;
  z-index: 300; /* make sure its in front */
  border: solid 1px #7986cb;
}
/*-----------------
	62. File Manager
-----------------------*/
.wday-box input {
  opacity: 0;
}
.wday-box .checkmark {
  border: 2px solid #ff9b44;
  width: 40px;
  height: 40px;
  line-height: 35px;
  padding-left: 13px !important;
  color: #ff9b44;
  display: block;
}
.wday-box input:checked ~ .checkmark {
  background: #ff9b44;
  color: #fff;
}
.table-responsive {
  white-space: nowrap;
}
.user-add-shedule-list a {
  display: inline-block;
  padding: 10px;
  border: 1px dashed #a7a7a7;
  color: #a7a7a7;
  border-radius: 5px;
}
.user-add-shedule-list h2 a {
  border: 2px dashed #1eb53a;
  padding: 10px;
  color: #1eb53a;
}
.user-add-shedule-list span {
  justify-content: center;
  display: flex;
}
.file-wrap {
  /* border: 1px solid #e0e3e4; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  border: none;
  transition: all 0.5s ease-in-out;
}
.file-wrap .file-sidebar {
  border-right: 1px solid #e0e3e4;
  left: 0;
  position: absolute;
  z-index: 5;
  width: 300px;
  transition: 0.3s ease-in-out;
}
.file-wrap .file-sidebar .file-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  color: #324148;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-sidebar .file-header span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.file-wrap .file-sidebar .file-search {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}
.file-wrap .file-sidebar .file-search .input-group {
  width: 100%;
}
.file-wrap .file-sidebar .file-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}
.file-wrap .file-sidebar .file-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.file-wrap .file-sidebar .file-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.file-wrap .file-scroll {
  max-height: calc(100vh - 259px);
  min-height: 497px;
  overflow-y: auto;
}
.file-wrap .file-sidebar .file-pro-list {
  background-color: #fff;
  padding: 15px;
}
.file-side-close {
  display: none;
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #bbb;
  font-size: 14px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
  position: absolute;
  right: 15px;
}
.file-wrap .file-content {
  flex: 0 0 100%;
  max-width: 100%;
}
.file-wrap .file-content .file-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-content .file-body {
  background-color: #fff;
}
.dropdown-file {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdown-file .dropdown-link {
  color: #777;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dropdown-file .dropdown-link:hover,
.dropdown-file .dropdown-link:focus {
  color: #1b2e4b;
}
.dropdown-file .dropdown-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.card-file {
  padding: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.card-file:hover,
.card-file:focus {
  border-color: #ccc;
}
.card-file .card-body {
  padding: 15px 0 0;
}
.card-file .card-footer {
  font-size: 11px;
  color: #a9a9a9;
  padding: 10px 0 0;
  border-top-width: 0;
  background-color: transparent;
}
.card-file h6 {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.card-file h6 a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  color: #333;
}
.card-file span {
  font-size: 12px;
  color: #888;
}
.card-file-thumb {
  align-items: center;
  background-color: #f5f5f5;
  color: #777;
  display: flex;
  font-size: 48px;
  height: 120px;
  justify-content: center;
}
.file-wrap .file-content .file-body .file-content-inner {
  padding: 15px;
  width: 100%;
}
.file-wrap.file-sidebar-toggle .file-sidebar {
  left: -331px;
}
.file-wrap.file-sidebar-toggle .file-cont-wrap {
  margin-left: 0;
}
.file-wrap .file-cont-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: 300px;
  width: 100%;
  transition: 0.4s ease-in-out;
}
.file-wrap .file-cont-wrap .file-cont-inner {
  flex: 0 0 100%;
  max-width: 100%;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e0e3e4;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header > span {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-sidebar-toggle {
  color: #333;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options {
  display: flex;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options > a,
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > span {
  color: #333;
  display: inline-block;
  font-size: 20px;
  margin-left: 25px;
}
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > a:first-child,
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > span:first-child {
  margin-left: 0;
}
.file-wrap .file-content .file-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}
.file-wrap .file-content .file-search .input-group {
  width: 100%;
}
.file-wrap .file-content .file-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}
.file-wrap .file-content .file-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.file-wrap .file-content .file-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.btn-file {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file input {
  cursor: pointer;
  direction: ltr;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.file-menu {
  display: inline-block;
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
}
.file-menu li {
  display: inline-block;
  width: 100%;
}
.file-menu li + li {
  margin-top: 2px;
}
.file-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  transition: 0.3s ease;
}
.file-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}
.file-menu li a:hover,
.file-menu li.active a,
.file-menu li a:focus {
  background: rgba(33, 33, 33, 0.05);
}
.show-more {
  text-align: center;
}
.show-more a {
  position: relative;
  padding: 0 20px 0 15px;
}
.show-more a:before {
  content: "\f107";
  top: 50%;
  right: 0;
  position: absolute;
  font-size: 18px;
  line-height: inherit;
  font-family: var(--app-font-family);
  transform: translateY(-50%);
}

/*-----------------
	63. Subscriptions
-----------------------*/

.pricing-box {
  text-align: center;
}
.pricing-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.pricing-box ul li {
  line-height: 35px;
}
.pricing-box ul li i {
  color: #00bf6f;
  margin-right: 5px;
}
.equal-height-cards > div > div.card {
  height: 100%;
}
.mb-30 {
  margin-bottom: 30px !important;
}

/*-----------------
	64. Responsive
-----------------------*/

@media only screen and (min-width: 991px) {
  #toggle_btn {
    color: #999;
    float: left;
    font-size: 26px;
    line-height: 60px;
    padding: 0 10px;
    z-index: 1000;
    position: absolute;
  }
  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 30px;
    width: auto;
    display: none;
  }
  .mini-sidebar .header-left .cropped-logo {
    max-height: 30px;
    width: auto;
    display: inline-block;
  }
  .mini-sidebar .header-left .cropped-logo img {
    height: auto;
    width: 30px;
    display: inline-block;
  }
  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 60px;
  }
  .mini-sidebar .sidebar {
    width: 60px;
  }
  .mini-sidebar.expand-menu .sidebar {
    width: 230px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .mini-sidebar .sidebar .noti-dot:before {
    display: none;
  }
  .mini-sidebar .sidebar .noti-dot:after {
    display: none;
  }
  .mini-sidebar.expand-menu .sidebar .noti-dot:before {
    display: block;
  }
  .mini-sidebar.expand-menu .sidebar .noti-dot:after {
    display: block;
  }
  .modal-open.mini-sidebar .sidebar {
    z-index: 1001;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span.chat-avatar-sm {
    display: inline-block;
    opacity: 1;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span.status {
    display: inline-block;
    opacity: 1;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .sidebar .sidebar-menu > ul > li > a i {
    font-size: 30px;
    width: 30px;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
    font-size: 24px;
    width: 20px;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .modal-md {
    width: 600px;
  }
  .nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs.nav-justified.nav-tabs-top > li > a,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
  .nav-tabs.nav-tabs-top > li {
    margin-bottom: 0;
  }
  .nav-tabs.nav-tabs-top > li > a,
  .nav-tabs.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
  .nav-tabs.nav-tabs-top > li.open > a,
  .nav-tabs.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-tabs-top > li > a:focus {
    border-top-color: #ddd;
  }
  .nav-tabs.nav-tabs-top > li + li > a {
    margin-left: 1px;
  }
  .nav-tabs.nav-tabs-top > li > a.active,
  .nav-tabs.nav-tabs-top > li > a.active:hover,
  .nav-tabs.nav-tabs-top > li > a.active:focus {
    border-top-color: var(--prm-color);
  }
  .nav-tabs.nav-tabs-bottom > li {
    margin-bottom: -1px;
  }
  .nav-tabs.nav-tabs-bottom > li > a.active,
  .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: var(--prm-color);
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
  }
  .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
  }
  .nav-tabs.nav-tabs-solid > li {
    margin-bottom: 0;
  }
  .nav-tabs.nav-tabs-solid > li > a {
    border-color: transparent;
  }
  .nav-tabs.nav-tabs-solid > li > a:hover,
  .nav-tabs.nav-tabs-solid > li > a:focus {
    background-color: #f5f5f5;
  }
  .nav-tabs.nav-tabs-solid > .open:not(.active) > a {
    background-color: #f5f5f5;
    border-color: transparent;
  }
  .nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs-justified.nav-tabs-top > li > a,
  .nav-tabs-justified.nav-tabs-top > li > a:hover,
  .nav-tabs-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
}
@media only screen and (max-width: 1199.98px) {
  .page-title-box {
    display: none;
  }
  .file-wrap .file-sidebar {
    left: -331px;
  }
  .file-wrap .file-cont-wrap {
    margin-left: 0;
  }
  .file-wrap.file-sidebar-toggle .file-sidebar {
    left: 0;
  }
  .file-wrap.file-sidebar-toggle .file-cont-wrap {
    margin-left: 0;
  }
  .file-side-close {
    display: inline-block;
  }
}
@media only screen and (max-width: 991.98px) {
  .profile-rightbar {
    display: inline-block !important;
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .header .header-left {
    position: absolute;
    width: 100%;
  }
  .mobile_btn {
    display: block;
  }
  .page-title-box {
    display: none;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    transition: all 0.4s ease;
    z-index: 1041;
  }
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  a.mobile_btn {
    color: #fff;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 10;
  }
  #toggle_btn {
    display: none;
  }
  .page-wrapper {
    transition: all 0.4s ease;
  }
  .responsive-search {
    color: #fff;
    display: inline-block;
  }
  .top-nav-search form {
    display: none;
  }
  .top-nav-search .form-control {
    background-color: #fff;
    border-color: #e3e3e3;
    color: #333;
  }
  .top-nav-search .btn {
    color: #999;
  }
  .top-nav-search .form-control::placeholder {
    color: #999;
  }
  .header .has-arrow .dropdown-toggle > span:nth-child(2) {
    display: none;
  }
  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.75rem;
  }
  h3,
  .h3 {
    font-size: 1.375rem;
  }
  h4,
  .h4 {
    font-size: 1rem;
  }
  h5,
  .h5 {
    font-size: 0.9375rem;
  }
  h6,
  .h6 {
    font-size: 0.75rem;
  }
  .page-title,
  .dash-widget-info > h3,
  .account-title {
    font-size: 1.5rem;
  }
  .dash-card-content p,
  .stats-info h4,
  .account-box .account-btn,
  .job-title {
    font-size: 1.25rem;
  }
  .card-title,
  .dash-stats-list h4,
  .custom-modal .modal-title,
  .file-wrap .file-cont-wrap .file-cont-inner .file-cont-header > span,
  .faq-card .card .card-header h4 > a,
  .lead {
    font-size: 1.125rem;
  }
  .btn,
  .welcome-det p,
  .kanban-list > .kanban-header > .status-title,
  .account-box label,
  .job-info .job-dept,
  .form-focus .focus-label {
    font-size: 0.9375rem;
  }
  .onoffswitch-inner::before,
  .onoffswitch-inner::after,
  .form-control,
  .user-menu.nav > li > a,
  .page-header .breadcrumb {
    font-size: 0.875rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    cursor: pointer;
    font-size: 0.8125rem;
  }
  .comp-section .section-title,
  .voice-call-avatar .username,
  .page-menu .nav-tabs > li > a,
  .btn-group-lg > .btn,
  .btn-lg,
  .account-subtitle,
  .job-list h3.job-list-title {
    font-size: 1rem;
  }
  .message-content {
    font-size: 0.75rem;
  }
  .user-menu.nav > li > a {
    padding: 0 10px;
  }
  .top-nav-search form {
    width: 210px;
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
  .notifications {
    right: -48px;
  }
  .notifications::before,
  .notifications::after {
    right: 60px;
  }
  .header {
    z-index: 1040;
  }
  .task-wrapper #task-list li .task-container .task-label {
    padding-right: 50px;
    word-break: unset;
  }
  .task-btn-right {
    position: absolute;
    right: 8px;
  }
  .task-wrapper #task-list li .task-container {
    padding: 8px 10px;
  }
  .task-action-btn.task-check {
    width: 36px;
  }
  .roles-menu {
    margin-bottom: 20px;
  }
  .contacts-list {
    padding: 0 70px 0 15px;
  }
  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }
  .right-action {
    text-align: center;
  }
  .top-action-left .float-left {
    float: none !important;
  }
  .top-action-left .btn-group {
    margin-bottom: 15px;
  }
  .top-action-right {
    text-align: center;
  }
  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }
  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs.nav-justified > li > a.active,
  .nav-tabs.nav-justified > li > a.active:hover,
  .nav-tabs.nav-justified > li > a.active:focus {
    border-color: transparent transparent transparent var(--prm-color);
    border-left-width: 2px;
  }
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .nav-tabs > li > a {
    border-width: 2px;
    border-left-color: transparent;
  }
  .nav-tabs .nav-link {
    border-width: 2px;
  }
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    background-color: #fafafa;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs > li > a.active,
  .nav-tabs > li > a.active:hover,
  .nav-tabs > li > a.active:focus {
    background-color: #f5f5f5;
    border-color: transparent transparent transparent var(--prm-color);
    border-left-width: 2px;
  }
  .nav-tabs > li.open:not(.active) > a,
  .nav-tabs > li.open:not(.active) > a:hover,
  .nav-tabs > li.open:not(.active) > a:focus {
    background-color: #fafafa;
  }
  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }
  .nav-tabs.nav-tabs-solid > li > a {
    border-left-width: 0 !important;
  }
  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs-justified > li > a.active,
  .nav-tabs-justified > li > a.active:hover,
  .nav-tabs-justified > li > a.active:focus {
    border-width: 0 0 0 2px;
    border-left-color: var(--prm-color);
  }
  .my-video ul li {
    width: 80px;
  }
  .voice-call-avatar .call-avatar {
    width: 120px;
    height: 120px;
  }
  .product-info {
    margin: 20px 0 30px;
  }
  .profile-bg {
    height: 280px;
  }
  .flag-nav > a > span {
    display: none;
  }
  .equal-height-cards > div > div.card {
    height: unset;
  }
}
@media only screen and (max-width: 575.98px) {
  .contact-cat {
    padding-right: 70px;
  }
  .user-menu {
    display: none;
  }
  .mobile-user-menu {
    display: block;
  }
  .search-box {
    display: none;
  }
  .filter-row {
    margin-bottom: 15px;
  }
  .page-wrapper > .content {
    padding: 15px;
  }
  .profile-view .profile-basic {
    margin-left: 0;
    padding-right: 0;
  }
  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }
  .profile-info-left {
    text-align: center;
  }
  .profile-basic {
    margin-left: 0;
    margin-top: 15px;
  }
  .page-title {
    font-size: 18px;
  }
  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }
  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }
  .upload-text {
    font-size: 18px;
  }
  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }
  .end-call {
    margin-top: 10px;
    position: inherit;
  }
  .chat-panel .chats {
    padding: 15px 0;
  }
  .chat-right .chat-content {
    max-width: 90%;
  }
  .chat-right .chat-body {
    padding-left: 0;
    padding-right: 0;
  }
  .chat-left .chat-content {
    max-width: 90%;
  }
  .chat-left .chat-body {
    padding-left: 0;
    padding-right: 0;
  }
  .chat-avatar .avatar {
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  .account-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .float-left.ticket-view-details {
    width: 80%;
  }
  .custom-menu > li > a {
    margin-left: 10px;
  }
  .contact-alphapets {
    top: 113px;
  }
  .account-page .main-wrapper {
    flex-direction: column;
  }
  .otp-input {
    font-size: 18px;
    height: 50px;
    margin-right: 10px;
    width: 52px;
  }
  .account-wrapper {
    padding: 15px;
  }
  .leave-right {
    flex: 0 0 auto;
  }
  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.85rem;
  }
  .call-box .call-avatar {
    height: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  .call-box .call-wrapper .call-items .call-item {
    padding: 10px;
  }
  .call-box .call-wrapper .call-items .call-item.call-end {
    margin: 30px 5px 0;
    padding: 10px;
  }
  .submit-section .submit-btn {
    margin: 0 0 10px !important;
  }
  .leave-row {
    display: block;
  }
  .leave-box .subtitle {
    display: none;
  }
  .leave-right {
    margin-top: 20px;
  }
  .leave-inline-form {
    display: block;
  }
  .page-header .breadcrumb {
    display: none;
  }
  .add-btn {
    font-size: 14px;
    min-width: inherit;
  }
}
/*Project Board*/
.page-header .page-title i {
  color: #e6e9ef;
}
.page-header .page-title i:hover,
.page-header .page-title i:active {
  color: #ffcb00;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: 1px dashed #e6e9ef;
  outline: none;
  /*  padding:5px;*/
}
.page-header .text-truncate {
  width: 330px;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -1.25rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -1.75rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #2962ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}


.payroll-approval-modal-body {
  display: "flex";
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.custom-modal-width {
  width: auto !important;
}

.payroll-approval-card-group {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  flex-shrink: 1;
  width: 100%;
}

.payroll-approval-card {
  background-color: #fff;
  width: auto;
  margin: 0 10px;
  border: 1px solid #ededed;
  padding: 15px 20px 15px 10px;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.payroll-approvers {
  cursor: pointer;
  transition: 0.3s;
  min-width: 300px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}
.payroll-approvers:hover {
  transition: 0.3s;
  transform: scale(1.05);
  background-color: #f1f1f1 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  border: 2px solid rgba(0, 197, 251, 0.5);
}
.active_reviewer {
  transition: 0.3s;
  transform: scale(1.05);
  background-color: #f1f1f1 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  border: 2px solid rgba(28, 221, 41, 0.5) !important;
}

.current-processor {
  background-color: rgba(28, 221, 41, 0.2);
  border-radius: 100%;
  color: green;
  float: left;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
  font-weight: 500;
}

.payroll-processor {
  background-color: #f1f1f1 !important;
  border: 2px solid rgba(28, 221, 41, 0.5) !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.viewconfig-page-header {
  padding: 30px;
}

.viewconfig-page-title {
  font-size: 2em;
  color: #2c3e50;
}

.viewconfig-container {
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f4f7fc;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.viewconfig-title {
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 3px solid #00b7ed;
  padding-bottom: 10px;
  letter-spacing: 1px;
}

.viewconfig-office-info {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #34495e;
}

.viewconfig-subtitle {
  font-size: 1.6em;
  color: #34495e;
  margin-bottom: 25px;
  text-align: center;
  border-bottom: 1px solid #ecf0f1;
  padding-bottom: 12px;
}

.viewconfig-card {
  margin-bottom: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 10px;
  padding: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.viewconfig-card:hover{
  color: #00b7ed;
  box-shadow : 0 6px 12px rgba(0,0,0,0.1);
  border: 1px solid #00b7ed;

}

.viewconfig-card-header {
  font-size: 1.4em;
  margin-bottom: 15px;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.viewconfig-task-list {
  padding-left: 20px;
  list-style-type: disc;
}

.viewconfig-task-item {
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #7f8c8d;
  line-height: 1.6;
}

html {
  scroll-behavior: smooth;
}

:root {
  --prm-color: #00c5fb;
  --prm-color-shade: #00c5fb4d;
  --sidebar-bg-color: #212a36;
  --sec-color: #0253cc;
  --sec-color-shade: #0253cc4d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
}

/* valentines theme */
/* :root {
  --prm-color: #E63946;
  --prm-color-shade: #dfb5bc4d;
  --sidebar-bg-color: #212a36;
  --sec-color: #C2185B;
  --sec-color-shade: #8c3c4f4d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
} */

/* :root {
  --prm-color: #f4f4d;
  --prm-color-shade: #f14f4d4d;
  --sidebar-bg-color: #804e49;
  --sec-color: #ffa345;
  --sec-color-shade: #ffa3454d;
  --prm-gray: #b1b1b1;
  --prm-delete: #d80000;
  --app-font-family: "Jost", sans-serif;
} */

.page-wrapper {
  min-height: 643px;
}
.float-end {
  float: right !important;
}

.account-wrapper {
  padding: 30px;
}

@media only screen and (max-width: 767.98px) {
  .page-title,
  .dash-widget-info > h3,
  .account-title {
    font-size: 1.5rem;
  }
}
.account-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.account-subtitle {
  color: #4c4c4c;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

.error {
  color: crimson;
  font-size: small;
}
.react-bootstrap-table {
  width: 100%;
}
table {
  /* display: table !important; */
  /* overflow-x: scroll !important; */
}
a {
  cursor: pointer;
}
.rc-404-card {
  margin: 3rem auto;
  padding-left: 4rem;
  padding-top: 4rem;
}
.title-403,
.rc-404-card .title-404 {
  color: var(--prm-color);
  /* color: #ed4b8d; */
  font-family: var(--app-font-family);
  margin-top: 1rem;
  font-size: 37px;
  font-weight: 500;
  letter-spacing: 6px;
  line-height: 40px;
}
.rc-404-card .access-denied {
  color: #434455;
  font-family: var(--app-font-family);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
.rc-404-card .no-permi {
  color: #878999;
  font-family: var(--app-font-family);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

.go-back,
.export-csv {
  box-sizing: border-box;
  border: 2px solid var(--prm-color);
  border-radius: 3px;
  background-color: #ffffff;
  color: #161a37 !important;
  font-family: var(--app-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.go-back {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}
.go-back:hover,
.export-csv:hover {
  background: rgb(0, 197, 251);
  border: 2px solid var(--prm-color);
  color: #ffffff !important;
}
.designation-dropdown {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
    background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  /* margin-left: 30px; */
  height: 54px !important;
  width: 100%;
}
.css-yk16xz-control {
  min-height: 44px !important;
}
.sortable {
  border: 1px solid #dee2e6;
}
.clear {
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  font-weight: 400;
  color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.page-item.active .page-link,
#pageDropDown,
.btn-primary {
  z-index: 0;
  color: #ffffff;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #23b5dd;
  border-color: var(--prm-color);
}
.card-header {
  background: #f1f1f1;
  color: #000 !important;
}

.date-filter {
  position: absolute;
  top: 0%;
  right: 25%;
}
@media only screen and (max-width: 768px) {
  .date-filter {
    position: relative;
    top: 0%;
    right: 0%;
  }
}
.react-bs-table-no-data {
  text-align: center;
}
.pos-relative {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #495057;
}
.status-online,
.status-pending,
.status-terminated,
.status-ticket,
.status-strict,
.status-flexible {
  border: 2px solid #ffffff;
  bottom: 3px;
  height: 15px;
  color: #4c4c4c !important;
  position: absolute;
  left: 0;
  width: 15px;
  border-radius: 50%;
}
.status-online {
  background-color: #55ce63;
}
.status-pending {
  background-color: #ceb255;
}
.status-terminated {
  background-color: #c41717;
}
.status-open {
  background-color: #2dcecc;
}
.status-strict {
  background-color: var(--prm-color);
}
.status-flexible {
  background-color: #808080;
}
.btn-gray {
  background: #ffffff;
  border: 1px solid #f1f1f1 !important;
}
.edit-employee > .active {
  display: flex;
}
.btn-white {
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #333;
}

/* Active Sidebar Style */
#sidebar-menu a.active {
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: 500;
  border-right: 5px solid var(--prm-color);
  text-transform: var(--prm-color);
  color: var(--prm-color);
  transition: 0.3s;
}

.select-employee {
  width: 100%;
  margin-left: 0;
}
.form-check-input {
  /* display: block; */
  width: 20px !important;
  /* padding: .375rem .75rem !important; */

  color: #495057;
  background-color: #ffffff;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 20px !important;
}
.form-check-label {
  margin-bottom: 0;
  margin-left: 1rem;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
.table::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.react-bootstrap-table-sort-order .caret:before {
  margin-left: 3.5px;
  content: "\2191";
}
.react-bootstrap-table th .caret:after {
  content: "\2193";
  opacity: 0.4;
}
.header-class {
  box-shadow: 0 0 3px #e5e5e5;
}
.header-class,
tr {
  background-color: #ffffff;
}
tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
.filter-label {
  display: block;
}
.invoice-card {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
  border-radius: 0.375rem;
  min-height: 1px;
  padding: 1.5rem;
  flex: 1 1 auto;
}
.invoice-card2 {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}
.pos-relative {
  position: relative !important;
}

@media print {
  .sidebar {
    margin-left: -225px !important;
  }
  .header {
    display: none !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: var(--prm-color);
  border: 1px solid var(--prm-color);
}
a.nav-link {
  color: #000;
  border: none;
  transition: all 0.5s;
}

.upload-csv {
  border: 1px dashed #000;
  background: #eff9fc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5% 23px;
  font-size: 20px;
  cursor: pointer;
}
.balance-sheet tbody > tr:nth-of-type(2n + 1) {
  background-color: none !important;
}
.welcome-card {
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  z-index: 0;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: var(--prm-color-shade);
}

@media (min-width: 900px) {
  .left-card {
    padding: 0px 0px 0px 40px;
  }
}

.left-card {
  padding: 24px;
  color: rgb(33, 43, 54) !important;
}
.welcome-text {
  margin: 0px 0px 8px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.25rem;
  font-family: var(--app-font-family);
  color: rgb(33, 43, 54) !important;
}

.welcome-p {
  /* margin: 0px auto; */
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: var(--app-font-family);
  font-weight: 400;
  max-width: 480px;
  padding-bottom: 24px;
}
.go {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
          appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: var(--app-font-family);
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: rgb(28, 202, 255);
  box-shadow: rgb(28 202 255 / 24%) 0px 8px 16px 0px;
}

.rec-p {
  color: #2b2929;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 40px;
}

.ju-percent-group {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 138px);
  grid-gap: 0px;
  gap: 0px;
}
.ju-percent-group .d-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ju-percent-group .ju-percent {
  justify-content: flex-start;
}

.ju-percent-group h1 {
  font-weight: 800;
  font-size: 2.9375rem;
  line-height: 54px;
  text-align: center;
  color: var(--prm-color);
}

.ju-percent-group p {
  font-size: 1rem;
  line-height: 24px;
  margin: 12px 0px 0px;
  /* background-color: #ed4b8d; */
}
.availableOpening {
  font-size: 2.9375rem;
  line-height: 54px;
  color: #000;
  margin-bottom: 81px;
  font-weight: 800;
  position: relative;
}
.availableOpening::before {
  content: "";
  width: 107px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--prm-color);
  position: absolute;
  bottom: -20px;
  left: 0px;
}

/* multi-step form */
.application-form-header {
  background: var(--prm-color);
  /* color: #f7e3e8; */
  text-align: center;
}
/* CSS */
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.step-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  margin-top: 3px;
  margin-bottom: 5px;
  background-color: var(--prm-gray);
  transition: 0.4s;
}

.step-button[aria-expanded="true"] {
  width: 30px;
  height: 30px;
  background-color: var(--prm-color);
  color: #ffffff;
}
.full-btn {
  width: 100px !important;
  border-radius: 8px !important;
}
.done {
  background-color: var(--prm-color);
  color: #ffffff;
  width: 30px;
  height: 30px;
}

.step-item:nth-child(2) {
  z-index: 10;
  text-align: left;
}
.step-item:nth-child(3) {
  z-index: 10;
  text-align: center;
}
.step-item:nth-child(4) {
  z-index: 10;
  text-align: right;
}

#progress {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  width: 100%;
  z-index: 5;
  height: 4px;
  /* margin-left: 2.5%; */
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  #progress {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    width: 90%;
    z-index: 5;
    height: 4px;
    margin-left: 18px;
    margin-bottom: 18px;
  }
}

/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: var(--prm-color);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#progress::-webkit-progress-bar {
  background-color: var(--prm-gray);
}
.job-field {
  font-weight: 500;
}
.job-field::first-letter {
  text-transform: uppercase;
}
.sliding-text {
  transition: all 1s ease;
  overflow: hidden;
}
.sliding-text div {
  overflow: hidden;
  display: flex;
  animation: scrollText 33s infinite linear;
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.letter-box {
  width: 80%;
  margin: 10% auto;
}

.offer-letter-content {
  padding: 5em;
}

.confirmation {
  text-align: center;
}

.confirmation-btns {
  display: flex;
  justify-content: center;
}

.confirmation-btn {
  margin: 10px;
}

.applicant-signature {
  font-family: "Babylonica", cursive;
}

.signature-line {
  width: 50%;
}

.signature-line > hr {
  background: black;
}
/* multi-step form */

.shift_update_div {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.shift_update-warning {
  font-size: 18px;
}

.shift_update_div > p {
  margin: 0;
  color: #999;
}

.dashboard-hero-icon > img {
  width: 100%;
  height: 100%;
}

.sportDayGIF {
  position: absolute;
  width: 100px;
  right: 16%;
}

.slide-out-left {
  animation: slide-out-left 10s linear infinite both;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 1;
  }
}



.question-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Footer */
.modal-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
  text-align: right;
}

/* Valentine stuff Rhema */

.val-wrapper {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
  /* display: flex; */
  z-index: -10;
  justify-content: center;
  align-items: center;
}

.hearts-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.heart {
  position: absolute;
  width: 30px; /* Base width */
  height: 30px; /* Base height */
  background-color: #ff6b6b;
  transform: rotate(-45deg) scale(1); /* Scale for size variation */
  animation: floatUp 8s infinite ease-in-out;
}

.heart::before,
.heart::after {
  content: '';
  position: absolute;
  width: 100%; /* Inherit parent width */
  height: 100%; /* Inherit parent height */
  background-color: inherit;
  border-radius: 50%;
}

.heart::before {
  top: -50%; /* Position to form the top-left lobe */
  left: 0;
}

.heart::after {
  top: 0;
  left: 50%; /* Position to form the top-right lobe */
}
@keyframes floatUp {
  0% {
    transform: translateY(100vh) rotate(-45deg) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-10vh) rotate(-45deg) scale(1);
    opacity: 0;
  }
}
@keyframes float {
  0% {
      transform: translateY(0) rotate(-45deg) scale(1);
      opacity: 1;
  }
  50% {
      transform: translateY(-20px) rotate(-45deg) scale(1);
      opacity: 0.7;
  }
  100% {
      transform: translateY(0) rotate(-45deg) scale(1);
      opacity: 1;
  }
}

/* Different sizes and colors for variety */
.heart:nth-child(1) {
  top: 10%;
  left: 20%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(1.5); /* Scale for size */
  background-color: #ff7675;
  animation-duration: 6s;
}

.heart:nth-child(2) {
  top: 30%;
  left: 50%;
  transform: rotate(-45deg) scale(1.2); /* Scale for size */
  background-color: #ff4757;
  animation-duration: 4s;
}

.heart:nth-child(3) {
  top: 50%;
  left: 90%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(2); /* Scale for size */
  background-color: #ff6b81;
  animation-duration: 7s;
}

.heart:nth-child(4) {
  top: 70%;
  left: 20%;
  transform: rotate(-45deg) scale(1); /* Scale for size */
  background-color: #ffa502;
  animation-duration: 5s;
}

.heart:nth-child(5) {
  top: 80%;
  left: 40%;
  width: 20px; /* Base width */
  height: 20px; /* Base height */
  transform: rotate(-45deg) scale(1.8); /* Scale for size */
  background-color: #ff6348;
  animation-duration: 6s;
}

.heart:nth-child(6) {
  top: 20%;
  left: 80%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(7) {
  top: 70%;
  left: 80%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(8) {
  top: 70%;
  left: 60%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}
.heart:nth-child(9) {
  top: 30%;
  left: 5%;
  transform: rotate(-45deg) scale(0.8); /* Scale for size */
  background-color: #ff7f50;
  animation-duration: 3s;
}

.val_login_icon {
  /* position: absolute; */
  width: 50px;
  height: auto; /* Maintain aspect ratio */
}
.val_login_icon2 {
  /* position: absolute; */
  transform: scaleX(-1);
  width: 50px;
  height: auto; /* Maintain aspect ratio */
}
.form_builder_wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 3rem;
}

.form_builder_wrapper > p {
    margin: 0;
}

.option_input_div {
    margin-bottom: 1rem;
}

.correct_ans_instruction {
    margin-bottom: 1rem;
    color: red;
}

.form_builder_form {
    padding: 0.5rem 1rem;
}

.form_builder_form_sample_wrapper {
    padding-bottom: 5rem;
    margin-bottom: 2rem;
    border-bottom: 3px solid #009efb;
}

.form_builder_form_sample_wrapper > h3 {
    margin-bottom: 1rem;
    text-align: center;
}

.form_builder_form_sample {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
}

.form_builder_form_sample_fields {
    display: flex;
    flex-direction: column;
    margin-left: 5.5rem;
}

.survey_form_sample_fields {
    display: flex;
    flex-direction: column;
    margin: 0 0 3rem 0.5rem;
}

.survey_form_content_sample_fields {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.form_builder_form_sample_fields_inner {
    width: 100%;
}

.checkbox_radio_wrapper {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.checkbox_radio_wrapper > input::placeholder {
    color: rgba(255, 0, 0, 0.76);
}

.checkbox_radio_input {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-size: 14px;
    padding-right: 10px;
    cursor: pointer;
}

.checkbox_radio_input > input {
    margin-top: 3px;
    margin-right: 10px;
    cursor: pointer;
}

.form_builder_actions {
    display: flex;
    min-height: 44px !important;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.form_builder_actions > div > div {
    min-height: 44px !important;
}

.form_builder_actions > button {
    font-size: 15px;
}

.formInputTypeSelect {
    display: inline-block;
    width: 13%;
    height: 44px !important;
}

.add_que_btn,
.submit_que_btn {
    display: inline-block;
    width: 13%;
    height: 44px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
}

.submit_que_btn_wrapper {
    display: flex;
    justify-content: flex-end;
}

.form_builder_input_delete {
    transition: all 0.3s ease-in-out;
    color: var(--prm-gray);
    margin-left: 1rem;
    cursor: pointer;
    font-size: 18px;
}

.form_builder_input_delete:hover {
    color: var(--prm-delete);
}

.form_builder_form_sample_question {
    display: flex;
    align-items: center;
}

.form_builder_form_sample_question > h4 {
    margin: 0;
}

.edit_form_builder_field,
.delete_form_builder_field {
    background-color: #eee;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    color: #bbb;
    float: right;
    text-align: center;
    transition: all 0.3s ease-in-out;
    margin-right: 1rem;
}

.edit_form_builder_field {
    border-color: var(--prm-color);
}
.edit_form_builder_field:hover {
    background-color: var(--prm-color);
    color: #fff;
}

.delete_form_builder_field {
    border-color: var(--prm-delete);
}
.delete_form_builder_field:hover {
    background-color: var(--prm-delete);
    color: #fff;
}


.app_offices_view {
    padding: 20px 0 0 0;
    border-top: 1px solid #ccc;
}

.app_offices_view > ul {
    max-height: 210px;
    overflow: auto;
}
